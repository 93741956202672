<h1 class="build-evaluation-title mb-1">Evaluation Team</h1>

<div
  class="evaluation-team-desc mb-4 d-flex align-items-center justify-content-between">
  <div>
    Create and track your evaluation team and their progress on the project.
  </div>
  <div>
    <button
      [disabled]="true"
      type="button"
      class="btn btn-primary create-report mt-2">
      Create Evaluator Report
    </button>
  </div>
</div>

<div class="d-flex justify-content-between align-items-end">
  <div><h2 class="mb-0">Evaluator List</h2></div>
  <div ngbDropdown class="d-inline-block" placement="bottom-end">
    <button
      class="btn btn-secondary dropdown-toggle min-width custom-icon actions-evaluation"
      type="button"
      ngbDropdownToggle
      aria-expanded="false"
      id="evaluation-team-dropdown"
      [disabled]="!disableActionDropdown()">
      Actions
      <app-icon
        class="icon"
        [type]="'more-horizontal'"
        [width]="18"
        [height]="18"
        [strokeWidth]="3"></app-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="evaluation-team-dropdown">
      <button
        *ngFor="let action of actionsList"
        ngbDropdownItem
        class="evaluation-action"
        type="button"
        [disabled]="action.disabled"
        (click)="onClickActions(action.value)">
        {{ action.label }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="evaluatorsList.length && !evaluatorsListError; else noEvaluators"
  class="my-4">
  <app-evaluator-list
    #removeEvaluator
    [evaluatorsList]="evaluatorsList"
    (toggleAssignStagesModal)="toggleAssignStagesModal($event)"
    (removeAssignedStage)="openRemoveAssignedStageDialog($event)"
    (refreshEvalData)="getEvaluatorsList()"></app-evaluator-list>
  <div class="add-remove-evaluator-wrapper mt-4">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="toggleManageEvaluatorsModal()">
      Add/Remove Evaluators
    </button>
  </div>
</div>

<ng-template #noEvaluators>
  <div class="my-3" *ngIf="evaluatorsListError; else evaluatorFlow">
    <app-landing-page-error
      (refresh)="getEvaluatorsList()"></app-landing-page-error>
  </div>
  <ng-template #evaluatorFlow>
    <div class="card text-center my-3">
      <div class="card-body py-4">
        <div class="img-wrapper mb-3">
          <app-icon
            class="fa fa-circle fa-5x circle-icon"
            type="user-plus"
            [width]="52"
            [height]="52"></app-icon>
        </div>
        <h2 class="evaluator-not-exist-title">No Evaluators</h2>
        <p class="add-evaluator-desc mb-2">
          Add an evaluator to begin this section.
        </p>
        <button
          type="button"
          class="btn btn-primary add-evaluation mt-2"
          (click)="toggleManageEvaluatorsModal()">
          Add Evaluator
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>
<app-manage-evaluators
  *ngIf="showManageEvaluatorsModal"
  [evaluatorsList]="evaluatorsList"
  (hideManageEvaluatorsModal)="toggleManageEvaluatorsModal()"
  (refreshEvalData)="getEvaluatorsList()"></app-manage-evaluators>

<app-assign-stages
  *ngIf="showAssignStagesModal"
  [selectedEvaluatorList]="selectedEvaluatorList"
  (toggleAssignStagesModal)="toggleAssignStagesModal($event)"
  (refreshEvalData)="getEvaluatorsList()"></app-assign-stages>

<app-confirm-dialog
  *ngIf="showRemoveAssignedStageDialog"
  message=""
  dialogTitle="Remove Assigned Stage?"
  confirmButtonText="Remove Stage"
  cancelButtonText="Keep Stage"
  iconType="alert-circle"
  [isMinWidth]="false"
  (dialogConfirmed)="onConfirmRemoveAssignedStage()"
  (dialogCancel)="onCancelRemoveAssignedStage()"
  type="error">
  <p class="text-start">
    Are you sure you want to remove this assigned stage? This action will
    permanently remove:
  </p>
  <ul class="text-start">
    <li>All evaluator progress for this stage.</li>
    <li>All sections and criteria associated with this stage.</li>
    <li>Any linked assignments and evaluations.</li>
  </ul>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="showRemoveEvaluatorDialog"
  message="Are you sure you want to remove the selected evaluators from the evaluation team? This action cannot be undone."
  dialogTitle="Confirm Removal of Evaluators"
  confirmButtonText="Remove Evaluators"
  iconType="alert-circle"
  [isMinWidth]="false"
  (dialogConfirmed)="onConfirmRemoveEvaluator()"
  (dialogCancel)="onCancelRemoveEvaluators()"
  type="error">
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="showSendReminderDialog"
  message="Some of the selected evaluators do not have a stage assigned and will not receive a reminder. Reminders will only be sent to the evaluators with an assigned stage.Would you like to proceed with sending reminders to the eligible evaluators?"
  dialogTitle="Send Reminder to Eligible Evaluators"
  confirmButtonText="Proceed"
  iconType="alert-circle"
  [isMinWidth]="false"
  (dialogConfirmed)="onConfirmSendReminder()"
  (dialogCancel)="onCancelSendRemainer()"
  type="info">
</app-confirm-dialog>
