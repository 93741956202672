<!-- Start Stage Add form-->
<div class="add-stage-container" *ngIf="enableStage">
  <div class="build-flow-add-action mt-3">
    <a href="javascript:void(0)" class="link-theme add" (click)="addStage()"
      >Add Stage</a
    >
  </div>
</div>
<!-- End Stage Add form-->

<!-- Start Section Add form-->
<div class="add-section-container" *ngIf="enableSection">
  <div class="build-flow-add-action mt-3">
    <a href="javascript:void(0)" class="link-theme add" (click)="addSection()"
      >Add Section</a
    >
    <a
      href="javascript:void(0)"
      class="link-theme add ms-4"
      (click)="addCriteria()"
      >Add Criteria</a
    >
  </div>
</div>
<!-- End Section Add form-->

<!-- Start Criteria Add form-->
<div class="add-criteria-container" *ngIf="enableCriteria">
  <div class="build-flow-add-action mt-3">
    <a href="javascript:void(0)" class="link-theme add" (click)="addCriteria()"
      >Add Criteria</a
    >
  </div>
</div>
<!-- End Criteria Add form-->
