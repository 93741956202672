import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetsPathChangeService {
  getAssetUrl(assetPath: string): string {
    return environment?.baseHref
      ? `${environment?.baseHref}${assetPath}`
      : assetPath;
  }
}
