import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import {
  IEvaluationGuideList,
  IEvaluationNonDisclosureAgreementList,
  IEvaluationConflictOfInterestList,
  IEvaluationScoreCardList,
} from 'src/app/shared/constants/lists';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/core/services/store.service';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';

@Component({
  selector: 'app-build-evaluation',
  templateUrl: './build-evaluation.component.html',
  styleUrls: ['./build-evaluation.component.scss'],
})
export class BuildEvaluationComponent implements OnInit {
  evaluationForm: any;
  isEvaluationExist = false;
  evaluationGuideList: IEvaluationGuideList[] = [];
  evaluationNonDisclosureAgreementList: IEvaluationNonDisclosureAgreementList[] =
    [];
  evaluationConflictOfInterestList: IEvaluationConflictOfInterestList[] = [];
  evaluationScoreCardList: IEvaluationScoreCardList[] = [];

  confirmDialogMessage: any;
  confirmDialogTitle: any;
  operationType: any;

  launchBuilderErrorsWarningsData: any = {};

  evaluationGuideSelectedValue: any;

  isEvaluationError = false;
  evaluationData: any = {
    evaluationExists: false,
    evaluationId: null,
  };

  evaluationDetails: any = {
    evaluationId: null,
    evaluationGuideId: null,
    nonDisclosure: false,
    disclosureAgreementId: null,
    conflictOfInterestId: null,
    decimalPlacesId: 0,
    scoringMethodId: false,
    minScore: 0,
    maxScore: 0,
    scoreCardContentId: null,
  };
  showLaunchBuilder = false;
  showConfirmDialog = false;
  showAddEvaluationModal = false;

  constructor(
    public fb: FormBuilder,
    private pageLoader: PageLoaderService,
    private evaluation: EvaluationService,
    private toastr: ToastrService,
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {
    this.evaluationForm = this.fb.group({
      evaluationGuideId: [''],
      nonDisclosure: [null],
      disclosureAgreementId: [''],
      conflictOfInterestId: [''],
      scoringMethodId: [''],
      decimalPlacesId: [''],
      minScore: [null],
      maxScore: [null],
      scoreCardContentId: [''],
    });
  }

  ngOnInit(): void {
    this.getEvaluation();
    this.loadMasterListData();
    this.loadDropdowns();
  }

  checkNonDisclosureAgreement() {
    if (this.evaluationForm.value.nonDisclosure) {
      this.evaluationForm.get('disclosureAgreementId').enable();
    } else {
      this.evaluationForm.get('disclosureAgreementId').disable();
    }
  }

  // Getting evaluation already exist or not
  getEvaluation() {
    this.pageLoader.show();
    this.evaluation.getEvaluation().subscribe({
      next: (data: any) => {
        this.isEvaluationError = false;
        this.pageLoader.hide();
        if (data?.evaluationExists && data?.evaluationId) {
          this.evaluationData = { ...data };
          this.store.updateEvaluationId(this.evaluationData.evaluationId);
          this.getEvaluationById(this.evaluationData.evaluationId);
          this.getLaunchBuilderErrorsWarnings(this.evaluationData.evaluationId);
        }
      },
      error: e => {
        this.pageLoader.hide();
        this.isEvaluationError = true;
      },
    });
  }

  getEvaluationById(id: any) {
    this.evaluation.getEvaluationById(id).subscribe({
      next: (data: any) => {
        this.evaluationDetails = { ...data };
        this.store.updateEvaluationDetails(this.evaluationDetails);
        this.bindEditEvaluationDetails();
      },
    });
  }

  getLaunchBuilderErrorsWarnings(id: string) {
    this.evaluation.getLaunchBuilderErrorsWarnings(id).subscribe({
      next: (data: any) => {
        this.launchBuilderErrorsWarningsData = { ...data };
      },
    });
  }

  bindEditEvaluationDetails() {
    this.evaluationForm.controls.evaluationGuideId.setValue(
      this.evaluationDetails.evaluationGuideId
    );
    this.evaluationForm.controls.nonDisclosure.setValue(
      this.evaluationDetails.nonDisclosure
    );
    this.evaluationForm.controls.disclosureAgreementId.setValue(
      this.evaluationDetails.disclosureAgreementId
    );
    this.evaluationForm.controls.conflictOfInterestId.setValue(
      this.evaluationDetails.conflictOfInterestId
    );
    this.evaluationForm.controls.decimalPlacesId.setValue(
      this.evaluationDetails.decimalPlacesId
    );
    this.evaluationForm.controls.scoringMethodId.setValue(
      this.evaluationDetails.scoringMethodId
    );
    this.evaluationForm.controls.minScore.setValue(
      this.evaluationDetails.minScore
    );
    this.evaluationForm.controls.maxScore.setValue(
      this.evaluationDetails.maxScore
    );
    this.evaluationForm.controls.scoreCardContentId.setValue(
      this.evaluationDetails.scoreCardContentId
    );
  }

  openErrorConfirmModal(): void {
    this.confirmDialogMessage =
      "Are you sure that you would like to remove this evaluation from project? Please note that any work that you've completed so far will be lost.";
    this.confirmDialogTitle = 'Remove Evaluation';
    this.showConfirmDialog = true;
  }

  onCancelConfirmDialog(): void {
    if (this.confirmDialogTitle == 'Remove Evaluation') {
      this.showConfirmDialog = false;
    } else {
      this.openFirstConfirmModal();
    }
  }

  toggleAddEvaluation() {
    this.showAddEvaluationModal = !this.showAddEvaluationModal;
  }
  //Opening the Warning popup once score method has been changed
  openSecConfirmModal(): void {
    this.confirmDialogMessage =
      "Please note that switching the scoring method will result in the reset of any assigned points or weights you've applied. This action cannot be undone and may impact your current evaluation";
    this.confirmDialogTitle =
      'Changing Scoring Method Will Reset Points/Weights';
    this.showAddEvaluationModal = false;

    this.showConfirmDialog = true;
  }

  //Opening the Evaluation popup once cancel button has been clicked on Warning popup
  openFirstConfirmModal(): void {
    this.showConfirmDialog = false;

    this.showAddEvaluationModal = true;
  }

  onConfirmDialog(event: any): void {
    if (this.confirmDialogTitle == 'Remove Evaluation') {
      const deletePayload = {
        evaluationId: this.evaluationData.evaluationId,
        modifiedBy: '2fa85f64-5717-4562-b3fc-2c963f66afa4',
      };

      this.evaluation.removeEvaluation(deletePayload).subscribe({
        next: (data: any) => {
          window.location.reload();
        },
      });
    } else {
      const payload1 = { evaluationRequest: {} };
      const L2 = this.evaluationForm.value;
      L2.projectId = '2204faf3-e62c-469d-8181-5c9ecee8a5d9';
      L2.nodeId = '4e1140f8-6cd6-41d3-96da-d4bf8b6d58d0';
      L2.tenantId = '29839c9e-dd18-45d5-b0ff-620f38dd9713';
      L2.statusId = 1;
      L2.isDeleted = false;
      L2.createdBy = '2fa85f64-5717-4562-b3fc-2c963f66afa4';
      if (this.evaluationData.evaluationExists) {
        L2.modifiedBy = '2fa85f64-5717-4562-b3fc-2c963f66afa4';
        L2.evaluationId = this.evaluationData.evaluationId;
        this.operationType = 'Update';
      } else {
        this.operationType = 'Add';
      }
      payload1.evaluationRequest = L2;

      this.evaluation
        .addUpdateEvaluation(payload1, this.operationType)
        .subscribe({
          next: (data: any) => {
            window.location.reload();
          },
          error: e => {
            this.toastr.error(
              "We're experiencing trouble connecting to the server. This could be due to a temporary network issue. Please try refreshing your browser or check your internet connection. Error {" +
                e.status +
                '}',
              'Network Issue Detected',
              {
                closeButton: true,
                timeOut: 5000,
              }
            );
          },
        });
    }
  }

  saveEvaluation() {
    let edit = false;
    const payload = { evaluationRequest: {} };
    const L2 = this.evaluationForm.value;
    L2.projectId = '2204faf3-e62c-469d-8181-5c9ecee8a5d9';
    L2.nodeId = '4e1140f8-6cd6-41d3-96da-d4bf8b6d58d0';
    L2.tenantId = '29839c9e-dd18-45d5-b0ff-620f38dd9713';
    L2.statusId = 1;
    L2.isDeleted = false;
    L2.createdBy = '2fa85f64-5717-4562-b3fc-2c963f66afa4';
    if (this.evaluationData.evaluationExists) {
      L2.modifiedBy = '2fa85f64-5717-4562-b3fc-2c963f66afa4';
      L2.evaluationId = this.evaluationData.evaluationId;
      this.operationType = 'Update';
      edit = true;
    } else {
      edit = false;
      this.operationType = 'Add';
    }
    payload.evaluationRequest = L2;

    if (edit == true) {
      if (this.evaluationDetails.scoringMethodId == L2.scoringMethodId) {
        this.onConfirmDialog('');
      } else {
        this.openSecConfirmModal();
      }
    } else {
      this.evaluation
        .addUpdateEvaluation(payload, this.operationType)
        .subscribe({
          next: (data: any) => {
            if (this.operationType == 'Add') {
              this.evaluationForm.reset();
            }
            window.location.reload();
          },
          error: e => {
            this.toastr.error(
              "We're experiencing trouble connecting to the server. This could be due to a temporary network issue. Please try refreshing your browser or check your internet connection. Error {" +
                e.status +
                '}',
              'Network Issue Detected',
              {
                closeButton: true,
                timeOut: 5000,
              }
            );
          },
        });
    }
  }

  loadDropdowns() {
    if (localStorage['evaluationGuideListData']) {
      this.evaluationGuideList = JSON.parse(
        localStorage.getItem('evaluationGuideListData') || '{}'
      );
    }

    if (localStorage['evaluationNonDisclosureAgreementListData']) {
      this.evaluationNonDisclosureAgreementList = JSON.parse(
        localStorage.getItem('evaluationNonDisclosureAgreementListData') || '{}'
      );
    }

    if (localStorage['evaluationConflictOfInterestListData']) {
      this.evaluationConflictOfInterestList = JSON.parse(
        localStorage.getItem('evaluationConflictOfInterestListData') || '{}'
      );
    }

    if (localStorage['evaluationScoreCardListData']) {
      this.evaluationScoreCardList = JSON.parse(
        localStorage.getItem('evaluationScoreCardListData') || '{}'
      );
    }

    this.evaluationForm.controls.nonDisclosure.setValue(false);
    this.evaluationForm.controls.scoringMethodId.setValue(1);
  }

  loadMasterListData() {
    this.evaluation.getMasterData().subscribe(data => {
      if (data) {
        const evaluationGuideResult = data.data.evaluationGuideDetails;
        if (evaluationGuideResult.count > 0) {
          localStorage.setItem(
            'evaluationGuideListData',
            JSON.stringify(evaluationGuideResult.values)
          );
        } else {
          const evaluationGuideDummy = [
            {
              id: '26c665c3-3f65-4d83-ac61-12751460ed75',
              name: 'Evaluation guide template 1',
              active: true,
              deleted: false,
            },
            {
              id: '26c665c3-3f65-4d83-ac61-12751460ed76',
              name: 'Evaluation guide template 2',
              active: true,
              deleted: false,
            },
            {
              id: '26c665c3-3f65-4d83-ac61-12751460ed78',
              name: 'Evaluation guide template 4',
              active: true,
              deleted: false,
            },
          ];
          localStorage.setItem(
            'evaluationGuideListData',
            JSON.stringify(evaluationGuideDummy)
          );
        }

        const evaluationNonDisclosureAgreementResult =
          data.data.evaluationNonDisclosureAgreementDetails;
        if (evaluationNonDisclosureAgreementResult.count > 0) {
          localStorage.setItem(
            'evaluationNonDisclosureAgreementListData',
            JSON.stringify(evaluationNonDisclosureAgreementResult.values)
          );
        } else {
          const evaluationNonDisclosureAgreementDummy = [
            {
              id: '7fef1e33-c3d1-4ee4-88f1-06f21ac78028',
              name: 'Test Template 1 - Burl',
              active: true,
              deleted: false,
            },
          ];
          localStorage.setItem(
            'evaluationNonDisclosureAgreementListData',
            JSON.stringify(evaluationNonDisclosureAgreementDummy)
          );
        }

        const evaluationConflictOfInterestDetailsResult =
          data.data.evaluationConflictOfInterestDetails;
        if (evaluationConflictOfInterestDetailsResult.count > 0) {
          localStorage.setItem(
            'evaluationConflictOfInterestListData',
            JSON.stringify(evaluationConflictOfInterestDetailsResult.values)
          );
        } else {
          const evaluationConflictOfInterestDummy = [
            {
              id: '74928483-9c56-4ea1-a518-4bd0619580f9',
              name: 'Test Template 1 - Newmarket',
              active: true,
              deleted: false,
            },
          ];
          localStorage.setItem(
            'evaluationConflictOfInterestListData',
            JSON.stringify(evaluationConflictOfInterestDummy)
          );
        }

        const evaluationScoreCardDetailsResult =
          data.data.evaluationScoreCardDetails;
        if (evaluationScoreCardDetailsResult.count > 0) {
          localStorage.setItem(
            'evaluationScoreCardListData',
            JSON.stringify(evaluationScoreCardDetailsResult.values)
          );
        } else {
          const evaluationScoreCardDetailsDummy = [
            {
              id: '36c665c3-3f65-4d83-ac61-12751460ed85',
              name: 'Evaluation score cared template 1',
              active: true,
              deleted: false,
            },
            {
              id: '46c665c3-3f65-4d83-ac61-12751460ed86',
              name: 'Evaluation score card template 2',
              active: true,
              deleted: false,
            },
          ];
          localStorage.setItem(
            'evaluationScoreCardListData',
            JSON.stringify(evaluationScoreCardDetailsDummy)
          );
        }
      }
    });
  }

  toggleLaunchBuilder() {
    this.showLaunchBuilder = !this.showLaunchBuilder;
    if (!this.showLaunchBuilder) {
      this.getLaunchBuilderErrorsWarnings(this.evaluationData.evaluationId);
    }
  }

  cancelPopup() {
    window.location.reload();
  }
}
