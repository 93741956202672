<nav
  class="navbar navbar-expand d-flex flex-column rfx-sidebar"
  id="sidebar"
  [ngClass]="{ 'is-expanded': isExpanded }">
  <div class="toggle-sidebar-wrapper">
    <button
      type="button"
      class="btn btn-toggle"
      (click)="toggleSidebar()"
      aria-label="Toggle Sidebar Collapse">
      <app-icon
        class="icon"
        [type]="'chevron-left'"
        [width]="18"
        [height]="18"
        [strokeWidth]="3"></app-icon>
    </button>
  </div>
  <div class="menu-items">
    <ul class="nav d-flex flex-column w-100">
      <li class="nav-item w-100" *ngFor="let menu of menuItems; index as index">
        <a
          ariaLabel="{{ menu.name }} navigation link"
          class="nav-link"
          routerLinkActive="is-active"
          routerLink="/{{ menu.url }}">
          {{ menu.name }}</a
        >
      </li>
    </ul>
  </div>
</nav>
