import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BuildFlowService } from 'src/app/core/services/build-flow.service';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { StoreService } from 'src/app/core/services/store.service';
import { IEvaluatorList } from 'src/app/shared/constants/lists';
@Component({
  selector: 'app-assign-evaluators',
  templateUrl: './assign-evaluators.component.html',
  styleUrls: ['./assign-evaluators.component.scss'],
})
export class AssignEvaluatorsComponent implements OnInit {
  isEvalTeamError = false;
  @Input() criteriaDetails: any;
  @Input() stageDisplayOrder!: number;
  @Input() sectionDisplayOrder!: number;
  @Output() cancelModal = new EventEmitter<void>();
  searchText = '';
  evaluatorsList: IEvaluatorList[] = [];
  showLoader = true;
  selectAllEvaluators = false;
  indeterminateEvaluators = false;
  saveLoader = false;
  showManageEvaluatorsModal = false;
  hideModal = false;

  constructor(
    private buildFlowService: BuildFlowService,
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}
  getEvaluatorsListByCriteria() {
    this.showLoader = true;
    this.buildFlowService
      .getEvaluatorsListByCriteria(
        this.store.getEvaluationId(),
        this.criteriaDetails.id
      )
      .subscribe({
        next: (data: any) => {
          this.evaluatorsList = data?.data?.values || [];
          this.evaluatorsList.forEach(
            evaluator => (evaluator.teamMemberId = evaluator.id)
          );
          this.showLoader = false;
          this.updateIndeterminateState();
        },
        error: e => {
          this.showLoader = false;
        },
      });
  }

  get filteredEmployee() {
    const search = this.searchText.toLowerCase();
    return this.evaluatorsList.filter(emp =>
      `${emp.firstName} ${emp.lastName}`.toLowerCase().includes(search)
    );
  }

  updateIndeterminateState() {
    const checkedCount = this.evaluatorsList.filter(
      evalItem => evalItem.isSelected
    ).length;
    this.indeterminateEvaluators =
      checkedCount > 0 && checkedCount < this.evaluatorsList.length;
    this.selectAllEvaluators = checkedCount === this.evaluatorsList.length;
  }

  toggleSelected() {
    this.updateIndeterminateState();
  }

  toggleSelectAll() {
    this.selectAllEvaluators = !this.selectAllEvaluators;
    this.evaluatorsList.forEach(
      evalItem => (evalItem.isSelected = this.selectAllEvaluators)
    );
    this.updateIndeterminateState();
  }

  onClearSearch() {
    this.searchText = '';
  }
  saveEvaluatorsToCriteria() {
    const payload = {
      evaluationId: this.store.getEvaluationId(),
      criteriaId: this.criteriaDetails.id,
      teamMemberIds: this.evaluatorsList
        .filter(evalItem => evalItem.isSelected)
        .map(evalItem => evalItem.id),
    };
    this.saveLoader = true;
    this.buildFlowService.saveEvaluatorsListByCriteria(payload).subscribe({
      next: (data: any) => {
        this.store.setBuildFlowActionsData({
          action: 'refresh',
        });
        this.saveLoader = false;
        this.closeModal();
      },
      error: () => {
        this.saveLoader = false;
      },
    });
  }

  closeModal() {
    this.cancelModal.emit();
  }

  toggleManageEvaluatorsModal() {
    this.showManageEvaluatorsModal = !this.showManageEvaluatorsModal;
    if (!this.showManageEvaluatorsModal) {
      this.hideModal = false;
    } else {
      this.hideModal = true;
    }
  }

  ngOnInit() {
    this.getEvaluatorsListByCriteria();
  }
}
