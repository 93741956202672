import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/core/services/store.service';
import { StageTypes } from 'src/app/shared/constants/constants';
import { Subscription } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
@Component({
  selector: 'app-section-view',
  templateUrl: './section-view.component.html',
  styleUrls: ['./section-view.component.scss'],
})
export class SectionViewComponent implements OnInit, OnDestroy {
  @Input() sections: any = [];
  @Input() criterias: any = [];
  @Input() stageTypeId = 0;
  @Input() stageId = '';
  @Input() stageStatus = '';
  @Input()
  stageDisplayOrder!: number;
  @Input() enableErrorsWarnings = false;
  @Input() buildFlowFocus: any = {};

  @ViewChild('sectionAccordion') sectionAccordion: any;

  constructor(
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}

  stageTypes: any = StageTypes;
  selectedEditSectionDisplayOrder = 0;
  buildEditFlowSubscription!: Subscription;

  getSectionAndCriteriasList() {
    return [...this.sections, ...this.criterias]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .slice();
  }
  trackByFn(index: number, section: any) {
    return section.id ? section.id : section.uniqueTimestamp;
  }

  closeNestedAccordion(section: any) {
    section.criterias.forEach((criteria: any) => {
      criteria['enableEdit'] = false;
      criteria['enableExpanded'] = false;
    });
  }

  showEditSection(section: any) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: section.displayOrder,
    });
    if (this.stageStatus == 'Active') {
      this.selectedEditSectionDisplayOrder = section.displayOrder;
      this.store.setBuildFlowActionsData({
        data: section,
        type: 'section',
        action: 'edit',
      });
    } else {
      section['enableEdit'] = true;
      section['enableExpanded'] = true;
      this.sectionAccordion.expand(`section-item-${section.displayOrder}`);
    }
  }

  editActiveStageSection() {
    this.buildEditFlowSubscription =
      this.store.getBuildFlowEditActionsData.subscribe((data): any => {
        if (
          data.action === 'edit' &&
          data?.type === 'section' &&
          this.selectedEditSectionDisplayOrder === data.data.displayOrder
        ) {
          data.data['enableEdit'] = true;
          data.data['enableExpanded'] = true;
          this.selectedEditSectionDisplayOrder = 0;
          this.sectionAccordion.expand(
            `section-item-${data.data.displayOrder}`
          );
        }
      });
  }
  hideEditSection(section: any) {
    section['enableEdit'] = false;
    section['enableExpanded'] = !section['enableExpanded'];
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: section.displayOrder,
    });
    this.closeNestedAccordion(section);
  }

  copySection(displayOrder: number) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: displayOrder,
    });
    this.store.setBuildFlowActionsData({
      displayOrder: displayOrder,
      stageDisplayOrder: this.stageDisplayOrder,
      type: 'section',
      action: 'copy',
    });
  }

  deleteSection(section: any) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: section.displayOrder,
    });
    this.store.setBuildFlowActionsData({
      evaluationSectionId: section.id,
      evaluationStageId: this.stageId,
      displayOrder: section.displayOrder,
      stageDisplayOrder: this.stageDisplayOrder,
      stageStatus: this.stageStatus,
      title: section.title,
      type: 'section',
      action: 'delete',
      isLocalDelete: section.id ? false : true,
    });
  }
  dropSections(event: CdkDragDrop<string[]>) {
    const data = [...this.getSectionAndCriteriasList()];
    moveItemInArray(data, event.previousIndex, event.currentIndex);
    this.store.setBuildFlowActionsData({
      type: 'section',
      action: 'dragdrop',
      stageDisplayOrder: this.stageDisplayOrder,
      data: data,
    });
  }

  ngOnInit(): void {
    this.editActiveStageSection();
  }
  ngOnDestroy(): void {
    if (this.buildEditFlowSubscription) {
      this.buildEditFlowSubscription.unsubscribe();
    }
  }
}
