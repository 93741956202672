<div *ngIf="selectedProject">
  <h2 class="project-title">
    {{ selectedProject.project.id }}
    {{ selectedProject.project.name }}
  </h2>
  <div class="text-center py-3" *ngIf="showLoader; else content">
    <app-loader [showText]="true"></app-loader>
  </div>
  <ng-template #content>
    <div [innerHTML]="selectedProject?.project.content"></div>
  </ng-template>

  <div>Is there a conflict of interest?</div>

  <div class="mt-3 conflictOfInterest-continue">
    <button [disabled]="true" type="button" class="btn btn-danger me-3">
      Identify Conflict
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="true">
      No Conflict
    </button>
  </div>

  <button class="arrow-left">
    <app-icon [type]="'arrow-left'" [width]="18" [height]="18"> </app-icon>
  </button>
  <a class="back-btn-link" (click)="backToDashboard()"
    >Back to My Evaluation Dashboard</a
  >
</div>
