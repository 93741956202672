<div class="stages-container">
  <div
    ngbAccordion
    #stageAccordion="ngbAccordion"
    class="custom-accordion stage-items"
    cdkDropList
    (cdkDropListDropped)="dropStages($event)">
    <div
      ngbAccordionItem="stage-item-{{ stage.displayOrder }}"
      [collapsed]="stage?.enableEdit || stage?.enableExpanded ? false : true"
      class="stage-item"
      [ngClass]="{
        'is-edit': stage.displayOrder === buildFlowFocus?.stage,
        'has-error':
          enableErrorsWarnings &&
          stage?.messages &&
          stage?.messages?.errors?.count,
        'has-warning':
          enableErrorsWarnings &&
          stage?.messages &&
          stage?.messages?.warnings.count,
      }"
      *ngFor="
        let stage of stages.slice() | orderBy: 'displayOrder';
        index as stageIndex;
        trackBy: trackByFn
      "
      cdkDragBoundary=".stage-items"
      [cdkDragPreviewContainer]="'parent'"
      [cdkDragDisabled]="stage.enableEdit"
      cdkDrag>
      <div
        class="row d-flex px-3 py-2"
        [ngClass]="{ 'align-items-center': !stage.enableEdit }">
        <div class="col-12 col-sm-9 col-md-10" *ngIf="!stage.enableEdit">
          <div class="row">
            <div class="col-12 col-sm-5">
              <div class="d-flex align-items-center">
                <div class="drag-icon stage-drag-icon" cdkDragHandle>
                  <img
                    [src]="path.getAssetUrl('/assets/svg/drag-icon.svg')"
                    alt="icon of drag and drop" />
                </div>
                <h4 class="accordion-header ps-3">
                  {{ stage.displayOrder }}. {{ stage.title }}
                </h4>
              </div>
              <span *ngIf="stage.stageStatus !== 'Created'">
                <span
                  [ngClass]="{
                    'dot-active': stage.stageStatus === 'Active',
                    'dot-closed': stage.stageStatus === 'Closed',
                  }"></span>
                <small>
                  {{ stage.stageStatus }}
                </small>
              </span>
              <span class="col-12 stage-date">
                <small *ngIf="stage.startDateUtc"
                  >{{ stage.startDateUtc | date: 'MMM dd/yy' }} -
                  {{ stage.endDateUtc | date: 'MMM dd/yy' }}</small
                >
              </span>
            </div>
            <div class="col-12 col-sm-2">Stage</div>
            <div class="col-12 col-sm-5">
              <span
                *ngIf="stage.totalScore !== null && stage.stageTypeId === 1">
                {{
                  stage.totalScore + ' ' + stageTypes[stage.stageTypeId]
                    | lowercase
                }}
              </span>
              <span
                *ngIf="stage.totalScore !== null && stage.stageTypeId === 2">
                {{
                  stage.totalScore + '% ' + stageTypes[stage.stageTypeId]
                    | lowercase
                }}
              </span>
              <span *ngIf="stage.stageTypeId === 3 || stage.stageTypeId === 4">
                {{ stageTypes[stage.stageTypeId] }}
              </span>
            </div>
          </div>
          <div *ngIf="stage?.messages && enableErrorsWarnings">
            <div
              class="row"
              *ngFor="let error of stage?.messages?.errors.values">
              <span class="col-12 ps-4">
                <small>
                  <app-icon
                    [class]="'error pe-2'"
                    [type]="'alert-circle'"
                    [width]="20"
                    [height]="20"></app-icon>
                  {{ error }}
                </small>
              </span>
            </div>
            <div
              class="row"
              *ngFor="let warning of stage?.messages?.warnings.values">
              <span class="col-12 ps-4">
                <small>
                  <app-icon
                    [class]="'warning pe-2'"
                    [type]="'alert-triangle'"
                    [width]="22"
                    [height]="22"></app-icon>
                  {{ warning }}
                </small>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-9 col-md-10 mb-2" *ngIf="stage.enableEdit">
          <app-add-edit-stage [formData]="stage"></app-add-edit-stage>
        </div>
        <div class="col-12 col-sm-3 col-md-2">
          <div class="row">
            <div
              class="col-12 col-sm-12 d-flex align-items-center justify-content-end">
              <div class="stage-actions hover-action-items">
                <button
                  class="btn btn-none p-0"
                  type="button"
                  (click)="copyStage(stage.displayOrder)">
                  <app-icon
                    [type]="'copy'"
                    [width]="18"
                    [height]="18"></app-icon>
                </button>
                <button class="btn btn-none py-1" type="button">
                  <app-icon
                    [type]="'trash'"
                    [width]="18"
                    [height]="18"
                    (click)="deleteStage(stage)"></app-icon>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  [disabled]="
                    stage.stageStatus === 'Closed' || stage?.enableEdit
                  "
                  (click)="showEditStage(stage)">
                  Edit
                </button>
              </div>
              <div class="stage-actions toggle-accordion ps-3">
                <button
                  ngbAccordionButton
                  class="border-0"
                  type="button"
                  (click)="hideEditSage(stage)"></button>
              </div>
            </div>
            <div class="col-12">&nbsp;</div>
          </div>
        </div>
      </div>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody *ngIf="stage.enableExpanded">
          <app-section-view
            [sections]="stage?.sections"
            [criterias]="stage?.criterias"
            [stageTypeId]="stage.stageTypeId"
            [stageId]="stage?.id"
            [stageStatus]="stage.stageStatus"
            [stageDisplayOrder]="stage.displayOrder"
            [buildFlowFocus]="buildFlowFocus"
            [enableErrorsWarnings]="enableErrorsWarnings"></app-section-view>
          <app-build-flow-form-container
            [enableSection]="true"
            [stageDisplayOrder]="
              stage.displayOrder
            "></app-build-flow-form-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-build-flow-form-container
  [enableStage]="true"></app-build-flow-form-container>
