<div class="review-grid-container">
  <div class="table-container" *ngIf="scoreCardData">
    <table class="table review-table table-bordered text-center">
      <thead>
        <tr>
          <th>
            <div class="switch-view" (click)="changeView()">
              <app-icon
                class="mt-2 switch-icon"
                [type]="'refresh-cw'"
                [width]="15"
                [height]="15"
                [strokeWidth]="2"></app-icon>
              {{
                isSupplierView
                  ? 'Switch to evaluator View'
                  : 'Switch to Supplier View'
              }}
            </div>
            <div class="form-floating suppliers-dropdown">
              <select
                [(ngModel)]="selectedSupplierId"
                (change)="refreshGrid()"
                class="form-select"
                id="suppliers"
                name="suppliers"
                placeholder="Suppliers"
                aria-label="Suppliers">
                <option
                  *ngFor="
                    let supplier of suppliersList | orderBy: 'supplierName'
                  "
                  value="{{ supplier.supplierId }}">
                  {{ supplier.supplierName }}
                </option>
              </select>
              <label for="stage-type">Suppliers</label>
            </div>
          </th>
          <th class="score-header" *ngIf="!isSupplierView">
            {{ 'Weighted Score' }}
          </th>
          <ng-container *ngIf="isSupplierView; else evaluatorView">
            <th
              *ngFor="let supplier of displayedItems | orderBy: 'supplierName'">
              {{ supplier.supplierName }}
            </th>
          </ng-container>
          <ng-template #evaluatorView>
            <th
              *ngFor="
                let evaluator of displayedItems | orderBy: 'evaluatorName'
              ">
              <div class="user-icon">
                {{ getInitials(evaluator.evaluatorName) }}
              </div>
              {{ evaluator.evaluatorName }}
            </th>
          </ng-template>
        </tr>
      </thead>
      <tbody *ngIf="scoreCardData?.selectedStage">
        <tr>
          <td class="stage-dropdown">
            <div class="form-floating">
              <select
                [(ngModel)]="selectedStageId"
                (change)="refreshGrid()"
                class="form-select"
                id="stages"
                name="stages"
                placeholder="Stages"
                aria-label="Stages">
                <option
                  *ngFor="let stage of stagesList | orderBy: 'displayOrder'"
                  value="{{ stage.id }}">
                  {{
                    stage.displayOrder +
                      ' ' +
                      stage.title +
                      ' - ' +
                      stage.totalScore +
                      'Pts'
                  }}
                </option>
              </select>
              <label for="stage-type">Stages</label>
            </div>
          </td>
          <td *ngIf="!isSupplierView">
            {{ scoreCardData?.selectedStage?.actualWeightedScore }}/{{
              scoreCardData?.selectedStage?.expectedWeightedScore
            }}
          </td>
          <ng-container *ngIf="isSupplierView; else stageView">
            <td
              *ngFor="
                let supplier of displayedStageItems | orderBy: 'supplierName'
              ">
              {{ supplier?.actualScore }}/{{ supplier?.expectedScore }}
            </td>
          </ng-container>
          <ng-template #stageView>
            <td
              *ngFor="
                let evaluator of displayedStageItems | orderBy: 'evaluatorName'
              ">
              {{ evaluator.evaluatorScore }}
            </td>
          </ng-template>
        </tr>

        <ng-container
          *ngFor="
            let item of getSectionAndCriteriasList(
              scoreCardData?.selectedStage?.sections,
              scoreCardData?.selectedStage?.criterias
            ) | orderBy: 'displayOrder';
            let sectionIndex = index
          ">
          <tr>
            <td class="column-1">
              {{
                scoreCardData?.selectedStage?.displayOrder +
                  '.' +
                  item?.displayOrder +
                  '.'
              }}{{ item?.title }}
            </td>
            <td *ngIf="!isSupplierView">
              {{ item?.actualWeightedScore }}/{{ item?.expectedWeightedScore }}
            </td>
            <ng-container *ngIf="isSupplierView; else sectionView">
              <td
                *ngFor="
                  let supplier of displayedSectionCriteriaItems(item)
                    | orderBy: 'supplierName'
                ">
                {{ supplier?.actualScore }}/{{ supplier?.expectedScore }}
              </td>
            </ng-container>
            <ng-template #sectionView>
              <td
                *ngFor="
                  let evaluator of displayedSectionCriteriaItems(item)
                    | orderBy: 'evaluatorName'
                ">
                {{ evaluator?.evaluatorScore }}
              </td>
            </ng-template>
          </tr>
          <ng-container *ngIf="item.hasOwnProperty('evaluationStageId')">
            <ng-container
              *ngFor="
                let sectionCriteria of item?.criterias
                  | orderBy: 'displayOrder';
                let criteriaIndex = index
              ">
              <tr>
                <td class="column-1">
                  {{
                    scoreCardData?.selectedStage?.displayOrder +
                      '.' +
                      (sectionIndex + 1) +
                      '.' +
                      (criteriaIndex + 1) +
                      '.'
                  }}{{ sectionCriteria?.title }}
                  <span
                    *ngIf="sectionCriteria?.attachmentCount > 0"
                    class="attachment"
                    ><app-icon
                      class="mt-2"
                      [type]="'paperclip'"
                      [width]="15"
                      [height]="15"></app-icon
                    >{{ sectionCriteria?.attachmentCount }}</span
                  >
                </td>
                <td *ngIf="!isSupplierView">
                  {{ sectionCriteria?.actualWeightedScore }}/{{
                    sectionCriteria?.expectedWeightedScore
                  }}
                </td>
                <ng-container *ngIf="isSupplierView; else criteriaView">
                  <td
                    *ngFor="
                      let supplier of displayedSectionCriteriaItems(
                        sectionCriteria
                      ) | orderBy: 'supplierName'
                    ">
                    {{ supplier?.actualScore }}/{{ supplier?.expectedScore }}
                  </td>
                </ng-container>
                <ng-template #criteriaView>
                  <td
                    *ngFor="
                      let evaluator of displayedSectionCriteriaItems(
                        sectionCriteria
                      ) | orderBy: 'evaluatorName'
                    ">
                    {{ evaluator?.evaluatorScore }}
                  </td>
                </ng-template>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- <tr class="highlight-cell">
          <td class="text-left">
            1.1.1 Company Overview<br />
            <span class="consensus">Lack of Consensus</span>
            <span class="attachment"
              ><app-icon
                class="mt-2"
                [type]="'paperclip'"
                [width]="15"
                [height]="15"></app-icon
              >2</span
            >
          </td>
          <td>2/2</td>
          <td>2</td>
          <td>
            <app-icon
              [class]="'error info-tooltip'"
              [type]="'alert-circle'"
              [width]="20"
              [height]="20"
              triggers="hover"
              container="body"
              tooltipClass="custom-tooltip"
              [ngbTooltip]="tooltipContent"
              placement="top"></app-icon
            >1
          </td>
          <ng-template #tooltipContent>
            <div class="tooltip-header">
              <b>{{ tooltipHeader }}</b>
            </div>
            <div class="tooltip-body">{{ tooltipText }}</div>
          </ng-template>
          <td>2</td>
          <td>2</td>
        </tr> -->

        <tr>
          <td class="column-1">Total</td>
          <ng-container *ngIf="isSupplierView; else totalView">
            <td
              *ngFor="
                let supplier of displayedStageItems | orderBy: 'supplierName'
              ">
              {{ supplier?.actualScore }}/{{ supplier?.expectedScore }}
            </td>
          </ng-container>
          <ng-template #totalView>
            <td class="b-none">
              {{ scoreCardData?.selectedStage?.actualWeightedScore }}/{{
                scoreCardData?.selectedStage?.expectedWeightedScore
              }}
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="pagination-footer justify-content-between">
        <span>
          <strong>
            {{ isSupplierView ? 'Suppliers' : 'Evaluators' }}
            {{ currentRange }}
          </strong></span
        >
        <button
          class="btn navigation-btn btn-primary"
          (click)="previousPage()"
          [disabled]="currentPage === 1">
          <app-icon
            class="chevron-left back-btn-link"
            [type]="'chevron-left'"
            [width]="18"
            [height]="18">
          </app-icon>
        </button>
        <button
          class="btn navigation-btn btn-primary"
          (click)="nextPage()"
          [disabled]="
            currentPage * itemsPerPage >=
            (isSupplierView ? suppliersList.length : evaluatorList.length)
          ">
          <app-icon
            class="chevron-right forward-btn-link"
            [type]="'chevron-right'"
            [width]="18"
            [height]="18">
          </app-icon>
        </button>
      </div>
    </div>
  </div>
</div>
