import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceUrl } from 'src/app/shared/constants/api-urls';

@Injectable({
  providedIn: 'root',
})
export class SupplierStatusService {
  constructor(private http: HttpClient) {}

  getSupplierStatusList(evaluationId: string): Observable<any> {
    return this.http.get(
      `${serviceUrl.supplierStatusList}/${evaluationId}/supplierstatus`
    );
  }
  setSupplierqualifystatus(data: any): Observable<any> {
    return this.http.post(`${serviceUrl.supplierqualifystatus}`, data);
  }
  setUnsealpricing(data: any): Observable<any> {
    return this.http.post(`${serviceUrl.unsealpricing}`, data);
  }
}
