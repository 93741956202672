import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { StoreService } from 'src/app/core/services/store.service';
import { StageTypes } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-stage-view',
  templateUrl: './stage-view.component.html',
  styleUrls: ['./stage-view.component.scss'],
})
export class StageViewComponent implements OnInit, OnDestroy {
  constructor(
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}

  @Input() stages: any = [];
  @Input() enableErrorsWarnings = false;
  @Input() buildFlowFocus: any = {};

  @ViewChild('stageAccordion') stageAccordion: any;

  stageTypes: any = StageTypes;
  selectedEditStageDisplayOrder = 0;
  buildEditFlowSubscription!: Subscription;

  trackByFn(index: number, stage: any) {
    return stage.id ? stage.id : stage.uniqueTimestamp;
  }

  closeNestedAccordion(stage: any) {
    stage.sections.forEach((section: any) => {
      section['enableEdit'] = false;
      section['enableExpanded'] = false;
    });
    stage.criterias.forEach((criteria: any) => {
      criteria['enableEdit'] = false;
      criteria['enableExpanded'] = false;
    });
  }
  showEditStage(stage: any) {
    this.store.setBuildFlowAccodionFocus({ stage: stage.displayOrder });
    if (stage.stageStatus == 'Active') {
      this.selectedEditStageDisplayOrder = stage.displayOrder;
      this.store.setBuildFlowActionsData({
        data: stage,
        type: 'stage',
        action: 'edit',
      });
    } else {
      stage['enableEdit'] = true;
      stage['enableExpanded'] = true;
      this.stageAccordion.expand(`stage-item-${stage.displayOrder}`);
    }
  }
  editActiveSelectedStage() {
    this.buildEditFlowSubscription =
      this.store.getBuildFlowEditActionsData.subscribe((data): any => {
        if (
          data.action === 'edit' &&
          data?.type === 'stage' &&
          this.selectedEditStageDisplayOrder === data.data.displayOrder
        ) {
          data.data['enableEdit'] = true;
          data.data['enableExpanded'] = true;
          this.selectedEditStageDisplayOrder = 0;
          this.stageAccordion.expand(`stage-item-${data.data.displayOrder}`);
        }
      });
  }

  hideEditSage(stage: any) {
    stage['enableEdit'] = false;
    stage['enableExpanded'] = !stage['enableExpanded'];
    this.store.setBuildFlowAccodionFocus({ stage: stage.displayOrder });
    this.closeNestedAccordion(stage);
  }

  copyStage(displayOrder: number) {
    this.store.setBuildFlowAccodionFocus({ stage: displayOrder });
    this.store.setBuildFlowActionsData({
      displayOrder: displayOrder,
      type: 'stage',
      action: 'copy',
    });
  }

  deleteStage(stage: any) {
    this.store.setBuildFlowAccodionFocus({ stage: stage.displayOrder });
    this.store.setBuildFlowActionsData({
      evaluationStageId: stage.id,
      title: stage.title,
      displayOrder: stage.displayOrder,
      stageStatus: stage.stageStatus,
      type: 'stage',
      action: 'delete',
      isLocalDelete: stage.id ? false : true,
    });
  }
  dropStages(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stages, event.previousIndex, event.currentIndex);
    this.store.setBuildFlowActionsData({
      type: 'stage',
      action: 'dragdrop',
      data: this.stages,
    });
  }

  ngOnInit(): void {
    this.editActiveSelectedStage();
  }

  ngOnDestroy(): void {
    if (this.buildEditFlowSubscription) {
      this.buildEditFlowSubscription.unsubscribe();
    }
  }
}
