import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private sidebarExpanded$ = new Subject<boolean>();

  private nodeId$: BehaviorSubject<string> = new BehaviorSubject('');
  private customerId$: BehaviorSubject<string> = new BehaviorSubject('');
  private projectId$: BehaviorSubject<string> = new BehaviorSubject('');
  private project$: BehaviorSubject<any> = new BehaviorSubject({});

  private evaluationId$: BehaviorSubject<any> = new BehaviorSubject('');
  private evaluationDetails$: BehaviorSubject<any> = new BehaviorSubject({});
  private buildFlowActions$ = new Subject<any>();
  private proposalResponse$ = new BehaviorSubject<any>([]);
  private stages$ = new BehaviorSubject<any>([]);
  private editBuildFlowAction$ = new Subject<any>();
  private stopAutoSaveBuildFlow$: BehaviorSubject<any> = new BehaviorSubject(
    false
  );
  private buildFlowAccodionFocus$ = new Subject<any>();
  private evaluationReviewMeetingId$: BehaviorSubject<any> =
    new BehaviorSubject('');
  setSidebarExpanded(data: any) {
    this.sidebarExpanded$.next(data);
  }
  get getSidebarExpanded() {
    return this.sidebarExpanded$.asObservable();
  }

  getNodeId() {
    return this.nodeId$?.getValue();
  }
  getCustomerId() {
    return this.customerId$?.getValue();
  }
  getProjectId() {
    return this.projectId$?.getValue();
  }
  getProjectInfo() {
    return this.project$?.getValue();
  }

  setProData(value: any) {
    if (value?.nodeId) {
      this.nodeId$.next(value.nodeId);
    }
    if (value?.customerId) {
      this.customerId$.next(value.customerId);
    }
    if (value?.project) {
      this.projectId$.next(value.project?.id);
    }
    if (value?.project) {
      this.project$.next(value.project);
    }
  }

  updateEvaluationId(id: string) {
    this.evaluationId$.next(id);
  }

  getEvaluationId() {
    return this.evaluationId$?.getValue();
  }
  updateEvaluationReviewMeetingId(id: string) {
    this.evaluationReviewMeetingId$.next(id);
  }

  getEvaluationReviewMeetingId() {
    return this.evaluationReviewMeetingId$?.getValue();
  }

  get getEvaluationIdAsObservable() {
    return this.evaluationId$.asObservable();
  }

  getEvaluationScoringMethodId() {
    return this.evaluationDetails$?.getValue()?.scoringMethodId;
  }

  updateEvaluationDetails(data: any) {
    this.evaluationDetails$.next(data);
  }
  get getEvaluationDetails() {
    return this.evaluationDetails$.asObservable();
  }

  setBuildFlowActionsData(data: any) {
    this.buildFlowActions$.next(data);
  }
  get getBuildFlowActionsData() {
    return this.buildFlowActions$.asObservable();
  }

  setBuildFlowEditActionsData(data: any) {
    this.editBuildFlowAction$.next(data);
  }
  get getBuildFlowEditActionsData() {
    return this.editBuildFlowAction$.asObservable();
  }

  setProposalResponse(data: any) {
    this.proposalResponse$.next(data);
  }

  getProposalResponse() {
    return this.proposalResponse$?.getValue();
  }

  setStagesData(data: any) {
    this.stages$.next(data);
  }

  get getStagesSubscribe() {
    return this.stages$.asObservable();
  }
  getStagesData() {
    return this.stages$?.getValue();
  }

  setStopAutoSaveBuildFlow(status: boolean) {
    this.stopAutoSaveBuildFlow$.next(status);
  }

  getStopAutoSaveBuildFlow() {
    return this.stopAutoSaveBuildFlow$?.getValue();
  }

  setBuildFlowAccodionFocus(data: any) {
    this.buildFlowAccodionFocus$.next(data);
  }
  get getBuildFlowAccodionFocus() {
    return this.buildFlowAccodionFocus$.asObservable();
  }
}
