<div
  class="add-edit-criteria build-flow-add-edit-form-container p-2"
  [ngClass]="{ 'is-edit': formData }">
  <div
    class="add-edit-criteria-form build-flow-add-edit-form-wrapper py-2 px-4">
    <div class="drag-icon-disable">
      <img
        [src]="path.getAssetUrl('/assets/svg/drag-icon-disable.svg')"
        alt="Drag and drop disabled icon" />
    </div>
    <form [formGroup]="criteriaForm" name="add-edit-criteria" novalidate>
      <div class="row mx-0 d-flex align-items-center">
        <div class="col-12 col-sm-3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="title"
              class="form-control input-container"
              id="criteria-title-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-title-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              placeholder="Criteria title"
              aria-label="Criteria title" />

            <span class="seq-num" *ngIf="!sectionDisplayOrder">
              {{ stageDisplayOrder }}.{{ formData.displayOrder }}.
            </span>
            <span class="seq-num" *ngIf="sectionDisplayOrder">
              {{ stageDisplayOrder }}.{{ sectionDisplayOrder }}.{{
                formData.displayOrder
              }}.
            </span>
            <label
              for="criteria-title-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              >Criteria Title</label
            >
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-floating">
            <ng-select
              #selectDropdown
              formControlName="proposalResponseMappings"
              id="criteria-proposal-response-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-proposal-response"
              placeholder="Proposal Response"
              aria-label="Select proposal responses"
              [ngClass]="{
                'has-value': criteriaForm?.value?.proposalResponseMappings,
              }"
              [items]="proposalResponseList"
              [multiple]="true"
              bindLabel="name"
              groupBy="typeName"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              [closeOnSelect]="false"
              [dropdownPosition]="'bottom'"
              [clearOnBackspace]="true">
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index">
                <input
                  id="item-group-{{ index }}"
                  type="checkbox"
                  [ngModel]="item$.selected"
                  [ngModelOptions]="{ standalone: true }" />
                {{ item.typeName }}
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index">
                <input
                  id="item-{{ index }}"
                  type="checkbox"
                  [ngModel]="item$.selected"
                  [ngModelOptions]="{ standalone: true }" />
                {{ item.name }}
              </ng-template>
              <ng-template ng-footer-tmp>
                <div class="criteria-dropdown-footer">
                  <button
                    (click)="saveSelection()"
                    class="btn btn-primary"
                    aria-label="Save proposal response selection">
                    Save
                  </button>
                  <button
                    (click)="cancelSelection()"
                    class="btn btn-secondary"
                    aria-label="Cancel proposal response selection">
                    Cancel
                  </button>
                </div>
              </ng-template>
            </ng-select>
            <!-- <label for="criteria-proposal-response">Proposal Response</label> -->
          </div>
        </div>
        <div
          class="col-12 col-sm-2"
          *ngIf="stageTypeId === 1 || stageTypeId === 2">
          <div class="form-floating">
            <input
              type="text"
              formControlName="ratingScale"
              class="form-control"
              id="criteria-rating-scale-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-points-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              placeholder="Rating Scale"
              (input)="formatInput($event)"
              [(ngModel)]="maskedValue"
              aria-label="Enter rating scale" />
            <label
              for="criteria-rating-scale-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              >Rating Scale</label
            >
          </div>
        </div>
        <!--Start criteria type pass/fail area -->
        <div class="col-12 col-sm-2" *ngIf="stageTypeId === 3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="criteria-pass-fail-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-pass-fail-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              placeholder="Pass/Fail"
              aria-label="Pass/Fail criteria"
              disabled />
            <label
              for="criteria-pass-fail-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              >Pass/Fail</label
            >
          </div>
        </div>
        <!--End criteria type pass/fail area -->

        <!--Start criteria pricing formula area -->
        <div class="col-12 col-sm-2" *ngIf="stageTypeId === 4">
          <div class="form-floating">
            <select
              class="form-select"
              formControlName="pricingFormulaId"
              id="criteria-pricing-formula-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-pricing-formula-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              placeholder="Pricing Formula"
              aria-label="Select pricing formula"
              [ngClass]="{
                'has-value': criteriaForm?.value?.pricingFormulaId,
              }">
              <option
                *ngFor="let price of pricingFormulaList; index as priceIndex"
                [ngValue]="price.value">
                {{ price.title }}
              </option>
            </select>
            <label
              for="criteria-pricing-formula-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              >Pricing Formula</label
            >
          </div>
        </div>
        <!--End criteria pricing formula area -->

        <div class="col-12 col-sm-3" *ngIf="stageTypeId !== 4">
          <div class="selected-evaluators">
            {{ formData.assignedEvaluatorCount | pluralMessage: 'Evaluator' }}
          </div>
          <a
            href="javascript:void(0)"
            (click)="openAssignModal()"
            [class.disabled]="!formData.id"
            >Manage Evaluators</a
          >
        </div>
      </div>
      <div
        class="row mx-0 mt-3 pricing-formula-low-highest-info"
        *ngIf="stageTypeId === 4">
        <div class="col offset-sm-6">
          <div *ngIf="evaluationScoringMethodId === 1">
            <p class="mb-0" *ngIf="criteriaForm?.value?.pricingFormulaId === 1">
              Low Bid Formula: Lowest (value bid/supplier bid) x Total point
              (value)
            </p>
            <p class="mb-0" *ngIf="criteriaForm?.value?.pricingFormulaId === 2">
              Revenue Generating Formula: Highest (supplier bid/ highest $ value
              bid) x Total point (value)
            </p>
          </div>
          <div *ngIf="evaluationScoringMethodId === 2">
            <p class="mb-0" *ngIf="criteriaForm?.value?.pricingFormulaId === 1">
              Low Bid Formula: Lowest (value bid/supplier bid) x Total weight
              (value)
            </p>
            <p class="mb-0" *ngIf="criteriaForm?.value?.pricingFormulaId === 2">
              Revenue Generating Formula: Highest (supplier bid/ highest $ value
              bid) x Total weight (value)
            </p>
          </div>
        </div>
      </div>
      <div class="row mx-0 mt-3">
        <div class="col-12 col-sm-2 offset-sm-6">
          <div class="form-floating" *ngIf="stageTypeId && stageTypeId !== 3">
            <input
              type="text"
              formControlName="score"
              class="form-control"
              id="criteria-score-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              name="criteria-score-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              [placeholder]="
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              "
              [attr.aria-describedby]="
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              " />
            <label
              for="criteria-score-{{ stageDisplayOrder }}-{{
                sectionDisplayOrder ? sectionDisplayOrder : '0'
              }}-{{ formData.displayOrder }}"
              >{{
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              }}</label
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<app-assign-evaluators
  *ngIf="showAssignEvaluatorsModal"
  [criteriaDetails]="formData"
  [stageDisplayOrder]="stageDisplayOrder"
  [sectionDisplayOrder]="sectionDisplayOrder"
  (cancelModal)="cancelModal()"></app-assign-evaluators>
