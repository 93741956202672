import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StageTypesList,
  StageTypeEnum,
} from 'src/app/shared/constants/constants';
import { FormBuilder, FormControl } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { StoreService } from 'src/app/core/services/store.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-stage',
  templateUrl: './add-edit-stage.component.html',
  styleUrls: ['./add-edit-stage.component.scss'],
})
export class AddEditStageComponent implements OnInit, OnDestroy {
  @Input() formData: any = null;

  stageForm: any = this.fb.group({
    title: [null],
    stageTypeId: [null],
    startDate: [null],
    endDate: [null],
    totalScore: [null],
    minThreshold: [null],
  });
  constructor(
    public fb: FormBuilder,
    private focusMonitor: FocusMonitor,
    private el: ElementRef<HTMLElement>,
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}

  evaluationScoringMethodId: number = this.store.getEvaluationScoringMethodId(); // 1 for Points 2 for Weight
  stageTypesList: any = [];

  get stageTypeId(): FormControl {
    return this.stageForm.get('stageTypeId') as FormControl;
  }
  assginStageType() {
    if (this.evaluationScoringMethodId === 1) {
      this.stageTypesList = StageTypesList.filter(
        (type: any) => type.value !== StageTypeEnum.WEIGHT
      );
    }
    if (this.evaluationScoringMethodId === 2) {
      this.stageTypesList = StageTypesList.filter(
        (type: any) => type.value !== StageTypeEnum.POINTS
      );
    }
  }

  onChangeStageType() {
    const stageTypeId = this.stageTypeId;
    stageTypeId.valueChanges.subscribe(() => {
      this.stageForm.patchValue({
        totalScore: null,
        minThreshold: null,
      });
    });
  }
  assignFormData() {
    this.stageForm.patchValue({
      title: this.formData.title,
      stageTypeId: this.formData.stageTypeId,
      totalScore: this.formData.totalScore,
      minThreshold: this.formData.minThreshold,
      startDate: this.setDate(this.formData.startDateUtc),
      endDate: this.setDate(this.formData.endDateUtc),
    });
  }
  setDate(date: string): NgbDateStruct | null {
    if (!date) return null;
    const today = new Date(date);
    return {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
  }
  convertToDate(ngbDate: NgbDateStruct): Date | null {
    return ngbDate
      ? new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
      : null; // Month is 0-based
  }
  formValuesChanges() {
    this.stageForm.valueChanges
      .pipe(
        tap(() => {
          this.store.setStopAutoSaveBuildFlow(true);
          this.formData.stageTypeId = this.stageForm.value.stageTypeId;
        }),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.formData.title = this.stageForm.value.title;
        this.formData.stageTypeId = this.stageForm.value.stageTypeId;
        this.formData.totalScore = this.stageForm.value.totalScore;
        this.formData.minThreshold = this.stageForm.value.minThreshold;
        this.formData.startDateUtc = this.convertToDate(
          this.stageForm.value?.startDate
        );
        this.formData.endDateUtc = this.convertToDate(
          this.stageForm.value?.endDate
        );
        this.store.setStopAutoSaveBuildFlow(false);
      });
  }
  monitorFocus() {
    this.focusMonitor
      .monitor(this.el?.nativeElement, true)
      .subscribe(origin => {
        if (origin) {
          this.store.setBuildFlowAccodionFocus({
            stage: this.formData.displayOrder,
          });
        }
      });
  }
  ngOnInit(): void {
    this.assginStageType();
    this.onChangeStageType();
    this.formValuesChanges();
    if (this.formData) {
      this.assignFormData();
    }
    this.monitorFocus();
  }
  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.el?.nativeElement);
  }
}
