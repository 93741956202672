import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  parse(value: string): NgbDateStruct | null {
    if (!value) return null;
    const parts = value.split('/');
    if (parts.length !== 3) return null;

    const monthIndex = this.MONTHS.indexOf(parts[0]);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    return monthIndex !== -1 && !isNaN(day) && !isNaN(year)
      ? { year, month: monthIndex + 1, day }
      : null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${this.MONTHS[date.month - 1]} ${date.day}/${date.year}`
      : '';
  }
}
