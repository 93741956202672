import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthTokenInterceptor } from './core/interceptors/auth-token-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastContainerDirective } from 'ngx-toastr';
import { ErrorCatchingInterceptor } from './core/interceptors/error-catching.interceptor';
import { createCustomElement } from '@angular/elements';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { StatusComponent } from './core/components/status/status.component';
import { IconComponent } from './core/components/icon/icon.component';
import { PageLoaderComponent } from './core/components/page-loader/page-loader.component';
import { ConfirmDialogComponent } from './core/components/confirm-dialog/confirm-dialog.component';
import { TextEditorComponent } from './core/components/text-editor/text-editor.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LandingPageErrorComponent } from './core/components/landing-page-error/landing-page-error.component';
import { ModalComponent } from './core/components/modal/modal.component';
import { globalModalComponent } from './core/components/global-modal/global-modal.component';
import { ManageEvaluatorsComponent } from './modules/components/manage-evaluators/manage-evaluators.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgPipesModule } from 'ngx-pipes';
import { AssignEvaluatorsComponent } from './modules/components/assign-evaluators/assign-evaluators.component';
import { GetFirstCharPipe } from './shared/pipes/get-first-char.pipe';
import { BuildEvaluationComponent } from './modules/pages/build-evaluation/build-evaluation.component';
import { AddEditEvaluationComponent } from './modules/components/add-edit-evaluation/add-edit-evaluation.component';
import { LaunchBuilderComponent } from './modules/components/launch-builder/launch-builder.component';
import { StageViewComponent } from './modules/components/stage-view/stage-view.component';
import { AddEditStageComponent } from './modules/components/add-edit-stage/add-edit-stage.component';
import { SectionViewComponent } from './modules/components/section-view/section-view.component';
import { AddEditSectionComponent } from './modules/components/add-edit-section/add-edit-section.component';
import { CriteriaViewComponent } from './modules/components/criteria-view/criteria-view.component';
import { AddEditCriteriaComponent } from './modules/components/add-edit-criteria/add-edit-criteria.component';
import { BuildFlowFormContainerComponent } from './modules/components/build-flow-form-container/build-flow-form-container.component';
import { BuildEvaluationLandingPageErrorsComponent } from './modules/components/build-evaluation-landing-page-errors/build-evaluation-landing-page-errors.component';
import { PluralMessagePipe } from './shared/pipes/plural-message.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EvaluationTeamComponent } from './modules/pages/evaluation-team/evaluation-team.component';
import { EvaluationTeamLandingPageComponent } from './modules/components/evaluation-team-landing-page/evaluation-team-landing-page.component';
import { EvaluatorListComponent } from './modules/components/evaluator-list/evaluator-list.component';
import { AssignStagesComponent } from './modules/components/assign-stages/assign-stages.component';
import { EvaluationGuideComponent } from './modules/components/evaluation-guide/evaluation-guide.component';
import { NonDisclosureAgreementComponent } from './modules/components/non-disclosure-agreement/non-disclosure-agreement.component';
import { ConflictOfInterestComponent } from './modules/components/conflict-of-interest/conflict-of-interest.component';
import { ReviewConflictComponent } from './modules/components/review-conflict/review-conflict.component';
import { DismissCoiComponent } from './modules/components/dismiss-coi/dismiss-coi.component';
import { ReviewMeetingsComponent } from './modules/pages/review-meetings/review-meetings.component';
import { SupplierStatusComponent } from './modules/pages/supplier-status/supplier-status.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReviewDecisionComponent } from './modules/components/review-decision/review-decision.component';
import { ReviewGridComponent } from './modules/components/review-grid/review-grid.component';
import { CustomDateParserFormatter } from './core/services/core/custom-date-parser.service';
import { ReviewComponent } from './modules/pages/review-meetings/review/review.component';

@NgModule({
  declarations: [
    ReviewComponent,
    AppComponent,
    ReviewMeetingsComponent,
    SupplierStatusComponent,
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
    TextEditorComponent,
    LoaderComponent,
    LandingPageErrorComponent,
    ModalComponent,
    ManageEvaluatorsComponent,
    AssignEvaluatorsComponent,
    GetFirstCharPipe,
    BuildEvaluationComponent,
    AddEditEvaluationComponent,
    LaunchBuilderComponent,
    StageViewComponent,
    AddEditStageComponent,
    SectionViewComponent,
    AddEditSectionComponent,
    CriteriaViewComponent,
    AddEditCriteriaComponent,
    BuildFlowFormContainerComponent,
    BuildEvaluationLandingPageErrorsComponent,
    PluralMessagePipe,
    EvaluationTeamComponent,
    EvaluationTeamLandingPageComponent,
    EvaluatorListComponent,
    AssignStagesComponent,
    EvaluationGuideComponent,
    NonDisclosureAgreementComponent,
    ConflictOfInterestComponent,
    ReviewConflictComponent,
    DismissCoiComponent,
    ReviewDecisionComponent,
    ReviewGridComponent,
    globalModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    CKEditorModule,
    NgPipesModule,
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    DragDropModule,
    ToastrModule.forRoot({
      disableTimeOut: true,
      tapToDismiss: false,
      closeButton: true,
      newestOnTop: true,
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
    }),
    ToastContainerDirective,
    NgbModule,
  ],
  providers: [
    DatePipe,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
  ],
  entryComponents: [AppComponent],
  bootstrap: [],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {}
  ngDoBootstrap(): void {
    const el = createCustomElement(AppComponent, { injector: this.injector });
    customElements.define('rfx-app', el);
  }
}
