import { Component, OnInit } from '@angular/core';
import { EvaluationService } from './core/services/evaluation.service';
import { StoreService } from './core/services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private evaluation: EvaluationService,
    private store: StoreService
  ) {}
  title = 'RFx';

  // Getting evaluation already exist or not
  getEvaluation() {
    this.evaluation.getEvaluation().subscribe({
      next: (data: any) => {
        if (data?.evaluationExists && data?.evaluationId) {
          this.store.updateEvaluationId(data.evaluationId);
        }
      },
    });
  }
  ngOnInit(): void {
    this.getEvaluation();
  }
}
