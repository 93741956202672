import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-non-disclosure-agreement',
  templateUrl: './non-disclosure-agreement.component.html',
  styleUrls: ['./non-disclosure-agreement.component.scss'],
})
export class NonDisclosureAgreementComponent {
  @Input() selectedProject: any = null;
  @Output() continue = new EventEmitter<number>();
  @Output() goBack: EventEmitter<boolean> = new EventEmitter<boolean>();
  showLoader = false;

  isAcknowledged = false;

  backToDashboard(): void {
    this.goBack.emit();
  }
}
