import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EvaluationTeamService } from 'src/app/core/services/evaluation-team/evaluation-team.service';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import { IEvaluatorList } from 'src/app/shared/constants/lists';
import { EvaluatorListComponent } from '../../components/evaluator-list/evaluator-list.component';
import { StoreService } from 'src/app/core/services/store.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-evaluation-team',
  templateUrl: './evaluation-team.component.html',
  styleUrls: ['./evaluation-team.component.scss'],
})
export class EvaluationTeamComponent implements OnInit, OnDestroy {
  addAssignStageRequest: any = {
    evaluationId: '',
    createdBy: '',
    evaluator: [
      {
        id: '',
        teamMemberId: '',
      },
    ],
    addAssignmentId: [],
    removeAssignmentIDs: [],
  };
  constructor(
    private pageLoader: PageLoaderService,
    private evaluationTeamService: EvaluationTeamService,
    private store: StoreService
  ) {}
  evaluationId = this.store.getEvaluationId();
  evaluatorsListError = false;
  evaluatorsList: IEvaluatorList[] = [];
  actionsList = [
    { label: 'Assign Stage', value: 1, disabled: false },
    { label: 'Send Reminder', value: 2, disabled: true },
    { label: 'Remove Evaluators', value: 3, disabled: false },
  ];
  showAssignStagesModal = false;
  showManageEvaluatorsModal = false;
  showRemoveAssignedStageDialog = false;
  showRemoveEvaluatorDialog = false;
  showSendReminderDialog = false;
  selectedStageId: string | null = null;
  emptyStagesExist = false;
  evaluationIdSubscription!: Subscription;
  selectedEvaluatorList: string[] = [];

  @ViewChild(EvaluatorListComponent) child!: EvaluatorListComponent;

  toggleManageEvaluatorsModal() {
    this.showManageEvaluatorsModal = !this.showManageEvaluatorsModal;
  }

  toggleAssignStagesModal(teamMemberId: string) {
    this.selectedEvaluatorList = [teamMemberId];
    this.showAssignStagesModal = !this.showAssignStagesModal;
  }

  onClickActions(action: number) {
    if (action === 1) {
      this.showAssignStagesModal = true;
      this.selectedEvaluatorList = this.child?.evaluatorsList
        ?.filter((evaluator: any) => evaluator.inActive)
        .map((eva: any) => eva.teamMemberId);
    } else if (action === 2) {
      if (this.emptyStagesExist) this.showSendReminderDialog = true;
    } else if (action === 3) {
      this.showRemoveEvaluatorDialog = true;
    }
  }

  disableActionDropdown() {
    const flag = this.child?.evaluatorsList?.filter(
      (evaluator: any) => evaluator.inActive
    );

    if (flag?.length) {
      const sendReminderFlag = flag.some(
        (element: any, i: number) => element?.stages?.length
      );

      this.emptyStagesExist = flag.some(
        (element: any, i: number) => !element?.stages?.length
      );

      this.actionsList[1].disabled = !sendReminderFlag;
    }
    return flag ? flag.length : false;
  }

  getEvaluatorsList() {
    this.pageLoader.show();
    this.evaluationTeamService.getEvaluatorsList(this.evaluationId).subscribe({
      next: (data: any) => {
        this.evaluatorsListError = false;
        this.pageLoader.hide();
        data?.data?.evaluator.forEach((evaluator: any) => {
          evaluator.stages?.forEach((stage: any) => {
            stage.dateRange = stage.startDateUtc
              ? {
                  startDate: new Date(stage.startDateUtc),
                  endDate: new Date(stage.endDateUtc),
                }
              : null;
          });
        });
        this.evaluatorsList = data?.data?.evaluator
          ? [...data.data.evaluator]
          : [];
      },
      error: e => {
        this.pageLoader.hide();
        this.evaluatorsListError = true;
      },
    });
  }
  openRemoveAssignedStageDialog(stageData: any) {
    this.addAssignStageRequest.evaluationId = stageData.stage.evaluationId;
    this.addAssignStageRequest.evaluator[0].id = 0;
    this.addAssignStageRequest.evaluator[0].teamMemberId =
      stageData.teamMemberId;
    this.addAssignStageRequest.createdBy = stageData.stage.createdBy;
    this.addAssignStageRequest.removeAssignmentIDs =
      stageData.stage.assignStages;
    this.selectedStageId = stageData.teamMemberId;
    this.showRemoveAssignedStageDialog = true;
  }
  onConfirmRemoveAssignedStage() {
    this.evaluationTeamService
      .deleteEvaluationStage({
        addAssignStageRequest: this.addAssignStageRequest,
      })
      .subscribe({
        next: (data: any) => {
          this.onCancelRemoveAssignedStage();
          this.getEvaluatorsList();
        },
      });
  }
  onCancelRemoveAssignedStage() {
    this.showRemoveAssignedStageDialog = false;
  }
  onConfirmRemoveEvaluator() {
    this.selectedEvaluatorList = this.child?.evaluatorsList
      ?.filter((evaluator: any) => evaluator.inActive)
      .map((eva: any) => eva.teamMemberId);

    const removeEvaluators = this.selectedEvaluatorList.map((item: any) => ({
      id: item,
      teamMemberId: item,
    }));

    const removeEvaluatorsRequests = {
      evaluationId: this.store.getEvaluationId(),
      addEvaluators: [],
      removeEvaluators: removeEvaluators,
    };

    this.evaluationTeamService
      .saveEvaluatorsList(removeEvaluatorsRequests)
      .subscribe({
        next: (data: any) => {
          this.getEvaluatorsList();
          this.onCancelRemoveEvaluators();
        },
      });
  }
  onCancelRemoveEvaluators() {
    this.showRemoveEvaluatorDialog = false;
  }
  onConfirmSendReminder() {
    console.log('send remainder');
  }
  onCancelSendRemainer() {
    this.showSendReminderDialog = false;
  }
  subscribeStore() {
    this.evaluationIdSubscription =
      this.store.getEvaluationIdAsObservable.subscribe(id => {
        this.evaluationId = id;
        this.getEvaluatorsList();
      });
  }
  ngOnInit(): void {
    this.subscribeStore();
  }
  ngOnDestroy(): void {
    if (this.evaluationIdSubscription) {
      this.evaluationIdSubscription.unsubscribe();
    }
  }
}
