import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceUrl } from '../../shared/constants/api-urls';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  constructor(private http: HttpClient) {}

  getEvaluation(): Observable<any> {
    return this.http.get(serviceUrl.evaluationExists);
  }

  addUpdateEvaluation(data: any, type: any): Observable<any> {
    const method = type == 'Add' ? 'post' : 'put';
    return this.http[method](serviceUrl.evaluation, data);
  }

  removeEvaluation(data: any): Observable<any> {
    return this.http.delete(serviceUrl.evaluation, { body: data });
  }

  getEvaluationById(id: any): Observable<any> {
    return this.http.get(`${serviceUrl.evaluation}/${id}`);
  }

  getEvaluationStatusById(id: any): Observable<any> {
    return this.http.get(`${serviceUrl.evaluation}/status/${id}`);
  }

  getMasterData(): Observable<any> {
    return this.http.get(serviceUrl.getMasterDataForEvaluation);
  }

  getLaunchBuilderErrorsWarnings(evaluationId: any): Observable<any> {
    return this.http.get(`${serviceUrl.evaluation}/${evaluationId}/validate`);
  }
}
