import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-conflict-of-interest',
  templateUrl: './conflict-of-interest.component.html',
  styleUrls: ['./conflict-of-interest.component.scss'],
})
export class ConflictOfInterestComponent {
  @Input() selectedProject: any = null;
  showLoader = false;

  @Output() goBack: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmDialogStatus = new EventEmitter<boolean>();
  @Output() noCOIDialogStatus = new EventEmitter<boolean>();

  backToDashboard(): void {
    this.goBack.emit();
  }
}
