import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AssetsPathChangeService } from '../../services/core/assets-path-change.service';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss'],
})
export class globalModalComponent {
  @Output() closeModalEvent = new EventEmitter<void>();
  @Output() meetingActions = new EventEmitter<void>();
  @Input() additionalInfo = { buttonText: 'Start', isDisabled: true };
  constructor(public path: AssetsPathChangeService) {}

  leaveLaunchBuilder() {
    document.body.classList.remove('modal-open');
    this.closeModalEvent.emit();
  }
  isConcluded = false;

  toggleState(): void {
    //    this.isConcluded = !this.isConcluded;
    this.meetingActions.emit();
  }
}
