import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-review-conflict',
  templateUrl: './review-conflict.component.html',
  styleUrls: ['./review-conflict.component.scss'],
})
export class ReviewConflictComponent {
  [x: string]: any;
  @Input() evaluatorData: any = null;
  @Output() closeModalEvent = new EventEmitter<void>();

  showEvaluatorCOIDismissModal = false;
  showCOIModal = false;

  openCOIDismissModal() {
    this.showCOIModal = false;
    this.showEvaluatorCOIDismissModal = true;
  }
  closeCOIDismissModal(): void {
    this.showEvaluatorCOIDismissModal = false;
    this.showCOIModal = false;
    this.closeModalEvent.emit();
  }
  closeCOIModal(): void {
    this.showCOIModal = false;
    this.closeModalEvent.emit();
  }
}
