import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() dialogTitle = 'Confirm';
  @Input() dialogSubTitle = '';
  @Input() message = 'Are you sure you want ot proceed?';
  @Input() cancelButtonText = 'Cancel';
  @Input() confirmButtonText = 'Confirm';
  @Input() iconType = 'default'; //Icon can be changed dynamically
  @Input() type = '';
  @Input() textEditorTitle = '';
  @Input() showTextEditor = false;
  @Input() isMinWidth = true;

  @Output() dialogConfirmed = new EventEmitter<boolean>();
  @Output() dialogCancel = new EventEmitter<void>();
  @Output() hideConfrimDialog = new EventEmitter<boolean>();

  confirmReason = { text: '' };

  //On Cancel Dialog
  onCancel() {
    this.dialogCancel.emit();
  }
  //On Confirm Dialog
  onConfirm(): void {
    this.dialogConfirmed.emit(true); //send reason
  }
}
