import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { BuildFlowService } from 'src/app/core/services/build-flow.service';
import { CustomToastrService } from 'src/app/core/services/core/custom-toastr.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import { StoreService } from 'src/app/core/services/store.service';
import { AutoSaveTimeInterval } from 'src/app/shared/constants/constants';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import map from 'lodash/map';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';

@Component({
  selector: 'app-launch-builder',
  templateUrl: './launch-builder.component.html',
  styleUrls: ['./launch-builder.component.scss'],
})
export class LaunchBuilderComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmDialogComponent)
  confirmDialogComponent!: ConfirmDialogComponent;
  @Output() hideLaunchBuilder = new EventEmitter();

  constructor(
    private buildFlowService: BuildFlowService,
    private evaluation: EvaluationService,
    private store: StoreService,
    private pageLoader: PageLoaderService,
    private toastr: CustomToastrService,
    public path: AssetsPathChangeService
  ) {}
  buildFlowData: any = [];
  modalMessage = '';
  modalSubTitle = '';
  modalTitle = '';
  showConfirmDialog = false;
  buildFlowSubscription!: Subscription;
  stagsSubscription!: Subscription;
  buildFlowActionsData: any = null;
  autoSaveErrorCount = 0;
  stageStatus = '';
  showTextEditorOnDialog = false;
  confirmButtonTextOnDialog = 'Confirm';
  autoSavePolling: any;
  isAutoSaveApiCalled = false;
  autoSaveProgress = {
    show: false,
    status: '',
    type: '',
    message: '',
  };
  isAutoSaveRetry = false;
  totalErrorCount: any;
  totalWarningCount: any;
  totalWeightErrorMessage: any;
  enableErrorsWarnings = false;
  cloneBuildFlowData: any = null;
  buildFlowFocus: any = {};
  buildFlowAccordionSubscription!: Subscription;

  getEvaluationStages() {
    this.buildFlowService
      .getEvaluationStages(this.store.getEvaluationId())
      .subscribe({
        next: (data: any) => {
          this.pageLoader.hide();
          this.totalErrorCount = data?.data?.totalErrorCount;
          this.totalWarningCount = data?.data?.totalWarningCount;
          this.totalWeightErrorMessage =
            data?.data?.allStagesTotalWeightErrorMessage;
          this.store.setStagesData(data?.data?.stages || []);
          this.cloneBuildFlowData = cloneDeep(data?.data?.stages || []);
        },
        error: e => {
          this.pageLoader.hide();
        },
      });
  }
  validateScoring() {
    this.enableErrorsWarnings = true;
    this.getEvaluationStages();
  }

  getProposalResponse() {
    this.buildFlowService.getProposalResponse().subscribe({
      next: (data: any) => {
        this.store.setProposalResponse(data);
      },
    });
  }

  copyBuildFlow() {
    this.buildFlowService.copyBuildFlow(this.buildFlowActionsData);
  }

  deleteBuildFlow() {
    if (this.buildFlowActionsData.isLocalDelete) {
      this.buildFlowService.deleteLocalBuildFlow(this.buildFlowActionsData);
    } else {
      this.buildFlowActionsData.evaluationId = this.store.getEvaluationId();
      this.buildFlowActionsData.removalReason =
        this.confirmDialogComponent.confirmReason.text;
      this.buildFlowService
        .deleteBuildFlow(this.buildFlowActionsData)
        .subscribe({
          next: (data: any) => {
            this.buildFlowService.deleteLocalBuildFlow(
              this.buildFlowActionsData
            );
          },
        });
    }
  }

  onConfirmDialog() {
    if (this.buildFlowActionsData?.action == 'edit') {
      this.store.setBuildFlowEditActionsData({
        data: this.buildFlowActionsData?.data,
        type: this.buildFlowActionsData?.type,
        action: 'edit',
      });
    } else {
      this.deleteBuildFlow();
    }
    this.showConfirmDialog = false;
  }
  onCancelConfirmDialog() {
    this.showConfirmDialog = false;
  }
  dragAndDrop() {
    if (this.buildFlowActionsData.action === 'dragdrop') {
      this.buildFlowService.dragAndDrop(this.buildFlowActionsData);
    }
  }
  subscribeBuildFlowActions() {
    this.buildFlowSubscription = this.store.getBuildFlowActionsData.subscribe(
      (data): any => {
        this.showTextEditorOnDialog = false;
        this.confirmButtonTextOnDialog = 'Confirm';
        this.buildFlowActionsData = data;
        if (data.action === 'delete') {
          if (data?.type === 'stage') {
            this.modalSubTitle = `${data.displayOrder}. ${
              data.title ? data.title : ''
            }`;
            this.modalTitle = 'Remove Stage:';
            if (data.stageStatus === 'Active') {
              this.modalMessage =
                'Are you sure you want to remove an active evaluation stage ? This action is irreversible and will permanently remove all related scoring, comments, and any potential sections or criteria associated with it. Please provide a reason for removal to proceed.';
            } else {
              this.modalMessage =
                'Are you sure you want to delete this stage? This action will also permanently remove all related sections and criteria associated with it. Please confirm to proceed.';
            }
          } else if (data?.type === 'section') {
            this.modalSubTitle = `${data.stageDisplayOrder}.${
              data.displayOrder
            } ${data.title ? data.title : ''}`;
            this.modalTitle = 'Remove Section:';
            if (data.stageStatus === 'Active') {
              this.modalMessage =
                'Are you sure you want to remove an active evaluation section ? This action is irreversible and will permanently remove all related scoring, comments, and any potential sections or criteria associated with it. Please provide a reason for removal to proceed.';
            } else {
              this.modalMessage =
                'Are you sure you want to delete this section? This action will also permanently remove all related criteria associated with it. Please confirm to proceed.';
            }
          } else {
            this.modalSubTitle =
              data.sectionDisplayOrder === undefined
                ? `${data.stageDisplayOrder}.${data.displayOrder} ${
                    data.title ? data.title : ''
                  }`
                : `${data.stageDisplayOrder}.${data.stageDisplayOrder}.${
                    data.displayOrder
                  } ${data.title ? data.title : ''}`;
            this.modalTitle = 'Remove Criterion:';
            if (data.stageStatus === 'Active') {
              this.modalMessage =
                'Are you sure you want to remove an active evaluation criteria ? This action is irreversible and will permanently remove all related scoring, comments, and any potential sections or criteria associated with it. Please provide a reason for removal to proceed.';
            } else {
              this.modalMessage =
                'Are you sure you’d like to delete and remove this criterion?';
            }
          }
          this.showTextEditorOnDialog =
            data.stageStatus === 'Active' ? true : false;
          this.confirmButtonTextOnDialog =
            data.stageStatus === 'Active' ? 'Confirm Removal' : 'Confirm';
          this.showConfirmDialog = true;
        } else if (data.action === 'copy') {
          this.copyBuildFlow();
        } else if (data.action === 'dragdrop') {
          this.dragAndDrop();
        } else if (data.action === 'edit') {
          this.modalTitle = 'Warning the Stage is Active';
          this.modalSubTitle = '';
          this.modalMessage =
            'Editing an active stage may affect evaluators who have already started their work, potentially impacting their progress and evaluation results   Do you still wish to proceed with making changes?';
          this.confirmButtonTextOnDialog = 'Proceed';
          this.showConfirmDialog = true;
        } else if (data.action === 'refresh') {
          this.getEvaluationStages();
        }
      }
    );
  }

  setAutoSaveProgress(
    show: boolean,
    status: string,
    type: string,
    message: string
  ) {
    this.autoSaveProgress = {
      show,
      status,
      type,
      message,
    };
  }
  autoSaveBuildFlowData() {
    this.isAutoSaveApiCalled = true;
    this.setAutoSaveProgress(true, 'in-progress', '', 'Auto-save in progress');
    this.buildFlowService.saveBuildFlowData().subscribe({
      next: (data: any) => {
        this.isAutoSaveApiCalled = false;
        this.isAutoSaveRetry = false;
        this.autoSaveErrorCount = 0;
        this.setAutoSaveProgress(
          true,
          'completed',
          'text-success',
          'Changes saved successfully.'
        );
        setTimeout(() => {
          this.setAutoSaveProgress(false, 'completed', '', '');
        }, 5000);
        this.toastr.clear();
        this.getEvaluationStages();
      },
      error: e => {
        this.isAutoSaveApiCalled = false;
        this.autoSaveErrorCount += 1;
        if (this.autoSaveErrorCount > 3) {
          this.isAutoSaveRetry = false;
          this.setAutoSaveProgress(false, 'completed', '', '');
          const errorCode = e?.error?.correlationId;
          if (e.status === 401) {
            this.toastr.error(
              'Unauthorized',
              `Permission Denied: You do not have access to modify this stage.`,
              errorCode
            );
          } else if (e.status === 503) {
            this.toastr.serviceNotAvailable(errorCode);
          } else {
            this.toastr.error(
              'Network Issue Detected',
              `Auto-save failed after multiple attempts. Please refresh the page or check your internet connection.`,
              errorCode
            );
          }
        } else {
          this.isAutoSaveRetry = true;
          this.setAutoSaveProgress(
            true,
            'completed',
            'text-danger',
            `Auto Save Failed. Retrying ${this.autoSaveErrorCount}/3...`
          );
          setTimeout(() => {
            this.setAutoSaveProgress(false, 'completed', '', '');
            this.autoSaveBuildFlowData();
          }, 5000);
        }
      },
    });
  }
  subscribeStages() {
    this.stagsSubscription = this.store.getStagesSubscribe.subscribe(
      (data): any => {
        this.buildFlowData = [...data];
      }
    );
  }

  leaveLaunchBuilder() {
    this.hideLaunchBuilder.emit();
    this.autoSaveErrorCount = 4;
    this.autoSaveBuildFlowData();
  }

  // Function to recursively remove the 'enableEdit' and 'enableExpanded' property which is not requried to compare
  removeNotRequiredProperties(currentStage: any) {
    const modifiedStage: any = omit(currentStage, [
      'enableEdit',
      'enableExpanded',
    ]);
    if (modifiedStage.sections) {
      modifiedStage.sections = map(modifiedStage.sections, section =>
        this.removeNotRequiredProperties(section)
      );
    }
    if (modifiedStage.criterias) {
      modifiedStage.criterias = map(modifiedStage.criterias, criteria =>
        this.removeNotRequiredProperties(criteria)
      );
    }

    return modifiedStage;
  }

  triggerAutoSaveOnInterval() {
    this.autoSavePolling = setInterval(() => {
      if (
        !this.isAutoSaveApiCalled &&
        !this.isAutoSaveRetry &&
        !isEqual(
          map(this.cloneBuildFlowData, item =>
            this.removeNotRequiredProperties(item)
          ),
          map(this.store.getStagesData(), item =>
            this.removeNotRequiredProperties(item)
          )
        ) &&
        !this.store.getStopAutoSaveBuildFlow()
      ) {
        this.autoSaveBuildFlowData();
      }
    }, AutoSaveTimeInterval);
  }
  subscribeBuildFlowAccordion() {
    this.buildFlowAccordionSubscription =
      this.store.getBuildFlowAccodionFocus.subscribe((data: any) => {
        this.buildFlowFocus = cloneDeep(data);
      });
  }
  ngOnInit(): void {
    this.pageLoader.show();
    this.getEvaluationStages();
    this.subscribeStages();
    this.subscribeBuildFlowActions();
    this.triggerAutoSaveOnInterval();
    this.getProposalResponse();
    this.subscribeBuildFlowAccordion();
  }
  ngOnDestroy(): void {
    if (this.buildFlowSubscription) {
      this.buildFlowSubscription.unsubscribe();
    }
    if (this.stagsSubscription) {
      this.store.setStagesData([]);
      this.stagsSubscription.unsubscribe();
    }
    if (this.autoSavePolling) {
      clearInterval(this.autoSavePolling);
    }
    if (this.buildFlowAccordionSubscription) {
      this.buildFlowAccordionSubscription.unsubscribe();
    }
  }
}
