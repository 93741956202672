import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serviceUrl } from 'src/app/shared/constants/api-urls';

@Injectable({
  providedIn: 'root',
})
export class ReviewMeetingService {
  constructor(private http: HttpClient) {}
  evaluationId = null;
  getEvaluationreviewDetails(evaluationId: string): Observable<any> {
    return this.http.get(
      `${serviceUrl.getEvaluationreviewDetails}/${evaluationId}/evaluationreview`
    );
  }
  getAllStages(evaluationId: string): Observable<any> {
    return this.http.get(`${serviceUrl.getAllStages}/${evaluationId}/stages`);
  }
  getStatus(evaluationId: string): Observable<any> {
    return this.http.get(
      `${serviceUrl.getStatus}/${evaluationId}/evaluation/reviewmeeting/status`
    );
  }
  cancelMeeting(
    evaluationReviewMeetingId: any,
    evaluationId: any
  ): Observable<any> {
    return this.http.delete(
      `${serviceUrl.cancelMeeting}/${evaluationId}/evalutionreviewmeeting/${evaluationReviewMeetingId}/evalutionreview`
    );
  }
  postEvaluationreviewDetails(
    evaluationId: string,
    data: any
  ): Observable<any> {
    return this.http.post(
      `${serviceUrl.postEvaluationreviewDetails}/${evaluationId}/evaluationreview`,
      data
    );
  }
  putEvaluationreviewDetails(
    evaluationId: string,
    reviewMeetingId: string,
    data: any
  ): Observable<any> {
    return this.http.put(
      `${serviceUrl.putEvaluationreviewDetails}/${evaluationId}/evaluationreviewmeeting/${reviewMeetingId}/evaluationreview`,
      data
    );
  }
  generateScorecard(userType: string, data: any): Observable<any> {
    return this.http.post(
      `${serviceUrl.generateScorecard}?user=${userType}`,
      data
    );
  }
  getScorecard(
    evaluationId: string,
    meetingId: string,
    userType: string
  ): Observable<any> {
    return this.http.get(
      `${serviceUrl.proPortalApiBaseURL}/evaluation/${evaluationId}/meeting/${meetingId}/scorecard?user=${userType}`
    );
  }

  concludeMeeting(
    evaluationId: string,
    meetingId: string,
    data: any
  ): Observable<any> {
    return this.http.put(
      `${serviceUrl.apiBaseURL}/evaluation/${evaluationId}/evaluationreviewmeeting/${meetingId}/meetingconcluded`,
      data
    );
  }

  refreshScorecard(
    evaluationId: string,
    meetingId: string,
    userType: string,
    stageId: string,
    supplierId: string,
    submissionId: string
  ): Observable<any> {
    return this.http.get(
      `${serviceUrl.proPortalApiBaseURL}/evaluation/${evaluationId}/meeting/${meetingId}/stage/${stageId}/scorecardrefresh?user=${userType}&supplierId=${supplierId}&submissionId=${submissionId}`
    );
  }
}
