<div class="select-all-evaluator-wrapper pb-2 d-flex align-items-baseline">
  <div class="checkbox-wrapper pe-3">
    <input type="checkbox" class="theme-checkbox" />
  </div>
  <div>
    <h4>Evaluator Name</h4>
  </div>
</div>
<div class="accordion accordion-type-table evaluators-list">
  <div
    class="accordion-item evaluator-item"
    *ngFor="
      let evaluator of evaluatorsList.slice() | orderBy: 'firstName';
      index as evaluatorIndex;
      trackBy: trackByFn
    ">
    <div class="py-2 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div class="checkbox-wrapper pt-1 pe-3">
          <input
            type="checkbox"
            class="theme-checkbox"
            [(ngModel)]="evaluator.inActive" />
        </div>
        <div class="initials me-3">
          {{ evaluator.firstName | slice: 0 : 1
          }}{{ evaluator.lastName | slice: 0 : 1 }}
        </div>
        <div>
          <h4 class="mb-0">
            {{ evaluator.firstName }} {{ evaluator.lastName }}
          </h4>
        </div>
        <div
          class="issue-identified-error"
          *ngIf="
            evaluator.errorMessage !== '0 Issues Identified' &&
            evaluator.errorMessage !== null
          ">
          <app-icon
            [class]="'error pe-2'"
            [type]="'alert-circle'"
            [width]="20"
            [height]="20"></app-icon>
          <span>{{ evaluator.errorMessage }} </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="evaluator-no-stage-status pe-2">
          {{
            evaluator.status
              ? evaluator.status
              : evaluator.overAllPercentage + '% Completed'
          }}
        </div>
        <div class="evaluator toggle-accordion px-2">
          <button
            (click)="toggleAccordion(evaluator)"
            class="accordion-button border-0"
            type="button"
            [class.collapsed]="
              !expandedEvaluators[evaluator.teamMemberId]
            "></button>
        </div>
      </div>
    </div>
    <div
      class="accordion-collapse collapse"
      class="accordion-collapse collapse"
      [class.show]="expandedEvaluators[evaluator.teamMemberId]">
      <div class="mt-2 accordion-body">
        <div class="evaluator-stage-action-buttons text-end">
          <button
            type="button"
            class="btn btn-sm btn-secondary me-2"
            [disabled]="!evaluator.stages?.length">
            Remove with Cause
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary me-2"
            [disabled]="
              !evaluator.stages?.length || completedAndClosedStatus(evaluator)
            ">
            Send Reminder
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            (click)="showAssignStagePoup(evaluator.teamMemberId)"
            [disabled]="completedAndClosedStatus(evaluator)">
            Assign Stage
          </button>
        </div>
        <div *ngIf="evaluator.stages?.length; else noStages">
          <div class="evaluator-status-list py-2">
            <ul class="list-unstyled list-inline mb-1">
              <li class="list-inline-item">
                <a
                  class="normal-text"
                  (click)="openGuideModal(evaluationState.EvaluationGuide)"
                  ><img
                    [src]="
                      evaluator.evaluationGuidUnderReview
                        ? path.getAssetUrl('/assets/svg/acknowledge.svg')
                        : path.getAssetUrl('/assets/svg/pending.svg')
                    "
                    alt="Icon of Evaluation Guide" />
                  <span class="ps-2">{{
                    evaluator.evaluationGuidUnderReview
                      ? 'Evaluation Guide Acknowledged'
                      : 'Evaluation Guide Under Review'
                  }}</span></a
                >
              </li>
              <li
                class="list-inline-item"
                *ngIf="evaluator.nonDisclosureAgreement">
                <a
                  class="normal-text"
                  (click)="
                    openGuideModal(
                      evaluationState.EvaluationNonDisclosureAgreement
                    )
                  "
                  ><img
                    [src]="
                      evaluator.nonDisclosureAgreementAcknowlegde
                        ? path.getAssetUrl('/assets/svg/acknowledge.svg')
                        : path.getAssetUrl('/assets/svg/pending.svg')
                    "
                    alt="Icon of Non-Disclosure Agreement" />
                  <span class="ps-2">{{
                    evaluator.nonDisclosureAgreementAcknowlegde
                      ? 'Non-Disclosure Agreement Acknowledged'
                      : 'Non-Disclosure Agreement Under Review'
                  }}</span></a
                >
              </li>
              <li class="list-inline-item">
                <a
                  *ngIf="!evaluator.isCOI; else reviewConflict"
                  class="normal-text"
                  (click)="
                    openGuideModal(evaluationState.EvaluationConflictOfInterest)
                  "
                  ><img
                    [src]="
                      evaluator.conflictOfIntersetUnderReview
                        ? path.getAssetUrl('/assets/svg/acknowledge.svg')
                        : path.getAssetUrl('/assets/svg/pending.svg')
                    "
                    alt="Icon of Conflict of Interest" />
                  <span class="ps-2">{{
                    evaluator.conflictOfIntersetUnderReview
                      ? 'No Conflict of Interest'
                      : 'Conflict of Interest Under Review'
                  }}</span></a
                >
                <ng-template #reviewConflict>
                  <a
                    class="normal-text review-conflict-link"
                    (click)="openCOIModal(evaluator.teamMemberId)"
                    ><app-icon
                      [class]="'error pe-1'"
                      [type]="'alert-circle'"
                      [width]="22"
                      [height]="22">
                    </app-icon>
                    <span class="text-danger review-conflict-text"
                      >Review Conflict Of Interest</span
                    ></a
                  >
                </ng-template>
              </li>
              <li class="list-inline-item">
                <a class="normal-text" href="#">
                  <app-icon
                    *ngIf="evaluator.sealedPricingError"
                    [class]="'error pe-2'"
                    [type]="'alert-circle'"
                    [width]="22"
                    [height]="22"></app-icon>
                  <span class="text-danger">{{
                    evaluator.sealedPricingError
                  }}</span></a
                >
              </li>
              <li class="list-inline-item">
                <a class="normal-text" href="#">
                  <app-icon
                    *ngIf="evaluator.sealedSubmissionError"
                    [class]="'error pe-2'"
                    [type]="'alert-circle'"
                    [width]="22"
                    [height]="22"></app-icon>
                  <span class="text-danger">{{
                    evaluator.sealedSubmissionError
                  }}</span></a
                >
              </li>
            </ul>
            <app-modal
              *ngIf="showCOIModal"
              (closeModalEvent)="closeCOIModal()"
              [headerClass]="'custom-header'">
              <app-review-conflict
                *ngIf="showCOIModal"
                [evaluatorData]="evaluatorData"
                (closeModalEvent)="closeCOIModal()"></app-review-conflict>
            </app-modal>

            <app-modal
              *ngIf="showEvaluationContentLibraryModal"
              [buttonClass]="'btn-close-dynamic'"
              [headerClass]="'modal-header-dynamic'"
              [showModal]="showEvaluationContentLibraryModal"
              [modalSize]="'xl'"
              [modalData]="
                currentEvaluationState === 1
                  ? 'Evaluation Guide'
                  : currentEvaluationState === 2
                    ? 'Non-Disclosure Agreement'
                    : 'Conflict of Interest'
              "
              (closeModalEvent)="closeGuideModal()">
              <div class="text-center py-3" *ngIf="showLoader; else content">
                <app-loader [showText]="true"></app-loader>
              </div>
              <ng-template #content>
                <app-evaluation-guide
                  *ngIf="
                    currentEvaluationState === evaluationState.EvaluationGuide
                  "
                  [selectedProject]="libraryContent"
                  (continue)="setEvaluationState($event)"
                  (goBack)="setEvaluationState('close')"></app-evaluation-guide>
                <app-non-disclosure-agreement
                  *ngIf="
                    currentEvaluationState ===
                    evaluationState.EvaluationNonDisclosureAgreement
                  "
                  [selectedProject]="libraryContent"
                  (continue)="
                    setEvaluationState(
                      evaluationState.EvaluationConflictOfInterest
                    )
                  "
                  (goBack)="
                    setEvaluationState('close')
                  "></app-non-disclosure-agreement>
                <app-conflict-of-interest
                  *ngIf="
                    currentEvaluationState ===
                    evaluationState.EvaluationConflictOfInterest
                  "
                  [selectedProject]="libraryContent"
                  (goBack)="setEvaluationState('close')"
                  (confirmDialogStatus)="onConfirmDialogStatusChange($event)"
                  (noCOIDialogStatus)="
                    onNoCOIDialogStatusChange($event)
                  "></app-conflict-of-interest>
                <hr />
                <div>
                  <div class="table-responsive col-6">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="col-1 bg-light">Evaluator Name</th>
                          <th class="col-2 bg-light">
                            Acknowledgement DateTime
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let stage of libraryContent?.evaluatorAcknowledgementStatus
                              | orderBy: 'displayOrder';
                            index as stageIndex;
                            let last = last
                          "
                          class="row-1 supplier-progress">
                          <td class="col-1">
                            {{ stage.name }}
                          </td>
                          <td class="col-2">
                            <img
                              *ngIf="
                                stage.IsCOI === true ||
                                stage.is_Acknowledged === false
                              "
                              [src]="
                                stage.IsCOI === true
                                  ? path.getAssetUrl(
                                      '/assets/svg/alert-red.svg'
                                    )
                                  : stage.is_Acknowledged === false
                                    ? path.getAssetUrl(
                                        '/assets/svg/pending.svg'
                                      )
                                    : ''
                              "
                              alt="Icon of Evaluation Guide" />{{
                              stage.IsCOI === true
                                ? '  Conflict reported'
                                : stage.is_Acknowledged === false
                                  ? '  Under Review'
                                  : (stage.acknowledgementDateTime
                                    | date: 'medium')
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6"></div>
                </div>
              </ng-template>
            </app-modal>
          </div>
          <div
            class="assigned-stages row py-3 border-top-1"
            [ngClass]="last ? 'pb-0' : ''"
            *ngFor="
              let stage of evaluator.stages | orderBy: 'displayOrder';
              index as stageIndex;
              let last = last;
              trackBy: trackById
            ">
            <div
              class="col-sm-2 col-lg-3 col-xxl-3 text-truncate stage-title strong"
              title="{{ stage.title }}">
              {{ stage.displayOrder }}. {{ stage.title }}
            </div>
            <div
              class="col-sm-5 col-lg-5 col-xxl-6 stage-completion-status d-flex align-items-center">
              <ngb-progressbar class="w-100" [value]="stage.percentage" />
              <div class="w-25px ps-3 pe-5">{{ stage.percentage }}%</div>
            </div>
            <div class="col-sm-5 col-lg-4 col-xxl-3 stage-date-range">
              <div
                class="d-flex align-items-center justify-content-end"
                [class.opacity-25]="stage.stageStatus === 'Closed'">
                <div>
                  <div class="date-range">
                    <input
                      type="text"
                      class="form-control has-icon ngx-daterangepicker-action"
                      [class.text-danger]="!stage.startDateUtc"
                      id="stage-date-range-{{ stageIndex }}"
                      name="stage-date-range-{{ stageIndex }}"
                      placeholder="Apply Date Range"
                      aria-describedby="stage-date-range-desc"
                      [disabled]="stage.stageStatus === 'Closed'"
                      (click)="dateRangeClicked(stage)"
                      readonly />
                    <ng-container
                      *ngIf="stage.startDateUtc === null; else calendarError">
                      <img
                        class="error-calendar-icon"
                        [src]="
                          path.getAssetUrl('/assets/svg/calendar_error.svg')
                        " />
                    </ng-container>

                    <ng-template #calendarError>
                      <app-icon
                        class="input-icon"
                        [type]="'calendar'"
                        [width]="18"
                        [height]="18">
                      </app-icon>
                    </ng-template>
                  </div>
                </div>
                <div>
                  <a
                    href="javascript:void(0)"
                    role="button"
                    class="normal-text"
                    [class.disabled]="stage.stageStatus === 'Closed'"
                    (click)="removeStage(evaluator.teamMemberId, stage)">
                    <app-icon
                      class="input-icon"
                      [type]="'x'"
                      [strokeWidth]="3"
                      [width]="15"
                      [height]="15"></app-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noStages>
          <h4>
            <app-icon
              *ngIf="evaluator.noStageAssignedError"
              [class.text-danger]="evaluator.noStageAssignedError"
              [class]="'error pe-2'"
              [type]="'alert-circle'"
              [width]="20"
              [height]="20"></app-icon>
            <span
              *ngIf="evaluator.noStageAssignedError"
              [class.text-danger]="evaluator.noStageAssignedError">
              {{ evaluator.noStageAssignedError }}
            </span>
            <span *ngIf="!evaluator.noStageAssignedError">
              No Stage Assigned
            </span>
          </h4>
        </ng-template>
      </div>
    </div>
  </div>
</div>
