<div
  class="add-edit-stage build-flow-add-edit-form-container p-2"
  [ngClass]="{ 'is-edit': formData }">
  <div class="add-edit-stage-form build-flow-add-edit-form-wrapper py-2 px-4">
    <div class="drag-icon-disable">
      <img
        [src]="path.getAssetUrl('/assets/svg/drag-icon-disable.svg')"
        alt="Drag and drop disabled icon" />
    </div>
    <form [formGroup]="stageForm" name="add-edit-stage" novalidate>
      <div class="row mx-0 d-flex align-items-center">
        <div class="col-12 col-sm-3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="title"
              class="form-control input-container"
              id="stage-title-{{ formData.displayOrder }}"
              name="stage-title-{{ formData.displayOrder }}"
              placeholder="Stage title"
              aria-describedby="stage-title-desc" />
            <span class="seq-num"> {{ formData.displayOrder }}. </span>
            <label for="stage-title-{{ formData.displayOrder }}"
              >Stage Title</label
            >
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <div class="form-floating">
            <input
              type="text"
              class="form-control bg-transparent"
              id="stage-placeholder-{{ formData.displayOrder }}"
              name="stage-placeholder-{{ formData.displayOrder }}"
              placeholder="Stage"
              aria-describedby="stage-placeholder-desc"
              disabled />
            <label for="stage-placeholder-{{ formData.displayOrder }}"
              >Stage</label
            >
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <div class="form-floating">
            <select
              class="form-select"
              formControlName="stageTypeId"
              id="stage-type-{{ formData.displayOrder }}"
              name="stage-type-{{ formData.displayOrder }}"
              placeholder="Stage Type"
              aria-label="Stage type"
              aria-describedby="stage-type-desc"
              [ngClass]="{ 'has-value': stageTypeId.value }">
              <option
                *ngFor="let stage of stageTypesList; index as stageTypeIndex"
                [ngValue]="stage.value">
                {{ stage.title }}
              </option>
            </select>
            <label for="stage-type-{{ formData.displayOrder }}"
              >Stage Type</label
            >
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <a href="javascript:void(0)" role="button" class="disabled">
            Assign Evaluators
          </a>
          <app-icon
            class="ms-2"
            [type]="'info'"
            [width]="18"
            [height]="18"
            placement="top"
            ngbTooltip="You must add criteria within this stage before you can assign any evaluators."></app-icon>
        </div>
        <div class="col-12 col-sm-2 my-3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="startDate"
              class="form-control has-icon ngx-daterangepicker-action"
              id="stage-start-date-range-{{ formData.displayOrder }}"
              name="stage-start-date-range-{{ formData.displayOrder }}"
              placeholder="Select Start Date"
              aria-describedby="stage-start-date-range-desc"
              ngbDatepicker
              #startDate="ngbDatepicker"
              (click)="startDate.toggle()"
              readonly />
            <label
              for="stage-start-date-range-{{ formData.displayOrder }}"
              class="date-range">
              Select Start Date
            </label>
            <app-icon
              class="input-icon"
              [type]="'calendar'"
              [width]="18"
              [height]="18"></app-icon>
          </div>
        </div>
        <div class="col-12 col-sm-2 my-3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="endDate"
              class="form-control has-icon ngx-daterangepicker-action"
              id="stage-end-date-range-{{ formData.displayOrder }}"
              name="stage-end-date-range-{{ formData.displayOrder }}"
              placeholder="Select End Date"
              aria-describedby="stage-end-date-range-desc"
              ngbDatepicker
              #endDate="ngbDatepicker"
              (click)="endDate.toggle()"
              readonly />
            <label
              for="stage-end-date-range-{{ formData.displayOrder }}"
              class="date-range">
              Select End Date
            </label>
            <app-icon
              class="input-icon"
              [type]="'calendar'"
              [width]="18"
              [height]="18"></app-icon>
          </div>
        </div>
        <!--Start Stage type pricing area -->
        <div
          class="col-12 col-sm-2 offset-sm-1"
          *ngIf="stageTypeId.value === 4">
          <div class="form-floating">
            <input
              type="text"
              formControlName="totalScore"
              class="form-control"
              id="stage-total-score-{{ formData.displayOrder }}"
              name="stage-total-score-{{ formData.displayOrder }}"
              [placeholder]="
                evaluationScoringMethodId === 1 ? 'Points' : 'Weight'
              "
              [attr.aria-describedby]="
                evaluationScoringMethodId === 1
                  ? 'stage pricing points'
                  : 'stage pricing weight'
              " />
            <label for="stage-total-score-{{ formData.displayOrder }}">{{
              evaluationScoringMethodId === 1 ? 'Points' : 'Weight'
            }}</label>
          </div>
        </div>
        <!--End Stage type pricing area -->
        <div class="col-12">
          <hr class="m-0 custom" />
        </div>
      </div>
      <!--Start Stage type points caluate area -->
      <div
        class="row mx-0 mt-3"
        *ngIf="stageTypeId.value === 1 || stageTypeId.value === 2">
        <div class="col-12 col-sm-2 offset-sm-5">
          <p class="mb-2">Calculate</p>
          <div class="form-floating">
            <input
              type="text"
              formControlName="totalScore"
              class="form-control"
              id="stage-total-score-{{ formData.displayOrder }}"
              name="stage-total-score-{{ formData.displayOrder }}"
              [placeholder]="
                stageTypeId.value === 1 ? 'Total Points' : 'Total Weight'
              "
              [attr.aria-describedby]="
                stageTypeId.value === 1
                  ? 'Stage Total points'
                  : 'Stage Total weight'
              " />
            <label for="stage-total-score-{{ formData.displayOrder }}">{{
              stageTypeId.value === 1 ? 'Total Points' : 'Total Weight'
            }}</label>
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <p class="mb-2">&nbsp;</p>
          <div class="form-floating">
            <input
              type="text"
              formControlName="minThreshold"
              class="form-control"
              id="stage-min-threshold-{{ formData.displayOrder }}"
              name="stage-min-threshold-{{ formData.displayOrder }}"
              placeholder="Min Threshold"
              aria-describedby="stage-min-threshold-desc" />
            <label for="stage-min-threshold-{{ formData.displayOrder }}"
              >Min Threshold</label
            >
          </div>
        </div>
      </div>
      <!--End Stage type points caluate area -->
    </form>
  </div>
</div>
