<div
  cdkDropList
  (cdkDropListDropped)="dropSections($event)"
  class="sections-criterias-items">
  <div
    [ngClass]="
      item.hasOwnProperty('evaluationStageId')
        ? 'sections-container'
        : 'criteria-container'
    "
    *ngFor="
      let item of getSectionAndCriteriasList() | orderBy: 'displayOrder';
      index as itemIndex;
      trackBy: trackByFn
    "
    [cdkDragPreviewContainer]="'parent'"
    [cdkDragDisabled]="item.enableEdit"
    cdkDragBoundary=".sections-criterias-items"
    cdkDrag>
    <div
      *ngIf="item.hasOwnProperty('evaluationStageId')"
      ngbAccordion
      #sectionAccordion="ngbAccordion"
      class="custom-accordion section-items">
      <div
        ngbAccordionItem="section-item-{{ item.displayOrder }}"
        [collapsed]="item?.enableEdit || item?.enableExpanded ? false : true"
        class="section-item"
        [ngClass]="{
          'is-edit':
            item.displayOrder === buildFlowFocus?.section &&
            stageDisplayOrder === buildFlowFocus?.stage,
          'has-error':
            enableErrorsWarnings &&
            item.messages &&
            item.messages?.errors?.count,
          'has-warning':
            enableErrorsWarnings &&
            item.messages &&
            item.messages?.warnings.count,
        }">
        <div
          class="row d-flex px-3 py-2"
          [ngClass]="{ 'align-items-center': !item.enableEdit }">
          <div class="col-12 col-sm-9 col-md-10" *ngIf="!item.enableEdit">
            <div class="row">
              <div class="col-12 col-sm-5">
                <div class="d-flex align-items-center">
                  <div class="drag-icon section-drag-icon" cdkDragHandle>
                    <img
                      [src]="path.getAssetUrl('/assets/svg/drag-icon.svg')"
                      alt="icon of drag and drop" />
                  </div>
                  <h4 class="accordion-header ps-3">
                    {{ stageDisplayOrder }}.{{ item.displayOrder }}
                    {{ item.title }}
                  </h4>
                </div>
              </div>
              <div class="col-12 col-sm-2">Section</div>
              <div class="col-12 col-sm-5">
                <span *ngIf="item.score !== null && stageTypeId === 1">
                  {{ item.score + ' ' + stageTypes[stageTypeId] | lowercase }}
                </span>
                <span *ngIf="item.score !== null && stageTypeId === 2">
                  {{ item.score + '% ' + stageTypes[stageTypeId] | lowercase }}
                </span>
                <span *ngIf="stageTypeId === 3 || stageTypeId === 4">
                  {{ stageTypes[stageTypeId] }}
                </span>
              </div>
            </div>
            <div *ngIf="item?.messages && enableErrorsWarnings">
              <div
                class="row"
                *ngFor="let error of item?.messages?.errors.values">
                <span class="col-12 validate-msg">
                  <small>
                    <app-icon
                      [class]="'error pe-2'"
                      [type]="'alert-circle'"
                      [width]="20"
                      [height]="20"></app-icon>
                    {{ error }}
                  </small>
                </span>
              </div>
              <div
                class="row"
                *ngFor="let warning of item?.messages?.warnings.values">
                <span class="col-12 validate-msg">
                  <small>
                    <app-icon
                      [class]="'warning pe-2'"
                      [type]="'alert-triangle'"
                      [width]="22"
                      [height]="22"></app-icon>
                    {{ warning }}
                  </small>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-9 col-md-10 mb-2" *ngIf="item.enableEdit">
            <app-add-edit-section
              [formData]="item"
              [stageTypeId]="stageTypeId"
              [stageDisplayOrder]="stageDisplayOrder"></app-add-edit-section>
          </div>
          <div class="col-12 col-sm-3 col-md-2">
            <div class="row">
              <div
                class="col-12 col-sm-12 d-flex align-items-center justify-content-end">
                <div class="section-actions hover-action-items">
                  <button
                    class="btn btn-none p-0"
                    type="button"
                    (click)="copySection(item.displayOrder)">
                    <app-icon
                      [type]="'copy'"
                      [width]="18"
                      [height]="18"></app-icon>
                  </button>
                  <button
                    class="btn btn-none py-1"
                    type="button"
                    (click)="deleteSection(item)">
                    <app-icon
                      [type]="'trash'"
                      [width]="18"
                      [height]="18"></app-icon>
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    [disabled]="stageStatus === 'Closed' || item?.enableEdit"
                    (click)="showEditSection(item)">
                    Edit
                  </button>
                </div>
                <div class="section-actions toggle-accordion ps-3">
                  <button
                    class="border-0"
                    type="button"
                    ngbAccordionButton
                    (click)="hideEditSection(item)"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody *ngIf="item.enableExpanded">
            <app-criteria-view
              [criterias]="item.criterias"
              [stageTypeId]="stageTypeId"
              [stageStatus]="stageStatus"
              [stageDisplayOrder]="stageDisplayOrder"
              [sectionDisplayOrder]="item.displayOrder"
              [buildFlowFocus]="buildFlowFocus"
              [enableErrorsWarnings]="enableErrorsWarnings"></app-criteria-view>
            <app-build-flow-form-container
              [enableCriteria]="true"
              [stageDisplayOrder]="stageDisplayOrder"
              [sectionDisplayOrder]="
                item.displayOrder
              "></app-build-flow-form-container>
          </div>
        </div>
      </div>
    </div>
    <app-criteria-view
      *ngIf="item.hasOwnProperty('parentId')"
      [criterias]="[item]"
      [stageTypeId]="stageTypeId"
      [stageStatus]="stageStatus"
      [stageDisplayOrder]="stageDisplayOrder"
      [buildFlowFocus]="buildFlowFocus"
      [enableErrorsWarnings]="enableErrorsWarnings"></app-criteria-view>
  </div>
</div>
