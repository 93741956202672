import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { serviceUrl } from '../../shared/constants/api-urls';
import { StoreService } from './store.service';
import cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root',
})
export class BuildFlowService {
  constructor(
    private http: HttpClient,
    private store: StoreService
  ) {}

  getEvaluatorsListByCriteria(
    evaluationId: string,
    criteriaId: string
  ): Observable<any> {
    return this.http.get(
      `${serviceUrl.getEvaluatorsListByCriteria}/${evaluationId}/criteria/${criteriaId}/assign/evaluators`
    );
  }
  saveEvaluatorsListByCriteria(payload: any): Observable<any> {
    return this.http.post(
      `${serviceUrl.saveEvaluatorsListByCriteria}`,
      payload
    );
  }

  addStageToStore() {
    const stagePayload: any = {
      evaluationId: this.store.getEvaluationId(),
      createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      uniqueTimestamp: Date.now(),
      title: null,
      stageTypeId: null,
      dateRange: null,
      startDateUtc: null,
      endDateUtc: null,
      totalScore: null,
      minThreshold: null,
      id: null,
      displayOrder: null,
      enableEdit: true,
      enableExpanded: true,
      sections: [],
      criterias: [],
    };
    stagePayload.displayOrder = this.store.getStagesData().length + 1;
    const data = [...this.store.getStagesData(), stagePayload];
    this.store.setStagesData(data);
    this.store.setBuildFlowAccodionFocus({ stage: stagePayload.displayOrder });
  }
  addSectionToStore(stageDisplayOrder: number) {
    const sectionPayload: any = {
      uniqueTimestamp: Date.now(),
      title: null,
      score: null,
      displayOrder: null,
      enableEdit: true,
      id: null,
      enableExpanded: true,
      evaluationStageId: null,
      criterias: [],
    };
    const stages = this.store.getStagesData();
    for (const stage of stages) {
      if (stageDisplayOrder === stage.displayOrder) {
        sectionPayload.evaluationStageId = stage.id ? stage.id : null;
        sectionPayload.displayOrder =
          stage.sections.length + stage.criterias.length + 1;
        stage.sections = [...stage.sections, sectionPayload];
        break;
      }
    }
    this.store.setStagesData([...stages]);
    this.store.setBuildFlowAccodionFocus({
      stage: stageDisplayOrder,
      section: sectionPayload.displayOrder,
    });
  }
  addCriteriaToStore(stageOrder: number, sectionOrder: number) {
    const criteriaPayload: any = {
      uniqueTimestamp: Date.now(),
      title: null,
      ratingScale: null,
      minRatingScale: null,
      maxRatingScale: null,
      pricingFormulaId: null,
      score: null,
      proposalResponseMappings: null,
      id: null,
      displayOrder: null,
      enableEdit: true,
      enableExpanded: true,
      parentId: null,
    };
    const stages = this.store.getStagesData();
    if (sectionOrder === undefined) {
      for (const stage of stages) {
        if (stageOrder === stage.displayOrder) {
          criteriaPayload.parentId = stage.id ? stage.id : null;
          criteriaPayload.displayOrder =
            stage.sections.length + stage.criterias.length + 1;
          stage.criterias = [...stage.criterias, criteriaPayload];
          break;
        }
      }
    } else {
      for (const stage of stages) {
        if (stageOrder === stage.displayOrder) {
          for (const section of stage.sections) {
            if (sectionOrder === section.displayOrder) {
              criteriaPayload.parentId = section.id ? section.id : null;
              criteriaPayload.displayOrder = section.criterias.length + 1;
              section.criterias = [...section.criterias, criteriaPayload];
              break;
            }
          }
          break;
        }
      }
    }
    this.store.setStagesData([...stages]);
    this.store.setBuildFlowAccodionFocus({
      stage: stageOrder,
      section: sectionOrder,
      criteria: criteriaPayload.displayOrder,
    });
  }
  copyBuildFlow(data: any) {
    const stages = this.store.getStagesData();
    if (data.type === 'stage') {
      const index = stages.findIndex(
        (stage: any) => stage.displayOrder === data.displayOrder
      );
      const stage = cloneDeep({ ...stages[index] });
      stage.id = null;
      stage.title = `Copy of ${stage.title ? stage.title : ''}`;
      stage.uniqueTimestamp = Date.now();
      stage.sections.forEach((section: any) => {
        section.id = null;
        section.title = `Copy of ${section.title ? section.title : ''}`;
        section.uniqueTimestamp = Date.now();
        section.criterias.forEach((criteria: any) => {
          criteria.id = null;
          criteria.title = `Copy of ${criteria.title ? criteria.title : ''}`;
          criteria.uniqueTimestamp = Date.now();
          criteria.proposalResponseMappings?.forEach((res: any) => {
            res.id = null;
          });
        });
      });
      stage.criterias.forEach((criteria: any) => {
        criteria.id = null;
        criteria.title = `Copy of ${criteria.title ? criteria.title : ''}`;
        criteria.uniqueTimestamp = Date.now();
        criteria.proposalResponseMappings?.forEach((res: any) => {
          res.id = null;
        });
      });
      stages.splice(index + 1, 0, stage);
      stages.forEach((item: any, index: number) => {
        item.displayOrder = index + 1;
      });
      this.store.setStagesData([...stages]);
    }
    if (data.type === 'section') {
      for (const stage of stages) {
        if (data.stageDisplayOrder === stage.displayOrder) {
          const mergeSectionsCriterias = [
            ...stage.sections,
            ...stage.criterias,
          ].sort((a, b) => a.displayOrder - b.displayOrder);
          const index = mergeSectionsCriterias.findIndex(
            (section: any) => section.displayOrder === data.displayOrder
          );
          const section = cloneDeep({ ...mergeSectionsCriterias[index] });
          section.id = null;
          section.title = `Copy of ${section.title ? section.title : ''}`;
          section.uniqueTimestamp = Date.now();
          section.criterias.forEach((criteria: any) => {
            criteria.id = null;
            criteria.title = `Copy of ${criteria.title ? criteria.title : ''}`;
            criteria.uniqueTimestamp = Date.now();
            criteria.proposalResponseMappings?.forEach((res: any) => {
              res.id = null;
            });
          });
          mergeSectionsCriterias.splice(index + 1, 0, section);
          mergeSectionsCriterias.forEach((item: any, index: number) => {
            item.displayOrder = index + 1;
          });
          stage.sections = mergeSectionsCriterias.filter((section: any) =>
            Object.prototype.hasOwnProperty.call(section, 'evaluationStageId')
          );
          stage.criterias = mergeSectionsCriterias.filter((criteria: any) =>
            Object.prototype.hasOwnProperty.call(criteria, 'parentId')
          );
          break;
        }
      }
      this.store.setStagesData([...stages]);
    }
    if (data.type === 'criteria') {
      if (data.sectionDisplayOrder === undefined) {
        for (const stage of stages) {
          if (data.stageDisplayOrder === stage.displayOrder) {
            const mergeSectionsCriterias = [
              ...stage.sections,
              ...stage.criterias,
            ].sort((a, b) => a.displayOrder - b.displayOrder);
            const index = mergeSectionsCriterias.findIndex(
              (criteria: any) => criteria.displayOrder === data.displayOrder
            );
            const criteria = cloneDeep({ ...mergeSectionsCriterias[index] });
            criteria.id = null;
            criteria.title = `Copy of ${criteria.title ? criteria.title : ''}`;
            criteria.uniqueTimestamp = Date.now();
            criteria.proposalResponseMappings?.forEach((res: any) => {
              res.id = null;
            });
            mergeSectionsCriterias.splice(index + 1, 0, criteria);
            mergeSectionsCriterias.forEach((item: any, index: number) => {
              item.displayOrder = index + 1;
            });
            stage.sections = mergeSectionsCriterias.filter((section: any) =>
              Object.prototype.hasOwnProperty.call(section, 'evaluationStageId')
            );
            stage.criterias = mergeSectionsCriterias.filter((criteria: any) =>
              Object.prototype.hasOwnProperty.call(criteria, 'parentId')
            );
            break;
          }
        }
      } else {
        for (const stage of stages) {
          if (data.stageDisplayOrder === stage.displayOrder) {
            for (const section of stage.sections) {
              if (data.sectionDisplayOrder === section.displayOrder) {
                const index = section.criterias.findIndex(
                  (criteria: any) => criteria.displayOrder === data.displayOrder
                );
                const criteria = cloneDeep({ ...section.criterias[index] });
                criteria.id = null;
                criteria.title = `Copy of ${
                  criteria.title ? criteria.title : ''
                }`;
                criteria.uniqueTimestamp = Date.now();
                criteria.proposalResponseMappings?.forEach((res: any) => {
                  res.id = null;
                });
                section.criterias.splice(index + 1, 0, criteria);
                section.criterias.forEach((item: any, index: number) => {
                  item.displayOrder = index + 1;
                });
                break;
              }
            }
            break;
          }
        }
      }
      this.store.setStagesData([...stages]);
    }
  }
  deleteLocalBuildFlow(data: any) {
    const stages = this.store.getStagesData();
    if (data.type === 'stage') {
      const index = stages.findIndex(
        (stage: any) => stage.displayOrder === data.displayOrder
      );
      stages.splice(index, 1);
      stages.forEach((item: any, index: number) => {
        item.displayOrder = index + 1;
        item.uniqueTimestamp = Date.now();
      });
      this.store.setStagesData([...stages]);
      this.store.setBuildFlowAccodionFocus({ stage: null });
    }
    if (data.type === 'section') {
      const stageIndex = stages.findIndex(
        (stage: any) => stage.displayOrder === data.stageDisplayOrder
      );
      const sectionIndex = stages[stageIndex].sections.findIndex(
        (section: any) => section.displayOrder === data.displayOrder
      );
      stages[stageIndex].sections.splice(sectionIndex, 1);
      stages[stageIndex].sections.forEach((item: any, index: number) => {
        item.displayOrder = index + 1;
      });
      stages[stageIndex].criterias.forEach((item: any, index: number) => {
        item.displayOrder = stages[stageIndex].sections.length + index + 1;
      });
      this.store.setStagesData([...stages]);
      this.store.setBuildFlowAccodionFocus({
        stage: data.stageDisplayOrder,
        section: null,
      });
    }
    if (data.type === 'criteria') {
      if (data.sectionDisplayOrder === undefined) {
        const stageIndex = stages.findIndex(
          (stage: any) => stage.displayOrder === data.stageDisplayOrder
        );
        const criteriaIndex = stages[stageIndex].criterias.findIndex(
          (criteria: any) => criteria.displayOrder === data.displayOrder
        );
        stages[stageIndex].criterias.splice(criteriaIndex, 1);
        stages[stageIndex].sections.forEach((item: any, index: number) => {
          item.displayOrder = index + 1;
        });
        stages[stageIndex].criterias.forEach((item: any, index: number) => {
          item.displayOrder = stages[stageIndex].sections.length + index + 1;
        });
        this.store.setStagesData([...stages]);
        this.store.setBuildFlowAccodionFocus({
          stage: data.stageDisplayOrder,
          section: null,
          criteria: null,
        });
      } else {
        const stageIndex = stages.findIndex(
          (stage: any) => stage.displayOrder === data.stageDisplayOrder
        );
        const sectionIndex = stages[stageIndex].sections.findIndex(
          (section: any) => section.displayOrder === data.sectionDisplayOrder
        );
        const criteriaIndex = stages[stageIndex].sections[
          sectionIndex
        ].criterias.findIndex(
          (criteria: any) => criteria.displayOrder === data.displayOrder
        );
        stages[stageIndex].sections[sectionIndex].criterias.splice(
          criteriaIndex,
          1
        );
        stages[stageIndex].sections[sectionIndex].criterias.forEach(
          (item: any, index: number) => {
            item.displayOrder = index + 1;
            item.uniqueTimestamp = Date.now();
          }
        );
        this.store.setStagesData([...stages]);
        this.store.setBuildFlowAccodionFocus({
          stage: data.stageDisplayOrder,
          section: data.sectionDisplayOrder,
          criteria: null,
        });
      }
    }
  }
  deleteBuildFlow(data: any): Observable<any> {
    const url =
      data?.type === 'stage'
        ? serviceUrl.deleteStage
        : data?.type === 'section'
          ? serviceUrl.deleteSection
          : serviceUrl.deleteCriteria;
    return this.http.delete(url, { body: data });
  }
  dragAndDrop(data: any) {
    if (data.type === 'stage') {
      data.data.forEach((stage: any, index: number) => {
        stage.displayOrder = index + 1;
      });
      this.store.setStagesData([...data.data]);
    }
    if (data.type === 'section') {
      const stages = this.store.getStagesData();
      const index = stages.findIndex(
        (stage: any) => stage.displayOrder === data.stageDisplayOrder
      );
      data.data.forEach((item: any, index: number) => {
        item.displayOrder = index + 1;
      });
      stages[index].sections = data.data.filter((section: any) =>
        Object.prototype.hasOwnProperty.call(section, 'evaluationStageId')
      );
      stages[index].criterias = data.data.filter((criteria: any) =>
        Object.prototype.hasOwnProperty.call(criteria, 'parentId')
      );
      this.store.setStagesData([...stages]);
    }
    if (data.type === 'criteria') {
      const stages = this.store.getStagesData();
      const index = stages.findIndex(
        (stage: any) => stage.displayOrder === data.stageDisplayOrder
      );
      const sectionIndex = stages[index].sections.findIndex(
        (section: any) => section.displayOrder === data.sectionDisplayOrder
      );
      data.data.forEach((item: any, index: number) => {
        item.displayOrder = index + 1;
      });
      stages[index].sections[sectionIndex].criterias = [...data.data];
      this.store.setStagesData([...stages]);
    }
  }
  saveBuildFlowData(): Observable<any> {
    const payload = { autoSaveRequest: { stages: this.store.getStagesData() } };
    return this.http.put(serviceUrl.saveBuildFlow, payload);
  }
  getEvaluationStages(id: string): Observable<any> {
    return this.http.get(`${serviceUrl.getStages}/${id}/validate/stages`).pipe(
      map((response: any) => {
        response?.data?.stages?.forEach((stage: any) => {
          const existingStage = this.store
            .getStagesData()
            .find(
              (currentStage: any) =>
                currentStage.displayOrder === stage.displayOrder
            );
          stage.enableExpanded = existingStage?.enableExpanded || false;
          stage.enableEdit = existingStage?.enableEdit || false;
          stage.sections.forEach((section: any) => {
            const existingSection = existingStage?.sections.find(
              (currentSection: any) =>
                currentSection.displayOrder === section.displayOrder
            );
            section.enableExpanded = existingSection?.enableExpanded || false;
            section.enableEdit = existingSection?.enableEdit || false;
            section.criterias.forEach((criteria: any) => {
              const existingCriteria = existingSection?.criterias.find(
                (existingCriteria: any) =>
                  existingCriteria.displayOrder === criteria.displayOrder
              );
              criteria.enableExpanded =
                existingCriteria?.enableExpanded || false;
              criteria.enableEdit = existingCriteria?.enableEdit || false;
            });
          });
          stage.criterias.forEach((criteria: any) => {
            const existingCriteria = existingStage?.criterias.find(
              (existingCriteria: any) =>
                existingCriteria.displayOrder === criteria.displayOrder
            );
            criteria.enableExpanded = existingCriteria?.enableExpanded || false;
            criteria.enableEdit = existingCriteria?.enableEdit || false;
          });
        });
        return {
          ...response,
        };
      })
    );
  }

  getProposalResponse(): Observable<any> {
    return this.http.get(`${serviceUrl.proposalResponse}`);
  }
}
