import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EvaluationTeamService } from 'src/app/core/services/evaluation-team/evaluation-team.service';
import { serviceUrl } from 'src/app/shared/constants/api-urls';
import { IEvaluatorList } from 'src/app/shared/constants/lists';
@Component({
  selector: 'app-manage-evaluators',
  templateUrl: './manage-evaluators.component.html',
  styleUrls: ['./manage-evaluators.component.scss'],
})
export class ManageEvaluatorsComponent implements OnInit, OnDestroy {
  isEvalTeamError = false;
  @Input() evaluatorsList: IEvaluatorList[] = [];
  @Input() accessType = '';
  @Output() hideManageEvaluatorsModal = new EventEmitter<boolean>();
  @Output() refreshEvalData = new EventEmitter<void>();
  confirmDialogMessage: any;
  confirmDialogTitle: any;
  masterSelected = false;
  checkedList: IEvaluatorList[] = [];
  searchText = '';
  modalMessage = '';
  modalSubTitle = '';
  isInActive = false;
  deletedEvaluators: IEvaluatorList[] = [];
  addedEvaluators: IEvaluatorList[] = [];
  evaluatorsTeamList: IEvaluatorList[] = [];
  showLoader = true;
  showRemoveEvaluatorDialog = false;
  selectAll = false;
  evaluatorRequests: any = {
    addEvaluators: [],
    removeEvaluators: [],
  };
  teamMemberCloseInterval: any;
  isTyping = false;
  saveLoader = false;
  hideModal = false;

  ngOnInit() {
    this.getTeamMembersList();
  }

  constructor(private evaluationTeamService: EvaluationTeamService) {
    this.masterSelected = false;
  }

  getTeamMembersList() {
    this.showLoader = true;
    this.evaluationTeamService.getTeamMembersList().subscribe({
      next: (data: any) => {
        this.evaluatorsTeamList = data?.data?.users?.values || [];
        this.isEvalTeamError = false;
        this.showLoader = false;
      },
      error: e => {
        this.isEvalTeamError = true;
        this.showLoader = false;
      },
    });
  }

  get filteredEmployee() {
    const search = this.searchText.toLowerCase();
    return this.evaluatorsTeamList.filter(emp =>
      `${emp.firstName} ${emp.lastName}`.toLowerCase().includes(search)
    );
  }

  displayDeletionDialog(showdeletedEvaluators: IEvaluatorList[]) {
    const maxDisplayCount = 4;
    const displayedEvaluators = showdeletedEvaluators.slice(0, maxDisplayCount);
    const remainingCount = showdeletedEvaluators.length - maxDisplayCount;
    const evaluatorList = displayedEvaluators.map(
      evaluator => `\u25A0 ${evaluator.firstName} ${evaluator.lastName}` // \u25A0 is the square bullet
    );
    // .join('\n'); // Join names with line breaks \u25A0
    if (remainingCount > 0) {
      this.modalMessage = `<div>Are you sure you want to remove the following evaluators from the evaluation team?</div> 
      <div><ul class="evalListAlter">
        ${evaluatorList.map(item => `<li>${item}</li>`).join('')}
        </ul></div><div>...and ${remainingCount} more evaluators.</div> <div>This action will revoke all ongoing assignments for these evaluators, and any progress related to their assigned stages or criteria will be lost. Click "Confirm" to proceed.</div>`;
    } else {
      this.modalMessage = `<div>Are you sure you want to remove the following evaluators from the evaluation team?</div>
      <div><ul class="evalListAlter">${evaluatorList
        .map(item => `<li>${item}</li>`)
        .join('')}
        </ul></div><div>This action will revoke all ongoing assignments for these evaluators, and any progress related to their assigned stages or criteria will be lost. Click "Confirm" to proceed.</div>`;
    }
    this.showRemoveEvaluatorDialog = true;
  }
  onConfirmRemoveEvaluators() {
    this.saveEvaluatorsList(this.evaluatorRequests);
  }
  onCancelRemoveEvaluators() {
    this.showRemoveEvaluatorDialog = false;
    this.openModal();
  }

  openModal() {
    this.hideModal = false;
  }
  closeModal() {
    this.hideManageEvaluatorsModal.emit();
  }

  toggleSelected() {
    this.selectAll = this.evaluatorsTeamList.every(
      evalItem => evalItem.isSelected
    );
  }

  toggleSelectAll() {
    this.evaluatorsTeamList.forEach(evalItem => {
      evalItem.isSelected = this.selectAll;
    });
  }

  saveSelectedEvaluators() {
    const selectedEvaluators = this.evaluatorsTeamList.filter(
      evalItem => evalItem.isSelected
    );
    this.deletedEvaluators = this.evaluatorsList.filter(
      itemA =>
        !selectedEvaluators.some(itemB => itemB.id === itemA.teamMemberId)
    );
    this.addedEvaluators = selectedEvaluators.filter(
      itemC =>
        !this.evaluatorsList.some(itemD => itemD.teamMemberId === itemC.id)
    );
    const evaluatorRequests = {
      addEvaluators: this.addedEvaluators,
      removeEvaluators: this.deletedEvaluators,
    };
    if (this.addedEvaluators.length > 0) {
      evaluatorRequests.addEvaluators.forEach(addReq => {
        addReq.teamMemberId = addReq.id;
        addReq.id = null;
      });
    }

    if (this.deletedEvaluators.length > 0) {
      evaluatorRequests.removeEvaluators.forEach(remReq => {
        // remReq.teamMemberId = remReq.id;
        remReq.id = null;
      });
      this.evaluatorRequests = evaluatorRequests;
      this.displayDeletionDialog(this.deletedEvaluators);
      this.hideModal = true;
    } else {
      this.saveEvaluatorsList(evaluatorRequests);
    }
  }

  saveEvaluatorsList(evaluatorRequests: any) {
    this.saveLoader = true;
    this.evaluationTeamService.saveEvaluatorsList(evaluatorRequests).subscribe({
      next: (data: any) => {
        this.saveLoader = false;
        this.refreshEvalData.emit();
        setTimeout(() => {
          this.showRemoveEvaluatorDialog = false;
          this.closeModal();
        }, 2);
      },
      error: () => {
        this.saveLoader = false;
      },
    });
  }
  clearInterval() {
    if (this.teamMemberCloseInterval) {
      clearInterval(this.teamMemberCloseInterval);
    }
  }
  addMemberProPortal(event: Event): void {
    event.preventDefault();
    const url = `${serviceUrl.proPortalBaseURl}`;
    this.clearInterval();
    const width = screen.width - 300;
    const height = screen.height;
    const windowFeatures = `width=${width}, height=${height}, top=100,left=150, resizable=yes`;
    const teamMemberWindow = window.open(url, '_blank', windowFeatures);
    this.teamMemberCloseInterval = setInterval(() => {
      if (teamMemberWindow?.closed) {
        clearInterval(this.teamMemberCloseInterval);
        this.getTeamMembersList();
      }
    }, 1000);
  }

  onSearchInput() {
    this.isTyping = this.searchText.length > 0;
  }
  onClearSearch() {
    this.searchText = '';
    this.isTyping = false;
  }
  ngOnDestroy(): void {
    this.clearInterval();
  }
}
