import {
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { EvaluationService } from './core/services/evaluation.service';
import { StoreService } from './core/services/store.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  private router = inject(Router);
  constructor(
    private evaluation: EvaluationService,
    private store: StoreService,
    private toastrService: ToastrService,
    private el: ElementRef
  ) {}
  title = 'RFx';
  sidebarExpanded = 'is-expanded';
  sidebarSubscription!: Subscription;

  @Input() proData: any;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer!: ToastContainerDirective;

  // Getting evaluation already exist or not
  getEvaluation() {
    this.evaluation.getEvaluation().subscribe({
      next: (data: any) => {
        if (data?.evaluationExists && data?.evaluationId) {
          this.store.updateEvaluationId(data.evaluationId);
        }
      },
    });
  }
  setProdData(data: any) {
    if (data) {
      this.store.setProData(data);
      setTimeout(() => {
        this.getEvaluation();
        this.router.initialNavigation();
        const path = window.location.pathname;
        this.router.navigate([path || '/build-evaluation']); // on default load programatically navigation
      });
    }
  }
  applyStyles() {
    const fontLink = document.createElement('link');
    fontLink.type = 'text/css';
    fontLink.rel = 'stylesheet';
    fontLink.href = `${environment?.baseHref}/assets/styles/fonts/webcomponent-fonts.css`;
    document.head.appendChild(fontLink);

    const styleLink = document.createElement('link');
    styleLink.rel = 'stylesheet';
    fontLink.type = 'text/css';
    styleLink.href = `${environment?.baseHref}/styles.css`;
    this.el.nativeElement.shadowRoot.appendChild(styleLink);
  }
  subscribeSidebarToggleAction() {
    this.sidebarSubscription = this.store.getSidebarExpanded.subscribe(
      (status: boolean) => {
        this.sidebarExpanded = status ? 'is-expanded' : '';
      }
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setProdData(changes['proData']?.currentValue);
  }
  ngOnInit(): void {
    this.subscribeSidebarToggleAction();
    this.setProdData(this.proData); // setting pro data passed into store
    this.toastrService.overlayContainer = this.toastContainer;
    this.applyStyles();
  }
  ngOnDestroy(): void {
    if (this.sidebarSubscription) {
      this.sidebarSubscription.unsubscribe();
    }
  }
}
