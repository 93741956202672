<div>
  <div class="main-container">
    <div
      class="editor-container editor-container_classic-editor"
      #editorContainerElement>
      <div class="editor-container__editor">
        <div #editorElement>
          <ckeditor
            [editor]="Editor"
            [config]="config"
            [(ngModel)]="confirmReason.text"
            (change)="onChange($event)"
            *ngIf="isLayoutReady" />
        </div>
      </div>
    </div>
  </div>
</div>
