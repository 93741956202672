<div class="coi-container">
  <div class="coi-icon">
    <app-icon
      [class]="'error pe-2'"
      [type]="'alert-circle'"
      [width]="50"
      [height]="50"></app-icon>
  </div>

  <h2 class="coi-title">Conflict of Interest(COI)</h2>
  <p>
    A conflict of interest has been reported for this evaluator.To Proceed,
    click 'Confirm' to exclude the evaluator, or click 'Dismiss' to allow them
    to continue their evaluation.
  </p>

  <div class="evaluator-section">
    <div class="left-panel">
      <div class="evaluator-initials">
        {{ evaluatorData.firstName | slice: 0 : 1
        }}{{ evaluatorData.lastName | slice: 0 : 1 }}
      </div>
    </div>
    <div class="right-panel">
      <div class="evaluator-name">
        <strong>
          {{ evaluatorData.firstName }}
          {{ evaluatorData.lastName }}</strong
        >
      </div>
      <div class="date">{{ evaluatorData.acknowledgedDateTimeUtc }}</div>
      <div
        class="comment"
        [innerHTML]="evaluatorData.commentByEvaluatorOnCOI"></div>
    </div>
  </div>

  <div class="mt-3 conflictOfInterest-continue text-center">
    <button type="button" class="btn btn-danger me-3" (click)="closeCOIModal()">
      Confirm COI
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="openCOIDismissModal()">
      Dismiss COI
    </button>
  </div>
</div>
<app-modal
  *ngIf="showEvaluatorCOIDismissModal"
  (closeModalEvent)="closeCOIDismissModal()"
  [headerClass]="'custom-header'">
  <app-dismiss-coi
    *ngIf="showEvaluatorCOIDismissModal"
    [evaluatorData]="evaluatorData"
    (closeModalEvent)="closeCOIDismissModal()">
  </app-dismiss-coi>
</app-modal>
