export enum StageTypeEnum {
  POINTS = 1,
  WEIGHT = 2,
  PASSFAIL = 3,
  PRICING = 4,
}
export const StageTypes: any = {
  [StageTypeEnum.POINTS]: 'Points',
  [StageTypeEnum.WEIGHT]: 'Weight',
  [StageTypeEnum.PASSFAIL]: 'Pass/Fail',
  [StageTypeEnum.PRICING]: 'Pricing',
};
export const StageTypesList: any = [
  { title: 'Points', value: StageTypeEnum.POINTS },
  { title: 'Weight', value: StageTypeEnum.WEIGHT },
  { title: 'Pass/Fail', value: StageTypeEnum.PASSFAIL },
  { title: 'Pricing', value: StageTypeEnum.PRICING },
];
export const PricingFormula: any = {
  1: 'Lowest',
  2: 'Highest',
};
export const PricingFormulaList: any = [
  { title: 'Low Bid', value: 1 },
  { title: 'Highest Bid', value: 2 },
];
export const AutoSaveTimeInterval = 1000 * 15; // 15 = 15 sections
