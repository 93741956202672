import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { BuildFlowService } from 'src/app/core/services/build-flow.service';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { StoreService } from 'src/app/core/services/store.service';
import { PricingFormulaList } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-add-edit-criteria',
  templateUrl: './add-edit-criteria.component.html',
  styleUrls: ['./add-edit-criteria.component.scss'],
})
export class AddEditCriteriaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stageTypeId = 0;
  @Input() formData: any;
  @Input() stageDisplayOrder!: number;
  @Input() sectionDisplayOrder!: number;
  @Output() hideAddCriteria = new EventEmitter();
  @ViewChild('selectDropdown', { static: false })
  selectDropdown!: NgSelectComponent;
  evaluationScoringMethodId: number = this.store.getEvaluationScoringMethodId();
  pricingFormulaList: any = PricingFormulaList;
  proposalResponseList: any = this.store.getProposalResponse();
  maskedValue = '';
  savedSelections: any[] = [];
  selectedItems: any[] = [];
  showAssignEvaluatorsModal = false;

  openAssignModal() {
    this.showAssignEvaluatorsModal = !this.showAssignEvaluatorsModal;
  }

  formatInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    const digits = input.replace(/\D/g, '');
    let formattedValue = '';
    if (digits.length === 1) {
      formattedValue = +digits[0] <= 5 ? digits : '';
    } else if (digits.length === 2) {
      const firstDigit = +digits[0];
      const secondDigit = +digits[1];
      if (firstDigit <= 5 && secondDigit <= 5) {
        formattedValue = `${firstDigit}-${secondDigit}`;
      }
    }
    this.maskedValue = formattedValue;
    (event.target as HTMLInputElement).value = this.maskedValue;
  }

  criteriaForm: any = this.fb.group({
    title: [null],
    ratingScale: [null],
    pricingFormulaId: [null],
    score: [null],
    proposalResponseMappings: [null],
  });

  constructor(
    public fb: FormBuilder,
    private store: StoreService,
    private buildFlow: BuildFlowService,
    private cdr: ChangeDetectorRef,
    private focusMonitor: FocusMonitor,
    private el: ElementRef<HTMLElement>,
    public path: AssetsPathChangeService
  ) {}

  saveSelection() {
    this.savedSelections = [...this.selectedItems];
    this.selectDropdown?.close();
    this.cdr.detectChanges();
  }
  cancelSelection() {
    this.selectedItems = [...this.savedSelections];
    this.selectDropdown?.close();
    this.cdr.detectChanges();
  }

  assignFormData() {
    this.criteriaForm.patchValue({
      title: this.formData.title,
      score: this.formData.score,
      pricingFormulaId: this.formData.pricingFormulaId,
      ratingScale:
        this.formData.minRatingScale && this.formData.maxRatingScale
          ? `${this.formData.minRatingScale}-${this.formData.maxRatingScale}`
          : null,
      proposalResponseMappings: this.formData.proposalResponseMappings,
    });
  }

  formValuesChanges() {
    this.criteriaForm.valueChanges
      .pipe(
        tap(() => this.store.setStopAutoSaveBuildFlow(true)),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        const ratingScaleValue = this.criteriaForm.value.ratingScale;
        this.formData.title = this.criteriaForm.value.title;
        this.formData.score = this.criteriaForm.value.score;
        this.formData.pricingFormulaId =
          this.criteriaForm.value.pricingFormulaId;
        this.formData.minRatingScale = ratingScaleValue
          ? ratingScaleValue.split('-')[0]
          : null;
        this.formData.maxRatingScale = ratingScaleValue
          ? ratingScaleValue.split('-')[1]
          : null;
        this.formData.proposalResponseMappings =
          this.criteriaForm.value.proposalResponseMappings;
        this.store.setStopAutoSaveBuildFlow(false);
      });
  }

  monitorFocus() {
    this.focusMonitor
      .monitor(this.el?.nativeElement, true)
      .subscribe(origin => {
        if (origin) {
          this.store.setBuildFlowAccodionFocus({
            stage: this.stageDisplayOrder,
            section: this.sectionDisplayOrder,
            criteria: this.formData.displayOrder,
          });
        }
      });
  }
  cancelModal() {
    this.showAssignEvaluatorsModal = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stageTypeId']) {
      this.criteriaForm.patchValue({
        score: null,
        ratingScale: null,
        pricingFormulaId: null,
      });
    }
  }
  ngOnInit(): void {
    if (this.formData) {
      this.assignFormData();
    }
    this.formValuesChanges();
    this.monitorFocus();
  }
  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.el?.nativeElement);
  }
}
