import { Component, EventEmitter, Output, Input } from '@angular/core';
import { EvaluationTeamService } from 'src/app/core/services/evaluation-team/evaluation-team.service';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import { StoreService } from 'src/app/core/services/store.service';
import { IEvaluatorList } from 'src/app/shared/constants/lists';

@Component({
  selector: 'app-dismiss-coi',
  templateUrl: './dismiss-coi.component.html',
  styleUrls: ['./dismiss-coi.component.scss'],
})
export class DismissCoiComponent {
  @Output() closeModalEvent = new EventEmitter<void>();

  @Input() evaluatorData: any = null;
  showEvaluatorCOIDismissModal = false;
  isCOIResolvedComment = { text: '' };
  evaluationId = this.store.getEvaluationId();
  evaluatorsListError = false;
  evaluatorsList: IEvaluatorList[] = [];

  constructor(
    private evaluationTeamService: EvaluationTeamService,
    private pageLoader: PageLoaderService,
    private store: StoreService
  ) {}

  closeCOIDismissModal(): void {
    this.showEvaluatorCOIDismissModal = false;
    this.closeModalEvent.emit();
  }
  getEvaluatorsList() {
    this.pageLoader.show();
    this.evaluationTeamService.getEvaluatorsList(this.evaluationId).subscribe({
      next: (data: any) => {
        this.evaluatorsListError = false;
        this.pageLoader.hide();
        data?.data?.evaluator.forEach((evaluator: any) => {
          evaluator.stages?.forEach((stage: any) => {
            stage.dateRange = stage.startDateUtc
              ? {
                  startDate: new Date(stage.startDateUtc),
                  endDate: new Date(stage.endDateUtc),
                }
              : null;
          });
        });
        this.evaluatorsList = data?.data?.evaluator
          ? [...data.data.evaluator]
          : [];
      },
      error: e => {
        this.pageLoader.hide();
        this.evaluatorsListError = true;
      },
    });
  }
  submitDismissal(): void {
    const { evaluationId, teamMemberId } = this.evaluatorData;

    const payload = {
      teamMemberId: this.evaluatorData.teamMemberId,
      evaluationId: this.evaluatorData.evaluationId,
      groupType: 3,
      isCOIResolvedComment: this.isCOIResolvedComment.text,
      isCOIValid: false,
    };
    this.evaluationTeamService
      .submitDismissal(payload, evaluationId, teamMemberId)
      .subscribe({
        next: response => {
          this.closeCOIDismissModal();
          this.getEvaluatorsList();
        },
        error: error => {
          console.error('Error', error);
        },
      });
  }
}
