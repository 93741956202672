<div
  class="modal d-block bg-dark bg-opacity-50"
  id="launch-builder-modal"
  aria-labelledby="launch builder modal"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header justify-content-between">
        <div class="col d-flex align-items-center">
          <div class="mb-1">
            <img
              [src]="path.getAssetUrl('/assets/svg/logo.svg')"
              alt="Logo of Bids and Tenders" />
          </div>
          <h4 class="modal-title">evaluation setup</h4>
        </div>
        <div
          class="col d-flex align-items-center justify-content-center text-center">
          <h3 class="project-title mb-0">
            RFP2018-19 Website Redesign and Development
          </h3>
        </div>
        <div class="col d-flex align-items-end justify-content-end text-end">
          <button
            type="button"
            class="btn btn-primary btn-sm leave-button pb-3"
            (click)="leaveLaunchBuilder()"
            data-bs-dismiss="modal">
            <app-icon
              class="mt-2"
              [type]="'x'"
              [width]="20"
              [height]="20"
              [strokeWidth]="3"></app-icon
            >Leave
          </button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div
          class="action-items"
          [ngClass]="
            enableErrorsWarnings
              ? 'justify-content-between'
              : 'justify-content-end text-end'
          ">
          <div class="px-4" *ngIf="enableErrorsWarnings">
            <app-icon
              [class]="'error pe-2'"
              [type]="'alert-circle'"
              [width]="24"
              [height]="24"></app-icon
            >{{ totalErrorCount ? totalErrorCount : 0 }} errors and
            {{ totalWarningCount ? totalWarningCount : 0 }} warnings found.
            {{ totalWeightErrorMessage }}
          </div>
          <div class="d-flex align-items-center">
            <div
              class="px-4 font-14"
              [ngClass]="autoSaveProgress.type"
              *ngIf="autoSaveProgress.show">
              <img
                class="spin"
                [src]="path.getAssetUrl('/assets/svg/loading.svg')"
                alt="spin icon of saving"
                [hidden]="autoSaveProgress.status !== 'in-progress'" />
              {{ autoSaveProgress.message }}
            </div>
            <div class="text-nowrap">
              <button
                type="button"
                class="btn btn-secondary me-2"
                (click)="validateScoring()">
                Validate Scoring
              </button>
              <button type="button" class="btn btn-primary btn-theme min-width">
                Preview<app-icon
                  class="ms-2"
                  [type]="'play'"
                  [width]="16"
                  [height]="16"></app-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="launch-builder-content p-4">
          <app-stage-view
            [stages]="buildFlowData"
            [buildFlowFocus]="buildFlowFocus"
            [enableErrorsWarnings]="enableErrorsWarnings"></app-stage-view>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-dialog
  *ngIf="showConfirmDialog"
  [message]="modalMessage"
  [dialogSubTitle]="modalSubTitle"
  [dialogTitle]="modalTitle"
  textEditorTitle="Reason for Removal"
  [showTextEditor]="showTextEditorOnDialog"
  cancelButtonText="Cancel"
  [confirmButtonText]="confirmButtonTextOnDialog"
  [iconType]="
    buildFlowActionsData?.action === 'edit' ? 'alert-triangle' : 'alert-circle'
  "
  (dialogConfirmed)="onConfirmDialog()"
  (dialogCancel)="onCancelConfirmDialog()"
  [type]="buildFlowActionsData?.action === 'edit' ? 'warning' : 'error'">
</app-confirm-dialog>
