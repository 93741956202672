<div
  class="add-edit-section build-flow-add-edit-form-container p-2"
  [ngClass]="{ 'is-edit': formData }">
  <div class="add-edit-section-form build-flow-add-edit-form-wrapper py-2 px-4">
    <div class="drag-icon-disable">
      <img
        [src]="path.getAssetUrl('/assets/svg/drag-icon-disable.svg')"
        alt="icon of Drag and drop disabled" />
    </div>
    <form [formGroup]="sectionForm" name="add-edit-section" novalidate>
      <div class="row mx-0 d-flex align-items-center">
        <div class="col-12 col-sm-3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="title"
              class="form-control input-container"
              id="section-title-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              name="section-title-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              placeholder="Section title"
              aria-describedby="Section title" />
            <span class="seq-num">
              {{ stageDisplayOrder }}.{{ formData.displayOrder }}.</span
            >
            <label
              for="section-title-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              >Section Title</label
            >
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="section-placeholder-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              name="section-placeholder-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              placeholder="Section"
              aria-describedby="Section"
              disabled />
            <label
              for="section-placeholder-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              >Section</label
            >
          </div>
        </div>

        <div class="col-12 col-sm-2" *ngIf="stageTypeId && stageTypeId !== 3">
          <div class="form-floating">
            <input
              type="text"
              formControlName="score"
              class="form-control"
              id="section-score-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              name="section-score-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              [placeholder]="
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              "
              [attr.aria-describedby]="
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              " />
            <label
              for="section-score-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              >{{
                stageTypeId === 1 ||
                (evaluationScoringMethodId === 1 && stageTypeId === 4)
                  ? 'Points'
                  : 'Weight'
              }}</label
            >
          </div>
        </div>

        <!--Start Section type pass/fail area -->
        <div class="col-12 col-sm-2" *ngIf="stageTypeId === 3">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="section-pass-fail-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              name="section-pass-fail-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              placeholder="Pass/Fail"
              aria-describedby="Pass/Fail"
              disabled="" />
            <label
              for="section-pass-fail-{{ stageDisplayOrder }}-{{
                formData.displayOrder
              }}"
              >Pass/Fail</label
            >
          </div>
        </div>
        <!--End Section type pass/fail area -->

        <div class="col-12 col-sm-5">
          <a href="javascript:void(0)" class="disabled">Assign Evaluators</a>
          <app-icon
            class="ms-2"
            [type]="'info'"
            [width]="18"
            [height]="18"
            placement="top"
            ngbTooltip="You must add criteria within this section before you can assign any evaluators."></app-icon>
        </div>
      </div>
    </form>
  </div>
</div>
