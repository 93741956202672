import { environment } from '../../../environments/environment';
const baseUrl = environment.BASE_URL;
const proPortalBaseUrl = environment.PRO_PORTAL_BASE_URL;
const apiVersion = 'api/v1';

const nodeId = '{nodeId}';
const projectId = '{projectId}';
const customerId = '{customerId}';

const API_BASE_URL = `${baseUrl}/${apiVersion}/node/${nodeId}/project/${projectId}`;
const PRO_PORTAL_API_BASE_URL = `${baseUrl}/${apiVersion}/node/${nodeId}/project/${projectId}/customer/${customerId}`;

export const serviceUrl: any = {
  proPortalBaseURl: `${proPortalBaseUrl}/#/team/addteammember`,
  apiBaseURL: API_BASE_URL,
  proPortalApiBaseURL: PRO_PORTAL_API_BASE_URL,
  evaluationExists: `${API_BASE_URL}/evaluation/exists`,
  evaluation: `${API_BASE_URL}/evaluation`,
  getMasterDataForEvaluation: `${PRO_PORTAL_API_BASE_URL}/evaluation/master`,
  getStages: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  deleteStage: `${API_BASE_URL}/evaluation/stage`,
  deleteSection: `${API_BASE_URL}/evaluation/section`,
  deleteCriteria: `${API_BASE_URL}/evaluation/criteria`,
  deleteEvaluationStage: `${API_BASE_URL}/assign/evaluators/stage`,
  proposalResponse: `${PRO_PORTAL_API_BASE_URL}/proposalresponse`,
  saveBuildFlow: `${API_BASE_URL}/evaluation/autosave`,
  getTeamMembersList: `${PRO_PORTAL_API_BASE_URL}/teammembers`,
  getEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluators`,
  saveEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluators`,
  saveEvaluationStages: `${API_BASE_URL}`,

  getAssignEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  getLibraryContent: `${PRO_PORTAL_API_BASE_URL}/evaluation`,

  getEvaluatorsListByCriteria: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  saveEvaluatorsListByCriteria: `${API_BASE_URL}/assign/evaluators`,
  supplierStatusList: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
  supplierqualifystatus: `${PRO_PORTAL_API_BASE_URL}/supplierqualifystatus`,
  unsealpricing: `${PRO_PORTAL_API_BASE_URL}/unsealpricing`,
  getCoiContent: `${PRO_PORTAL_API_BASE_URL}/evaluation`,

  getEvaluationreviewDetails: `${API_BASE_URL}/evaluation`,
  getAllStages: `${API_BASE_URL}/evaluation`,
  postEvaluationreviewDetails: `${API_BASE_URL}/evaluation`,
  putEvaluationreviewDetails: `${API_BASE_URL}/evaluation`,
  cancelMeeting: `${API_BASE_URL}/evaluation`,
  getStatus: `${API_BASE_URL}/evaluation`,
  generateScorecard: `${PRO_PORTAL_API_BASE_URL}/generatescorecard`,
};
