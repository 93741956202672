<div *ngIf="selectedProject">
  <h2 class="project-title">
    {{ selectedProject.project.id }}
    {{ selectedProject.project.name }}
  </h2>
  <div class="text-center py-3" *ngIf="showLoader; else content">
    <app-loader [showText]="true"></app-loader>
  </div>
  <ng-template #content>
    <div [innerHTML]="selectedProject?.project.content"></div>
  </ng-template>

  <div>
    <input
      type="checkbox"
      class="me-2"
      [disabled]="true"
      [(ngModel)]="isAcknowledged" />
    Yes, I have read the non-disclosure agreement
  </div>

  <div class="mt-3 nonDisclosure-continue">
    <button type="button" class="btn btn-primary me-3" [disabled]="true">
      Continue
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="true">
      Cancel
    </button>
  </div>

  <button class="arrow-left">
    <app-icon [type]="'arrow-left'" [width]="18" [height]="18"> </app-icon>
  </button>
  <a class="back-btn-link" (click)="backToDashboard()"
    >Back to My Evaluation Dashboard</a
  >
</div>
