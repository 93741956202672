import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/core/services/store.service';
import { PricingFormula, StageTypes } from 'src/app/shared/constants/constants';
import { Subscription } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
@Component({
  selector: 'app-criteria-view',
  templateUrl: './criteria-view.component.html',
  styleUrls: ['./criteria-view.component.scss'],
})
export class CriteriaViewComponent implements OnInit, OnDestroy {
  @Input() criterias: any = [];
  @Input()
  stageTypeId!: number;
  @Input() stageStatus = '';
  @Input()
  stageDisplayOrder!: number;
  @Input()
  sectionDisplayOrder!: number;
  @Input() enableErrorsWarnings = false;
  @Input() buildFlowFocus: any = {};
  @ViewChild('criteriaAccordion') criteriaAccordion: any;

  constructor(
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}

  stageTypes: any = StageTypes;
  pricingFormula: any = PricingFormula;
  selectedEditCriteriaDisplayOrder = 0;
  buildEditFlowSubscription!: Subscription;

  trackByFn(index: number, criteria: any) {
    return criteria.id ? criteria.id : criteria.uniqueTimestamp;
  }

  showEditCriteria(criteria: any) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: this.sectionDisplayOrder,
      criteria: criteria.displayOrder,
    });
    if (this.stageStatus == 'Active') {
      this.selectedEditCriteriaDisplayOrder = criteria.displayOrder;
      this.store.setBuildFlowActionsData({
        data: criteria,
        type: 'criteria',
        action: 'edit',
      });
    } else {
      criteria['enableEdit'] = true;
      this.criteriaAccordion.expand(`criteria-item-${criteria.displayOrder}`);
    }
  }
  editActiveStageCriteria() {
    this.buildEditFlowSubscription =
      this.store.getBuildFlowEditActionsData.subscribe((data): any => {
        if (
          data.action === 'edit' &&
          data?.type === 'criteria' &&
          this.selectedEditCriteriaDisplayOrder === data.data.displayOrder
        ) {
          data.data['enableEdit'] = true;
          this.selectedEditCriteriaDisplayOrder = 0;
          this.criteriaAccordion.expand(
            `criteria-item-${data.data.displayOrder}`
          );
        }
      });
  }

  hideEditCriteria(criteria: any) {
    criteria['enableEdit'] = false;
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: this.sectionDisplayOrder,
      criteria: criteria.displayOrder,
    });
  }

  copyCriteria(displayOrder: number) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: this.sectionDisplayOrder,
      criteria: displayOrder,
    });
    this.store.setBuildFlowActionsData({
      displayOrder: displayOrder,
      stageDisplayOrder: this.stageDisplayOrder,
      sectionDisplayOrder: this.sectionDisplayOrder,
      type: 'criteria',
      action: 'copy',
    });
  }

  deleteCriteria(criteria: any) {
    this.store.setBuildFlowAccodionFocus({
      stage: this.stageDisplayOrder,
      section: this.sectionDisplayOrder,
      criteria: criteria.displayOrder,
    });
    this.store.setBuildFlowActionsData({
      evaluationCriteriaId: criteria.id,
      title: criteria.title,
      displayOrder: criteria.displayOrder,
      stageDisplayOrder: this.stageDisplayOrder,
      sectionDisplayOrder: this.sectionDisplayOrder,
      stageStatus: this.stageStatus,
      type: 'criteria',
      action: 'delete',
      isLocalDelete: criteria.id ? false : true,
    });
  }
  dropCriterias(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.criterias, event.previousIndex, event.currentIndex);
    this.store.setBuildFlowActionsData({
      type: 'criteria',
      action: 'dragdrop',
      data: this.criterias,
      stageDisplayOrder: this.stageDisplayOrder,
      sectionDisplayOrder: this.sectionDisplayOrder,
    });
  }

  ngOnInit(): void {
    this.editActiveStageCriteria();
  }
  ngOnDestroy(): void {
    if (this.buildEditFlowSubscription) {
      this.buildEditFlowSubscription.unsubscribe();
    }
  }
}
