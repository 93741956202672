<h1 class="pb-1">Supplier Status</h1>

<div class="supplier-container">
  <p class="supplier-desc pb-1">
    Compare suppliers throughout the evaluation process. View detailed scores
    and comments by expanding a supplier. Generate reports for internal or
    external use by clicking 'Create Supplier Report'.
  </p>

  <div class="suplier-status-container">
    <h2 class="supplier-status-title">Supplier Status Filter</h2>
    <p>
      Filter suppliers by name, status (Qualified, Disqualified), or pricing
      (Sealed, Unsealed). Use the Actions menu to manage multiple suppliers,
      including unsealing or disqualifying them.
    </p>

    <div class="search-box mb-3">
      <!-- <div class="text-center mt-5"> -->
      <input
        class="form-control h-50"
        type="text"
        [(ngModel)]="searchTerm"
        (input)="filterData()"
        autocomplete="off"
        placeholder="Search suppliers" />
      <button type="button" (click)="onClearSearch()" class="clear-search">
        <app-icon
          *ngIf="!isTyping"
          class="input-icon"
          [type]="'search'"
          [width]="18"
          [height]="18">
        </app-icon>
        <app-icon
          *ngIf="isTyping"
          class="input-icon"
          [type]="'x'"
          [width]="20"
          [height]="20"></app-icon>
      </button>
    </div>
    <div class="filter-container mb-2">
      <div class="form-floating filter">
        <select
          (change)="filterData()"
          [(ngModel)]="selectedOption1"
          class="form-select filter-dropdown"
          id="stages"
          name="stages"
          placeholder="Stages"
          aria-label="Stages">
          <option value="All Statuses">All Statuses</option>
          <option value="Disqualified">Disqualified</option>
          <option value="Qualified">Qualified</option>
        </select>
        <label for="stage-type">Filter by Status</label>
      </div>
      <div class="form-floating filter">
        <select
          (change)="filterData()"
          [(ngModel)]="selectedOption2"
          class="form-select filter-dropdown"
          id="stages"
          name="stages"
          placeholder="Stages"
          aria-label="Stages">
          <option value="All Pricing">All Pricing</option>
          <option value="Sealed Pricing">Sealed Pricing</option>
          <option value="Unsealed Pricing">Unsealed Pricing</option>
        </select>
        <label for="stage-type">Filter by Pricing</label>
      </div>
      <div class="action-btns">
        <div ngbDropdown class="d-inline-block" placement="bottom-end">
          <button
            class="btn btn-secondary dropdown-toggle min-width custom-icon actions-supplier"
            type="button"
            ngbDropdownToggle
            aria-expanded="false"
            id="supplier-status-actions"
            [disabled]="!isAtLeastOneSelected()">
            Actions
            <app-icon
              class="icon"
              [type]="'more-horizontal'"
              [width]="18"
              [height]="18"
              [strokeWidth]="3"></app-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="supplier-status-actions">
            <button
              ngbDropdownItem
              [disabled]="allUnsealed"
              class="dropdown-item supplier-action"
              type="button"
              (click)="openSealOrUnsealModal()">
              Unseal Pricing
            </button>
            <button
              ngbDropdownItem
              [disabled]="allUDisqualify"
              class="dropdown-item supplier-action"
              type="button"
              (click)="openDisOrQualifyModal('disqualify')">
              Disqualify Suppliers
            </button>
            <button
              ngbDropdownItem
              [disabled]="allQualify"
              class="dropdown-item supplier-action"
              type="button"
              (click)="openDisOrQualifyModal('requalify')">
              Requalify Suppliers
            </button>
          </div>
        </div>
        <div>
          <button
            [disabled]="true"
            type="button"
            class="btn btn-primary create-report mt-2">
            Create Supplier Report
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--checked table-->

  <div
    class="select-all-supplier-wrapper pb-2 mt-3 d-flex align-items-baseline">
    <table class="table-responsive">
      <thead>
        <th>
          <div class="checkbox-wrapper pe-3">
            <input
              type="checkbox"
              class="theme-checkbox"
              [(ngModel)]="selectAll"
              (change)="onSelectAllChange()" />
          </div>
        </th>
        <th class="text-center">Rank</th>
        <th class="supplier-text">Supplier Name</th>
        <th></th>
        <th>Min Threshold</th>
        <th class="text-center">Scores</th>
        <th class="text-center">Status</th>
        <th></th>
      </thead>
      <tbody>
        <ng-container *ngFor="let supplier of filteredItems; let i = index">
          <tr>
            <td>
              <div class="checkbox-wrapper pe-3">
                <input
                  type="checkbox"
                  class="theme-checkbox"
                  [(ngModel)]="supplier.selected"
                  (change)="onCheckboxChange()" />
              </div>
            </td>
            <td class="text-center">
              <strong>{{ supplier.rank }}</strong>
            </td>
            <td class="supplier-text">
              <strong> {{ supplier.companyName }} </strong>
            </td>
            <td class="text-center">
              <button
                class="btn unsealed-pricing"
                *ngIf="supplier.unsealPrices">
                <div class="sealed-pricing-icon">
                  <span class="sealed-icon justify-content-between">
                    <img
                      [src]="path.getAssetUrl('/assets/svg/checked.svg')"
                      alt="alert icon" />
                  </span>
                  <span class="btn-text">Unsealed Pricing </span>
                </div>
              </button>
              <button class="btn sealed-pricing" *ngIf="!supplier.unsealPrices">
                <div class="sealed-pricing-icon">
                  <span class="sealed-icon justify-content-between">
                    <img
                      [src]="path.getAssetUrl('/assets/svg/alert-yellow.png')"
                      alt="alert icon" />
                  </span>
                  <span class="btn-text">Sealed Pricing </span>
                </div>
              </button>
            </td>
            <td></td>
            <td class="text-center">
              <span *ngIf="supplier.supplier; else score">
                Evaluation Not Started Yet
              </span>
              <ng-template #score>
                <span>
                  {{ supplier.totalEvaluatorScore }} pts/{{
                    supplier.totalScoreAllStages
                  }}
                  pts</span
                >
              </ng-template>
            </td>
            <td
              class="text-center"
              *ngIf="supplier.qualifyStatus; else disQualify">
              <button
                class="btn btn-disqualify text-center"
                (click)="openDisOrQualifyModalSingleItem('disqualify', i)">
                Disqualify
              </button>
            </td>
            <ng-template #disQualify>
              <td class="text-center">
                <button
                  class="btn btn-requalify text-center"
                  (click)="openDisOrQualifyModalSingleItem('requalify', i)">
                  Requalify
                </button>
              </td>
            </ng-template>

            <td>
              <div class="accordion-table accordion" id="supplier-accordion">
                <div class="accordion-item">
                  <button
                    (click)="toggleAccordion(supplier)"
                    class="accordion-button"
                    type="button"
                    [class.collapsed]="
                      expandedSuppliers[supplier.vendorId]
                    "></button>
                </div>
              </div>
            </td>
          </tr>
          <ng-container *ngIf="expandedSuppliers[supplier.vendorId]">
            <ng-container
              *ngFor="
                let stage of supplier.stages | orderBy: 'displayOrder';
                let stageIndex = index
              ">
              <tr class="row-stage">
                <td></td>
                <td></td>
                <td class="supplier-text">
                  <strong class="stage-padding">
                    {{ stage.displayOrder + '.'
                    }}{{ ' ' + stage.title }} </strong
                  ><span class="light-shade-text">{{ ' - STAGE' }}</span>
                </td>
                <td></td>
                <td>{{ stage.minThreshold }}</td>
                <td
                  [class]="
                    stage.stageEvaluatorScore > stage.minThreshold
                      ? 'text-center'
                      : 'red-text text-center'
                  ">
                  {{ stage.stageEvaluatorScore }} pts/{{ stage.totalScore }} pts
                </td>
                <td></td>

                <td>
                  <div
                    class="accordion-table accordion"
                    id="supplier-accordion">
                    <div class="accordion-item">
                      <button
                        class="accordion-button row-stage"
                        type="button"
                        [class.collapsed]="
                          expandedSuppliersStages[supplier.vendorId + stage.id]
                        "
                        (click)="
                          toggleNestedAccordion(stage, supplier.vendorId)
                        "></button>
                    </div>
                  </div>
                </td>
              </tr>
              <ng-container
                *ngIf="expandedSuppliersStages[supplier.vendorId + stage.id]">
                <ng-container
                  *ngFor="
                    let item of getSectionAndCriteriasList(
                      stage.sections,
                      stage.criterias
                    ) | orderBy: 'displayOrder'
                  ">
                  <tr class="row-section">
                    <td></td>
                    <td></td>
                    <td class="supplier-text">
                      <strong class="section-padding">
                        {{ stage.displayOrder + '.' + item.displayOrder + '.'
                        }}{{ ' ' + item.title }}</strong
                      >
                      <span class="light-shade-text">{{
                        item.hasOwnProperty('evaluationStageId')
                          ? ' - SECTION'
                          : ''
                      }}</span>
                    </td>
                    <td></td>

                    <td></td>
                    <td
                      [class]="
                        item.score > stage.minThreshold
                          ? 'text-center'
                          : 'red-text text-center'
                      ">
                      {{ item.score }} pts/{{ item.sectionEvaluatorScore }}
                      pts
                    </td>
                    <td></td>
                    <td
                      colspan="1"
                      *ngIf="item.hasOwnProperty('evaluationStageId')">
                      <div
                        class="accordion-table accordion"
                        id="supplier-accordion">
                        <div class="accordion-item">
                          <button
                            class="accordion-button row-section"
                            type="button"
                            [class.collapsed]="
                              expandedSuppliersStages[
                                supplier.vendorId + item.id
                              ]
                            "
                            (click)="
                              toggleNestedAccordion(item, supplier.vendorId)
                            "></button>
                        </div>
                      </div>
                    </td>
                    <td
                      *ngIf="!item.hasOwnProperty('evaluationStageId')"
                      class="row-section"></td>
                  </tr>
                  <ng-container
                    *ngIf="
                      expandedSuppliersStages[supplier.vendorId + item.id]
                    ">
                    <ng-container
                      *ngFor="
                        let criteria of item.criterias
                          | orderBy: 'displayOrder';
                        let criteriaIndex = index
                      ">
                      <tr class="row-criteria">
                        <td></td>
                        <td></td>
                        <td class="supplier-text">
                          <strong class="criteria-padding"
                            >{{
                              stage.displayOrder +
                                1 +
                                '.' +
                                item.displayOrder +
                                '.' +
                                criteria.displayOrder +
                                '.'
                            }}{{ ' ' + criteria.title }}</strong
                          >
                        </td>
                        <td></td>
                        <td></td>
                        <td
                          [class]="
                            criteria.score > stage.minThreshold
                              ? 'text-center'
                              : 'red-text text-center'
                          ">
                          {{ criteria.score }} pts/{{
                            criteria.sectionEvaluatorScore
                          }}
                          pts
                        </td>
                        <td></td>

                        <td></td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="pagination-footer">
    <button
      class="btn navigation-btn btn-secondary"
      (click)="goToTheFirstPage()"
      [disabled]="currentPage === 1">
      <app-icon
        class="chevrons-left back-btn-link"
        [type]="'chevrons-left'"
        [width]="18"
        [height]="18">
      </app-icon>
    </button>
    <button
      class="btn navigation-btn btn-secondary"
      (click)="previousPage()"
      [disabled]="currentPage === 1">
      <app-icon
        class="chevron-left back-btn-link"
        [type]="'chevron-left'"
        [width]="18"
        [height]="18">
      </app-icon>
    </button>
    <span class="pagination-align">
      Page
      <button class="btn btn-secondary navigation-btn number-input">
        {{ currentPage }}
      </button>
      of {{ totalPages }}
    </span>
    <button
      class="btn navigation-btn btn-secondary"
      (click)="nextPage()"
      [disabled]="currentPage === totalPages">
      <app-icon
        class="chevron-right forward-btn-link"
        [type]="'chevron-right'"
        [width]="18"
        [height]="18">
      </app-icon>
    </button>
    <button
      class="btn navigation-btn btn-secondary chevrons-right-btn"
      (click)="goToTheLastPage()"
      [disabled]="currentPage === totalPages">
      <app-icon
        class="chevrons-right forward-btn-link"
        [type]="'chevrons-right'"
        [width]="18"
        [height]="18">
      </app-icon>
    </button>
    <select
      class="page-count"
      [(ngModel)]="pageSize"
      (change)="onPageSizeChange()">
      <option *ngFor="let size of pageSizeOptions" [value]="size">
        {{ size + '/ Page' }}
      </option>
    </select>
  </div>
</div>
<app-confirm-dialog
  *ngIf="showConfirmDialog"
  [message]="message"
  [dialogTitle]="title"
  cancelButtonText="Cancel"
  [isMinWidth]="false"
  [confirmButtonText]="confirmButtonText"
  [iconType]="'alert-circle'"
  (dialogConfirmed)="confirmationMethod(this.itemIndex)"
  (dialogCancel)="modalCancel()"
  [type]="'error'">
</app-confirm-dialog>
<!--No Suppliers-->
<!-- <div class="card text-center py-4">
  <div class="card-body">
    <div class="img-wrapper mb-3">
      <span>
        <img [src]="path.getAssetUrl('/assets/svg/suppliers-icon.png')" />
      </span>
    </div>
    <h2>No Suppliers</h2>
    <p class="mb-2">
      Suppliers will be added once they have completed their submission.
    </p>
  </div>
</div> -->
