import { Component } from '@angular/core';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  menuItems = [
    { name: 'Build Evaluation', url: 'build-evaluation' },
    { name: 'Evaluation Team', url: 'evaluation-team' },
    { name: 'Evaluation Review', url: 'review-meetings' },
    { name: 'Supplier Status', url: 'supplier-status' },
  ];
  isExpanded = true;

  constructor(private store: StoreService) {}

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
    this.store.setSidebarExpanded(this.isExpanded);
  }
}
