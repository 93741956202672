<div
  class="modal d-block bg-dark bg-opacity-50"
  id="assignStageModal"
  tabindex="-1"
  aria-labelledby="assignStagesModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable w-650">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header ps-5 pt-5 pb-2 border-0">
        <h2 class="text-start">Assign Stages</h2>
        <button
          type="button"
          class="btn-close custom-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"></button>
      </div>
      <div class="w-100 px-5 pb-4 mb-1 position-relative" *ngIf="stages.length">
        <input
          class="form-control h-50"
          type="text"
          (input)="onSearchInput()"
          [(ngModel)]="searchQuery"
          autocomplete="off"
          placeholder="Keyword Search"
          [disabled]="!stages.length" />
        <button type="button" (click)="onClearSearch()" class="clear-search">
          <app-icon
            *ngIf="!isTyping"
            class="input-icon"
            [type]="'search'"
            [width]="18"
            [height]="18">
          </app-icon>
          <app-icon
            *ngIf="isTyping"
            class="input-icon"
            [type]="'x'"
            [width]="20"
            [height]="20"></app-icon>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="modal-body p-0 text-center">
        <div class="py-3" *ngIf="showLoader; else assignStages">
          <app-loader [showText]="true"></app-loader>
        </div>
        <ng-template #assignStages>
          <div class="px-5 pt-3">
            <div class="stages-container" *ngIf="stages.length; else noStages">
              <!-- Accordion Structure -->
              <div class="grey-area assign-stages-container">
                <!-- Global Select All -->
                <div
                  class="mb-1 mt-3 accordion-collapse collapse show selectAll">
                  <input
                    type="checkbox"
                    id="selectAll"
                    [(ngModel)]="isSelectedAllChecked"
                    [checked]="isSelectAllChecked"
                    [indeterminate]="isPartiallySelected()"
                    (change)="selectAll($event)" />
                  <label class="form-check-label" for="selectAll"
                    >Select All</label
                  >
                </div>
                <!-- Accordion for Stages -->
                <div
                  class="accordion accordion-type-list ps-3"
                  id="stageAccordion">
                  <div
                    *ngFor="
                      let stage of stages | orderBy: 'displayOrder';
                      let stageIndex = index
                    "
                    class="accordion-item">
                    <div
                      class="d-flex align-items-center justify-content-between stage-section-align accordion-item-container"
                      [class.opacity-50]="!stage.isCriteriaExist">
                      <div class="d-flex">
                        <input
                          type="checkbox"
                          [(ngModel)]="stage.isSelected"
                          (change)="selectStage(stage)"
                          [disabled]="!stage.isCriteriaExist" />
                        <label class="form-check-label" for="selectAll"
                          >{{ stage.displayOrder }}.
                          {{ stage.title }} (stage)</label
                        >
                      </div>
                      <div
                        class="accordion-header stage-header"
                        [id]="'stage' + stageIndex + 'Header'">
                        <button
                          (click)="toggleAccordion(stage)"
                          class="accordion-button"
                          [class.collapsed]="!stage.expanded"
                          type="button"
                          [attr.aria-controls]="
                            'stage-accordion-' + stage.displayOrder
                          "
                          aria-expanded="true"></button>
                      </div>
                    </div>
                    <div
                      [attr.id]="'stage-accordion-' + stage.displayOrder"
                      class="accordion-collapse collapse"
                      [class.show]="!stage.expanded"
                      [attr.aria-labelledby]="
                        'stage' + stage.displayOrder + 'Header'
                      ">
                      <div class="accordion-body">
                        <!-- Sections -->
                        <div
                          *ngFor="
                            let item of getSectionAndCriteriasList(
                              stage.sections,
                              stage.criterias
                            ) | orderBy: 'displayOrder';
                            index as itemIndex
                          "
                          class="accordion">
                          <div
                            class="accordion-item"
                            *ngIf="item.hasOwnProperty('evaluationStageId')">
                            <div
                              class="d-flex align-items-center justify-content-between stage-section-align accordion-item-container"
                              [class.opacity-50]="!stage.isCriteriaExist">
                              <div class="d-flex">
                                <input
                                  type="checkbox"
                                  [(ngModel)]="item.isSelected"
                                  (change)="selectSection(item, stage)"
                                  [disabled]="!item.isCriteriaExist" />
                                <label class="form-check-label" for="selectAll"
                                  >{{ stage.displayOrder }}.{{
                                    item.displayOrder
                                  }}
                                  {{ item.title }} (section)</label
                                >
                              </div>
                              <div
                                class="accordion-header stage-header"
                                [id]="'section' + item.displayOrder + 'Header'">
                                <button
                                  class="accordion-button"
                                  type="button"
                                  (click)="toggleAccordion(item)"
                                  [class.collapsed]="!item.expanded"
                                  [attr.aria-controls]="
                                    'section-accordion-' + item.displayOrder
                                  "
                                  aria-expanded="true"></button>
                              </div>
                            </div>
                            <div
                              [attr.id]="
                                'section-accordion-' + item.displayOrder
                              "
                              class="accordion-collapse collapse"
                              [class.show]="!item.expanded"
                              [attr.aria-labelledby]="
                                'section' + item.displayOrder + 'Header'
                              ">
                              <div class="accordion-body">
                                <!-- Criteria -->
                                <div
                                  class="accordion-item"
                                  *ngFor="let criterion of item.criterias">
                                  <div
                                    class="d-flex align-items-center justify-content-between accordion-item-container">
                                    <div class="d-flex">
                                      <input
                                        type="checkbox"
                                        [(ngModel)]="criterion.isSelected"
                                        (change)="
                                          selectCriterion(item, stage)
                                        " />
                                      <label
                                        class="form-check-label"
                                        for="selectAll"
                                        >{{ stage.displayOrder }}.{{
                                          item.displayOrder
                                        }}.{{ criterion.displayOrder }}
                                        {{ criterion.title }}</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="accordion-collapse collapse"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="accordion-item"
                            *ngIf="item.hasOwnProperty('parentId')">
                            <div
                              class="d-flex align-items-center justify-content-between accordion-item-container">
                              <div class="d-flex">
                                <input
                                  type="checkbox"
                                  [(ngModel)]="item.isSelected"
                                  (change)="selectCriterion(stage, item)" />
                                <label class="form-check-label" for="selectAll"
                                  >{{ stage.displayOrder }}.{{
                                    item.displayOrder
                                  }}
                                  {{ item.title }}</label
                                >
                              </div>
                            </div>
                            <div class="accordion-collapse collapse"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noStages>
            <div class="strong pt-4 pb-5 text-start">
              There are no stages created yet to assign
            </div>
          </ng-template>
        </ng-template>
      </div>
      <!-- Modal Footer -->
      <div class="modal-footer border-0 justify-content-start px-3 py-4">
        <button
          [disabled]="!stages?.length"
          type="button"
          class="btn btn-primary min-width"
          (click)="confirmModelTrigger($event)"
          data-bs-dismiss="modal">
          {{ stages?.length ? 'Save' : 'Add' }}
        </button>
        <button
          type="button"
          class="btn btn-secondary min-width"
          (click)="closeModal()"
          data-bs-dismiss="modal">
          Cancel
        </button>
        <button
          *ngIf="stages?.length"
          type="button"
          class="btn btn-secondary min-width"
          (click)="deselectAll()">
          Deselect All
        </button>
      </div>
    </div>
  </div>
</div>
