<div class="card text-center py-4">
  <div class="card-body">
    <div class="img-wrapper mb-3">
      <span>
        <img
          [src]="path.getAssetUrl('/assets/svg/cloud-failed.svg')"
          alt="cloud failed" />
      </span>
    </div>
    <h2 class="page-failed-title">Failed to load</h2>
    <p class="error-page-desc">
      We’re sorry, but something went wrong. Please refresh your browser window
      to continue.
    </p>
    <button
      type="button"
      class="btn btn-primary min-width refresh-data"
      (click)="refreshData()">
      Refresh
    </button>
  </div>
</div>
