<h1 class="build-evaluation-title pb-1">Build Evaluation</h1>
<p class="build-evaluation-desc mb-4 pb-1">
  Start building the stages and criteria of your evaluation.
</p>
<!-- Start: Evaluation API Failed Desing -->
<app-landing-page-error
  *ngIf="isEvaluationError; else evaluationFlow"
  (refresh)="getEvaluation()"></app-landing-page-error>
<!-- End: Evaluation API Failed Desing -->
<ng-template #evaluationFlow>
  <div class="card text-center py-4">
    <!-- Start: Evaluation Landing page Desing -->
    <div class="card-body">
      <div
        *ngIf="evaluationData?.evaluationExists"
        ngbDropdown
        class="d-inline-block actions-dropdown"
        placement="bottom-end">
        <button
          type="button"
          class="btn btn-secondary dropdown-toggle min-width custom-icon actions-evaluation"
          id="evaluation-action-dropdown"
          aria-expanded="false"
          ngbDropdownToggle>
          Actions
          <app-icon
            class="icon"
            [type]="'more-horizontal'"
            [width]="18"
            [height]="18"
            [strokeWidth]="3"></app-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="evaluation-action-dropdown">
          <button
            ngbDropdownItem
            class="evaluation-action"
            type="button"
            (click)="toggleAddEvaluation()">
            Edit Settings
          </button>
          <button
            ngbDropdownItem
            class="evaluation-action"
            type="button"
            (click)="openErrorConfirmModal()">
            Remove Evaluation
          </button>
        </div>
      </div>
      <div class="img-wrapper mb-3">
        <span *ngIf="evaluationData?.evaluationExists; else launchBuilder">
          <img
            [src]="path.getAssetUrl('/assets/svg/document-read.svg')"
            alt="document-read" />
        </span>
        <ng-template #launchBuilder>
          <img
            [src]="path.getAssetUrl('/assets/svg/rocket.svg')"
            alt="rocket" />
          <img
            [src]="path.getAssetUrl('/assets/svg/cog.svg')"
            alt="cog"
            class="cog-icon" />
        </ng-template>
      </div>
      <h2 class="evaluation-exist-title">
        {{ evaluationData?.evaluationExists ? 'Evaluation' : 'No Evaluation' }}
      </h2>
      <p
        *ngIf="!evaluationData?.evaluationExists"
        class="add-evaluation-desc mb-2">
        Select Add Evaluation to begin.
      </p>
      <button
        *ngIf="evaluationData?.evaluationExists"
        (click)="toggleLaunchBuilder()"
        type="button"
        class="btn btn-primary launch-evaluation mt-2">
        Launch Builder
      </button>
      <button
        *ngIf="!evaluationData?.evaluationExists"
        type="button"
        class="btn btn-primary add-evaluation mt-2"
        (click)="toggleAddEvaluation()">
        Add Evaluation
      </button>
      <app-build-evaluation-landing-page-errors
        [errorsWarnings]="
          launchBuilderErrorsWarningsData
        "></app-build-evaluation-landing-page-errors>
    </div>
    <!-- End: Evaluation Landing page Design -->
  </div>
</ng-template>

<!-- Modal -->
<div
  *ngIf="showAddEvaluationModal"
  class="modal d-block bg-dark bg-opacity-50"
  id="evaluationModal"
  tabindex="-1"
  aria-labelledby="evaluationModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="modal-dialog modal-lg">
    <form [formGroup]="evaluationForm" (ngSubmit)="saveEvaluation()">
      <div class="modal-content">
        <div class="pl-0 modal-header">
          <div class="d-flex w-100 justify-content-between align-items-start">
            <div class="pl-0">
              <h1>
                {{
                  evaluationData?.evaluationExists
                    ? 'Edit Evaluation'
                    : 'Add Evaluation'
                }}
              </h1>
              <label class="lebel-text"
                >Configure and create your evaluation</label
              >
            </div>
            <button
              type="button"
              class="btn-close btn-close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="cancelPopup()"></button>
          </div>
        </div>

        <div class="modal-body set-gap">
          <div class="row">
            <!-- Left Side (50% width for the form) -->
            <div class="col-md-8 col-lg-6">
              <!-- <form> -->
              <div class="sub-container">
                <h3 for="evaluationGuide ">Evaluation Guide</h3>
                <div class="form-floating">
                  <select
                    class="form-select"
                    formControlName="evaluationGuideId"
                    [ngClass]="{
                      'has-value': evaluationForm.value.evaluationGuideId,
                    }">
                    <option
                      *ngFor="let item of evaluationGuideList"
                      value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="conflictInterest"
                    >Select evaluation guide template</label
                  >
                </div>
              </div>

              <!-- Non-disclosure Agreement -->
              <div class="sub-container">
                <h3>Non-disclosure Agreement</h3>
                <label class="lebel-text"
                  >Do you want a non-disclosure agreement?</label
                >
                <div class="radio-btn-container margint-5">
                  <div class="pl-0 radio-btn form-check">
                    <input
                      class="radio-input"
                      type="radio"
                      [value]="true"
                      formControlName="nonDisclosure"
                      (change)="checkNonDisclosureAgreement()" />
                    <label class="lebel-text form-check-label" for="ndaYes"
                      >Yes</label
                    >
                  </div>
                  <div class="pl-0 radio-btn form-check">
                    <input
                      class="radio-input"
                      type="radio"
                      [value]="false"
                      formControlName="nonDisclosure"
                      (change)="checkNonDisclosureAgreement()"
                      selected="selected" />
                    <label class="lebel-text form-check-label" for="ndaNo"
                      >No</label
                    >
                  </div>
                </div>
                <!-- Non-disclosure Agreement Template -->
                <div class="form-floating margint-5">
                  <select
                    class="form-select"
                    formControlName="disclosureAgreementId"
                    [ngClass]="{
                      'has-value': evaluationForm.value.disclosureAgreementId,
                    }">
                    <option
                      class=""
                      *ngFor="let item of evaluationNonDisclosureAgreementList"
                      value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="ndaTemplate"
                    >Select non-disclosure agreement template</label
                  >
                </div>
              </div>

              <div class="sub-container">
                <!-- Conflict of Interest -->
                <h3 for="conflictInterest" class="form-label fw-bold">
                  Conflict of Interest
                </h3>
                <div class="form-floating">
                  <select
                    id="conflictInterest"
                    class="form-select"
                    formControlName="conflictOfInterestId"
                    [ngClass]="{
                      'has-value': evaluationForm.value.conflictOfInterestId,
                    }">
                    <option
                      class=""
                      *ngFor="let item of evaluationConflictOfInterestList"
                      value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="conflictInterest">Conflict of interest</label>
                </div>
              </div>

              <div class="sub-container">
                <div>
                  <h3 class="form-label fw-bold">Scoring</h3>
                  <div class="form-label">Select scoring method</div>
                  <div class="radio-btn-container">
                    <div class="pl-0 radio-btn form-check">
                      <input
                        class="radio-input"
                        type="radio"
                        [value]="1"
                        formControlName="scoringMethodId" />
                      <label class="lebel-text form-check-label" for="points"
                        >Points</label
                      >
                    </div>
                    <div class="pl-0 form-check" class="radio-btn">
                      <input
                        class="radio-input"
                        type="radio"
                        [value]="2"
                        formControlName="scoringMethodId" />
                      <label class="lebel-text form-check-label" for="weights"
                        >Weights</label
                      >
                    </div>
                  </div>
                </div>

                <!-- Decimal Place -->
                <div class="form-floating margint-5">
                  <select
                    id="decimalPlace"
                    class="form-select"
                    formControlName="decimalPlacesId"
                    [ngClass]="{
                      'has-value': evaluationForm.value.decimalPlacesId,
                    }">
                    <option selected class="" value="1">1</option>
                    <option class="" value="2">2</option>
                    <option class="" value="3">3</option>
                    <option class="" value="4">4</option>
                  </select>
                  <label for="decimalPlace">Select decimal place</label>
                </div>

                <!-- Evaluator Rating Scale Inputs -->

                <div class="mt-15">
                  <label for="ratingScale" class="form-label"
                    >Set default evaluator rating scale :</label
                  >

                  <div class="scorebox">
                    <div class="form-floating me-3">
                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        formControlName="minScore" />
                      <label for="floatingInput">Minimum score</label>
                    </div>
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        formControlName="maxScore" />
                      <label for="floatingInput">Maximum score</label>
                    </div>
                  </div>
                </div>

                <!-- Score Card Template -->
                <div class="form-floating mt-15">
                  <select
                    id="scoreCardTemplate"
                    class="form-select"
                    formControlName="scoreCardContentId"
                    [ngClass]="{
                      'has-value': evaluationForm.value.scoreCardContentId,
                    }">
                    <option
                      class=""
                      *ngFor="let item of evaluationScoreCardList"
                      value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="scoreCardTemplate">Score card template</label>
                </div>
              </div>
              <!-- </form> -->
            </div>
          </div>
        </div>

        <!-- Modal Footer (Save and Cancel Buttons) -->
        <div class="pl-0 modal-footer justify-content-start">
          <button type="submit" class="footer-btn btn btn-primary min-width">
            Save
          </button>
          <button
            type="button"
            class="footer-btn btn btn-secondary min-width"
            data-bs-dismiss="modal"
            (click)="cancelPopup()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-launch-builder
  *ngIf="showLaunchBuilder"
  (hideLaunchBuilder)="toggleLaunchBuilder()"></app-launch-builder>

<app-confirm-dialog
  *ngIf="showConfirmDialog"
  message="{{ confirmDialogMessage }}"
  dialogTitle="{{ confirmDialogTitle }}"
  cancelButtonText="Cancel"
  iconType="alert-circle"
  (dialogConfirmed)="onConfirmDialog($event)"
  (dialogCancel)="onCancelConfirmDialog()"
  type="error">
</app-confirm-dialog>
