<div
  class="modal d-block bg-dark bg-opacity-50"
  id="confirmationModal"
  tabindex="-1"
  aria-labelledby="confirmationModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="onCancel()"></button>
      </div>
      <div class="modal-body text-center">
        <div class="icon-container">
          <app-icon
            [class]="type"
            [type]="iconType"
            [width]="52"
            [height]="52"></app-icon>
        </div>
        <h5 class="mt-3 fw-bold">{{ dialogTitle }}</h5>
        <h5 class="mt-3 fw-bold" *ngIf="dialogSubTitle">
          {{ dialogSubTitle }}
        </h5>
        <p [innerHTML]="message"></p>
        <ng-content></ng-content>
        <div *ngIf="showTextEditor">
          <hr />
          <h4 class="title">{{ textEditorTitle }}</h4>
          <app-text-editor [confirmReason]="confirmReason"></app-text-editor>
        </div>
      </div>
      <div class="modal-footer justify-content-center border-0">
        <button
          *ngIf="showTextEditor"
          type="button"
          [ngClass]="
            type === 'error'
              ? confirmReason.text
                ? 'btn btn-danger'
                : 'btn btn-danger-outline'
              : 'btn btn-primary min-width'
          "
          (click)="onConfirm()"
          data-bs-dismiss="modal"
          [disabled]="!confirmReason.text && showTextEditor">
          {{ confirmButtonText }}
        </button>
        <button
          *ngIf="!showTextEditor"
          type="button"
          [class]="isMinWidth ? 'min-width' : ''"
          [ngClass]="type === 'error' ? 'btn btn-danger' : 'btn btn-primary'"
          (click)="onConfirm()"
          data-bs-dismiss="modal">
          {{ confirmButtonText }}
        </button>
        <button
          type="button"
          class="btn btn-secondary min-width"
          (click)="onCancel()"
          data-bs-dismiss="modal">
          {{ cancelButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
