import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EvaluationTeamService } from 'src/app/core/services/evaluation-team/evaluation-team.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-assign-stages',
  templateUrl: './assign-stages.component.html',
  styleUrls: ['./assign-stages.component.scss'],
})
export class AssignStagesComponent implements OnInit {
  @Input() selectedEvaluatorList: any = [];
  @Output() toggleAssignStagesModal = new EventEmitter<string>();
  @Output() refreshEvalData = new EventEmitter<boolean>();
  confirmDialogMessage: any;
  confirmDialogTitle: any;
  masterSelected = false;
  checkedList: any;
  searchText = '';
  stages: any = [];
  defaultSelectedIds: any = [];
  isTyping = false;
  searchQuery = '';
  isSelectAllChecked = false;
  showLoader = true;
  isSelectedAllChecked = false;

  ngOnInit() {
    this.getEvaluationStages();
  }

  constructor(
    private evaluationTeamService: EvaluationTeamService,
    private store: StoreService
  ) {
    this.masterSelected = false;
  }

  getEvaluationStages() {
    this.evaluationTeamService
      .getEvaluationStages(
        this.store.getEvaluationId(),
        this.selectedEvaluatorList
      )
      .subscribe({
        next: (data: any) => {
          this.stages = data?.data?.stages || [];
          this.defaultSelectedIds = [
            ...this.getSelectedAssignmentId(this.stages),
          ];
          this.showLoader = false;
        },
        error: e => {
          this.showLoader = false;
        },
      });
  }
  getSectionAndCriteriasList(sections: any, criterias: any) {
    return [...sections, ...criterias]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .slice();
  }

  getSelectedAssignmentId(stages: any) {
    const assignedIds: any = [];
    stages.forEach((stage: any) => {
      if (stage.isSelected) {
        assignedIds.push({ id: stage.id });
      }
      stage.sections.forEach((section: any) => {
        if (section.isSelected) {
          assignedIds.push({ id: section.id });
        }
        section.criterias.forEach((criteria: any) => {
          stage.isCriteriaExist = true;
          section.isCriteriaExist = true;
          if (criteria.isSelected) {
            assignedIds.push({ id: criteria.id });
          }
        });
      });
      stage.criterias.forEach((criteria: any) => {
        stage.isCriteriaExist = true;
        if (criteria.isSelected) {
          assignedIds.push({ id: criteria.id });
        }
      });
    });
    return assignedIds;
  }
  getRemoveAssignmentIDs(addAssignmentId: any) {
    return this.defaultSelectedIds.filter(
      (item: any) =>
        !addAssignmentId.some((currentItem: any) => currentItem.id === item.id)
    );
  }
  confirmModelTrigger(event: any) {
    const selectedEvaluators = this.selectedEvaluatorList.map((item: any) => ({
      teamMemberId: item,
    }));
    const addAssignmentId = this.getSelectedAssignmentId(this.stages);
    const removeAssignmentIDs = this.getRemoveAssignmentIDs(addAssignmentId);
    const payload = {
      addAssignStageRequest: {
        evaluationId: this.store.getEvaluationId(),
        evaluator: selectedEvaluators,
        addAssignmentId: addAssignmentId,
        removeAssignmentIDs: removeAssignmentIDs,
      },
    };

    this.evaluationTeamService.saveEvaluatorsStages(payload).subscribe({
      next: (data: any) => {
        this.refreshEvalData.emit();
        this.closeModal();
      },
    });
  }

  // Global Select All
  selectAll(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    this.stages.forEach((stage: any) => {
      stage.isSelected = checked;
      stage.criterias.forEach(
        (stageCriteria: any) => (stageCriteria.isSelected = checked)
      );
      stage.sections.forEach((section: any) => {
        section.isSelected = checked;
        section.criterias.forEach(
          (criterion: any) => (criterion.isSelected = checked)
        );
      });
    });
  }
  // Select/Deselect Stage
  selectStage(stage: any): void {
    stage.sections.forEach((section: any) => {
      section.isSelected = stage.isSelected;
      section.criterias.forEach(
        (criterion: any) => (criterion.isSelected = stage.isSelected)
      );
    });
    stage.criterias.forEach((stageCriteria: any) => {
      stageCriteria.isSelected = stage.isSelected;
    });
  }
  // Select/Deselect Section
  selectSection(section: any, stage: any): void {
    section.criterias.forEach(
      (criterion: any) => (criterion.isSelected = section.isSelected)
    );
    stage.isSelected = stage.sections.every((s: any) => s.isSelected);
  }
  // Select/Deselect Criterion
  selectCriterion(section: any, stage: any): void {
    section.isSelected = section.criterias.every((c: any) => c.isSelected);
    stage.isSelected = stage.sections.every((s: any) => s.isSelected);
    stage.isSelected = stage.criterias.every((c: any) => c.isSelected);
  }
  isAllSelected(): boolean {
    return this.stages.every((stage: any) => {
      stage.criterias.every((stageCriteria: any) => stageCriteria.isSelected);

      stage.sections.every((section: any) =>
        section.criterias.every((criterion: any) => criterion.isSelected)
      );
    });
  }
  deselectAll(): void {
    this.isSelectedAllChecked = false;
    this.stages.forEach((stage: any) => {
      stage.isSelected = false;
      stage.criterias.forEach(
        (stageCriteria: any) => (stageCriteria.isSelected = false)
      );

      stage.sections.forEach((section: any) => {
        section.isSelected = false;
        section.criterias.forEach(
          (criterion: any) => (criterion.isSelected = false)
        );
      });
    });
  }
  isPartiallySelected(): boolean {
    return (
      !this.isAllSelected() &&
      this.stages.some((stage: any) => {
        stage.criterias.some((stageCriteria: any) => stageCriteria.isSelected);
        stage.sections.some((section: any) =>
          section.criterias.some((criterion: any) => criterion.isSelected)
        );
      })
    );
  }
  onSearchInput() {
    this.isTyping = this.searchQuery.length > 0;
    // const search = this.searchQuery.toLowerCase();
    // this.stages.filter((obj: any) =>
    //   `${obj.title}`.toLowerCase().includes(search)
    // );
    //this.getEvaluationStages();
  }

  get filteredData() {
    return this.stages
      .filter((item: any) => this.matchesSearch(item))
      .map((item: any) => ({
        ...item,
        sections: item.sections.filter((sectionItem: any) =>
          this.matchesSearch(sectionItem)
        ),
        criterias: item.criterias.filter((criteriaItem: any) =>
          this.matchesSearch(criteriaItem)
        ),
      }))
      .filter(
        (item: any) => item.sections.length > 0 || item.criterias.length > 0
      ); // Remove items without valid sub-items
  }

  matchesSearch(item: any) {
    const term = this.searchQuery?.toLowerCase();
    return (
      item?.title?.toLowerCase().includes(term) ||
      item.sections.some(
        (sectionItem: any) =>
          sectionItem?.title?.toLowerCase().includes(term) ||
          sectionItem.criterias.some((criteriaItem: any) =>
            criteriaItem?.title?.toLowerCase().includes(term)
          )
      ) ||
      item.criterias.some((criteriaItem: any) =>
        criteriaItem?.title?.toLowerCase().includes(term)
      )
    );
  }

  onClearSearch() {
    this.searchQuery = '';
    this.isTyping = false;
  }

  closeModal() {
    this.toggleAssignStagesModal.emit('');
  }
  toggleAccordion(item: any) {
    item.expanded = !item.expanded;
  }
}
