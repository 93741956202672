import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { StoreService } from 'src/app/core/services/store.service';
import { SupplierStatusService } from 'src/app/core/services/supplier-status/supplier-status.service';

@Component({
  selector: 'app-supplier-status',
  templateUrl: './supplier-status.component.html',
  styleUrls: ['./supplier-status.component.scss'],
})
export class SupplierStatusComponent implements OnInit {
  searchText = '';
  isTyping = false;
  searchQuery = '';
  allUnsealed: any = false;
  allUDisqualify: any = false;
  allQualify: any = false;
  supplierStatusList: any = [];
  filteredItems: any = [];
  confirmButtonText = 'Confirm';
  evaluationId: any = null;
  expandedSuppliers: any = {};
  expandedSuppliersStages: any = {};
  constructor(
    private supplerStatusService: SupplierStatusService,
    private store: StoreService,
    public path: AssetsPathChangeService
  ) {}

  onSearchInput() {
    this.isTyping = this.searchQuery.length > 0;
  }
  getEvaluatorsList(evaluationId: any) {
    this.supplerStatusService.getSupplierStatusList(evaluationId).subscribe({
      next: (data: any) => {
        this.supplierStatusList = data.data.supplierStatus;
        this.filteredItems = [...this.supplierStatusList].map((item: any) => {
          return { ...item, selected: false };
        });

        this.updatepaginateddata();
      },
      error: e => {
        console.log(e);
      },
    });
  }
  ngOnDestroy(): void {
    this._subscribed = false;
  }
  _subscribed = true;
  subscribeStore() {
    this.store.getEvaluationIdAsObservable
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(id => {
        if (id) {
          this.getEvaluatorsList(id);
        }
      });
  }
  onClearSearch() {
    this.searchQuery = '';
    this.isTyping = false;
    this.filterData();
  }
  selectedOption1 = 'All Statuses';
  selectedOption2 = 'All Pricing';
  searchTerm = '';

  selectAll = false;
  showLoader = false;
  showConfirmDialog = false;
  closeGuideModal() {
    return 1;
  }
  isAtLeastOneSelected(): boolean {
    return (
      this.filteredItems.filter((item: any, index: any) => {
        return item.selected;
      }).length > 0
    );
  }

  onCheckboxChange(): void {
    this.selectAll =
      this.filteredItems.filter((item: any) => {
        return item.selected;
      }).length == this.filteredItems.length;

    this.checkAllItems();
  }

  onSelectAllChange(): void {
    this.filteredItems = this.filteredItems.map((item: any) => {
      return { ...item, selected: this.selectAll };
    });
    this.checkAllItems();
  }
  checkAllItems() {
    const selectedItems = this.filteredItems.filter((item: any, index: any) => {
      return item.selected;
    });

    const unsealedItems = this.filteredItems.filter((item: any, index: any) => {
      if (item.selected && item.unsealPrices) {
        return true;
      } else return false;
    });
    const qualifiedItems = this.filteredItems.filter(
      (item: any, index: any) => {
        if (item.selected && item.qualifyStatus) {
          return true;
        } else return false;
      }
    );
    const disQualifiedItems = this.filteredItems.filter(
      (item: any, index: any) => {
        if (item.selected && !item.qualifyStatus) {
          return true;
        } else return false;
      }
    );

    if (unsealedItems.length == selectedItems.length) {
      this.allUnsealed = true;
    } else {
      this.allUnsealed = false;
    }
    if (qualifiedItems.length == selectedItems.length) {
      this.allQualify = true;
    } else {
      this.allQualify = false;
    }
    if (disQualifiedItems.length == selectedItems.length) {
      this.allUDisqualify = true;
    } else {
      this.allUDisqualify = false;
    }
  }
  requalifySupplier(i = null) {
    const selectedItems =
      i != null
        ? [i]
        : this.filteredItems
            .map((value: any, index: any) =>
              value.selected === true ? index : -1
            )
            .filter((index: any) => index !== -1);
    const data = {
      isDisqualified: false,
      createdBy: 'dbbd88b0-f2e7-4f41-a05a-6b4d43466f68',
      suppliers: this.filteredItems
        .filter((item: any, index: any) => {
          if (selectedItems.includes(index) && !item.qualifyStatus) {
            return true;
          } else return false;
        })
        .map((item: any, index: any) => {
          return item.vendorId;
        }),
    };

    this.qualifySupplier(data);
  }
  disQualifySupplier(i: any = null) {
    const selectedItems =
      i != null
        ? [i]
        : this.filteredItems
            .map((value: any, index: any) =>
              value.selected === true ? index : -1
            )
            .filter((index: any) => index !== -1);
    const data = {
      evaluationId: this.evaluationId,
      isDisqualified: true,
      createdBy: 'dbbd88b0-f2e7-4f41-a05a-6b4d43466f68',
      suppliers: this.filteredItems
        .filter((item: any, index: any) => {
          if (selectedItems.includes(index) && item.qualifyStatus) {
            return true;
          } else return false;
        })
        .map((item: any, index: any) => {
          return item.vendorId;
        }),
    };
    this.qualifySupplier(data);
  }
  qualifySupplier(data: any) {
    this.supplerStatusService.setSupplierqualifystatus(data).subscribe({
      next: (data: any) => {
        this.showConfirmDialog = false;
        this.subscribeStore();
      },
    });
  }
  unSealSuppliers() {
    const selectedItems = this.filteredItems.filter(
      (item: any) => item.selected
    );
    const data = {
      submissionId: this.filteredItems
        .filter((item: any, index: any) => {
          if (selectedItems.includes(index) && !item.unsealPrices) {
            return true;
          } else return false;
        })
        .map((item: any, index: any) => {
          return item.submissionId;
        }),
    };
    this.supplerStatusService.setUnsealpricing(data).subscribe({
      next: (data: any) => {
        this.showConfirmDialog = false;
        this.subscribeStore();
      },
    });
  }
  openDisOrQualifyModal(dataType: any) {
    const selectedItems = this.filteredItems.filter(
      (item: any) => item.selected
    );
    this.confirmButtonText = 'Confirm';

    this.itemIndex = null;
    const qualifiedSelected = this.filteredItems.filter(
      (item: any, index: any) => {
        if (item.selected && item.qualifyStatus) {
          return true;
        } else return false;
      }
    );
    const disqualifiedSelected = this.filteredItems.filter(
      (item: any, index: any) => {
        if (item.selected && !item.qualifyStatus) {
          return true;
        } else return false;
      }
    );

    if (
      qualifiedSelected.length > 0 &&
      disqualifiedSelected.length === 0 &&
      dataType == 'disqualify'
    ) {
      this.title = 'Disqualifying a Qualified Suppliers';
      this.message =
        "You're attempting to disqualify a qualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (
      qualifiedSelected.length > 0 &&
      disqualifiedSelected.length === 0 &&
      dataType == 'requalify'
    ) {
      this.title = 'Requalifying a Qualified Suppliers';
      this.message =
        "You're attempting to requalify a qualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (
      disqualifiedSelected.length > 0 &&
      qualifiedSelected.length === 0 &&
      dataType == 'disqualify'
    ) {
      this.title = 'Disqualifying a Disqualified Suppliers';
      this.message =
        "You're attempting to disqualify a disqualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (
      disqualifiedSelected.length > 0 &&
      qualifiedSelected.length === 0 &&
      dataType == 'requalify'
    ) {
      this.title = 'Requalifying a Disqualified Suppliers';
      this.message =
        "You're attempting to requalify a disqualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (
      qualifiedSelected.length > 0 &&
      disqualifiedSelected.length > 0 &&
      dataType == 'disqualify'
    ) {
      this.title = 'Disqualifying Action for Selected Suppliers';
      const alreadyDisqualified = disqualifiedSelected.length;
      const quialifyCount = qualifiedSelected.length;
      this.message = `${alreadyDisqualified} of the ${selectedItems.length} selected suppliers has already  been disqualified. Click confirm to proceed with disqualifying the remaining ${quialifyCount} suppliers.`;
    } else if (
      qualifiedSelected.length > 0 &&
      disqualifiedSelected.length > 0 &&
      dataType == 'requalify'
    ) {
      this.title = 'Requalifying Action for Selected Suppliers';
      const alreadyqualified = qualifiedSelected.length;
      const disqualifyCount = disqualifiedSelected.length;
      this.message = `${alreadyqualified} of the ${selectedItems.length} selected suppliers has already  been qualified. Click confirm to proceed with requalify the remaining ${disqualifyCount} suppliers.`;
    }

    this.confirmationMethod =
      dataType == 'disqualify'
        ? this.disQualifySupplier
        : this.requalifySupplier;

    this.showConfirmDialog = true;
  }
  itemIndex: any = null;
  openDisOrQualifyModalSingleItem(dataType: any, index: any = null) {
    if (dataType == 'disqualify') {
      this.confirmButtonText = 'Disqualify Supplier';
    } else if (dataType == 'requalify') {
      this.confirmButtonText = 'Requalify Supplier';
    } else {
      this.confirmButtonText = 'Confirm';
    }
    const filterItem = this.filteredItems[index];
    this.itemIndex = index;
    if (filterItem.qualifyStatus === true && dataType == 'disqualify') {
      this.title = 'Disqualifying a Qualified Supplier';
      this.message =
        "You're attempting to disqualify a qualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (filterItem.qualifyStatus === true && dataType == 'requalify') {
      this.title = 'Requalifying a Qualified Supplier';
      this.message =
        "You're attempting to requalify a qualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (filterItem.qualifyStatus === false && dataType == 'disqualify') {
      this.title = 'Disqualifying a Disqualified Supplier';
      this.message =
        "You're attempting to disqualify a disqualified supplier. Please confirm if you'd like to proceed with this action.";
    } else if (filterItem.qualifyStatus === false && dataType == 'requalify') {
      this.title = 'Requalifying a Disqualified Suppliers';
      this.message =
        "You're attempting to requalify a disqualified supplier. Please confirm if you'd like to proceed with this action.";
    }

    this.confirmationMethod =
      dataType == 'disqualify'
        ? this.disQualifySupplier
        : this.requalifySupplier;

    this.showConfirmDialog = true;
  }
  openSealOrUnsealModal() {
    const selectedItems = this.filteredItems.filter(
      (item: any) => item.selected
    );
    this.confirmButtonText = 'Confirm';

    const sealedItems = this.filteredItems.filter((item: any, index: any) => {
      if (item.selected && item.unsealPrices) {
        return true;
      } else return false;
    });
    const unsealedItems = this.filteredItems.filter((item: any, index: any) => {
      if (item.selected && !item.unsealPrices) {
        return true;
      } else return false;
    });
    if (unsealedItems.length > 0 && sealedItems.length > 0) {
      this.title = 'Unsealing Action for Selected Suppliers.';
      this.message = `${sealedItems.length} of the ${selectedItems.length} selected suppliers has already been unsealed. Click confirm to proceed with unsealing the remaining ${unsealedItems.length} suppliers.`;
    } else if (unsealedItems.length > 0 && sealedItems.length === 0) {
      this.title = 'Unseal Pricing.';
      this.message = `You are about to unseal pricing for ${unsealedItems.length} suppliers.This action is irreversible and cannot be undone.To Proceed, click 'Confirm'.`;
    } else if (unsealedItems.length === 0 && sealedItems.length > 0) {
      this.title = 'Unseal Pricing.';
      this.message = `You are about to unseal pricing for ${sealedItems.length} suppliers.This action is irreversible and cannot be undone.To Proceed, click 'Confirm'.`;
    }
    this.confirmationMethod = this.unSealSuppliers;

    this.showConfirmDialog = true;
  }

  modalCancel() {
    this.confirmButtonText = 'Confirm';
    this.message = '';
    this.title = '';
    this.confirmationMethod = null;
    this.showConfirmDialog = false;
  }
  message: any = '';
  title: any = '';
  confirmationMethod: any = null;

  filterData() {
    this.filteredItems = this.supplierStatusList.filter((item: any) => {
      const matchesSearch = this.searchTerm
        ? item.companyName.toLowerCase().includes(this.searchTerm.toLowerCase())
        : true;

      const matchesOption1 =
        this.selectedOption1 === 'All Statuses' ||
        (item.qualifyStatus ? 'Qualified' : 'Disqualified') ===
          this.selectedOption1;

      const matchesOption2 =
        this.selectedOption2 === 'All Pricing' ||
        (item.unsealPrices ? 'Unsealed Pricing' : 'Sealed Pricing') ===
          this.selectedOption2;

      return matchesSearch && matchesOption1 && matchesOption2;
    });
  }
  pageSizeOptions = [20, 40, 60, 80];
  pageSize: any = 20;
  currentPage: any = 1;
  totalPages: any = 0;
  onPageSizeChange() {
    this.currentPage = 1;
    this.updatepaginateddata();
  }
  goToTheLastPage() {
    this.currentPage = this.totalPages;
    this.updatepaginateddata();
  }
  goToTheFirstPage() {
    this.currentPage = 1;
    this.updatepaginateddata();
  }

  ngOnInit(): void {
    this.evaluationId = this.store.getEvaluationId();
    this.subscribeStore();
  }
  updatepaginateddata() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.filteredItems = this.supplierStatusList.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.supplierStatusList.length / this.pageSize);
  }
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatepaginateddata();
    }
  }
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatepaginateddata();
    }
  }
  getSectionAndCriteriasList(sections: any, criterias: any) {
    return [...sections, ...criterias]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .slice();
  }
  toggleAccordion(supplier: any) {
    supplier.expanded = !supplier.expanded;
    if (
      Object.prototype.hasOwnProperty.call(
        this.expandedSuppliers,
        supplier.vendorId
      )
    ) {
      this.expandedSuppliers[supplier.vendorId] =
        !this.expandedSuppliers[supplier.vendorId];
    } else {
      this.expandedSuppliers[supplier.vendorId] = supplier.expanded;
    }
  }
  toggleNestedAccordion(item: any, vendorId: string) {
    item.expanded = !item.expanded;
    if (
      Object.prototype.hasOwnProperty.call(
        this.expandedSuppliersStages,
        vendorId + item.id
      )
    ) {
      this.expandedSuppliersStages[vendorId + item.id] =
        !this.expandedSuppliersStages[vendorId + item.id];
    } else {
      this.expandedSuppliersStages[vendorId + item.id] = item.expanded;
    }
  }
}
