import { Component, Input } from '@angular/core';
import { BuildFlowService } from 'src/app/core/services/build-flow.service';

@Component({
  selector: 'app-build-flow-form-container',
  templateUrl: './build-flow-form-container.component.html',
  styleUrls: ['./build-flow-form-container.component.scss'],
})
export class BuildFlowFormContainerComponent {
  @Input() enableStage = false;
  @Input() enableSection = false;
  @Input() enableCriteria = false;
  @Input()
  stageDisplayOrder!: number;
  @Input()
  sectionDisplayOrder!: number;
  constructor(private buildFlowService: BuildFlowService) {}

  addStage() {
    this.buildFlowService.addStageToStore();
  }
  addSection() {
    this.buildFlowService.addSectionToStore(this.stageDisplayOrder);
  }
  addCriteria() {
    this.buildFlowService.addCriteriaToStore(
      this.stageDisplayOrder,
      this.sectionDisplayOrder
    );
  }
}
