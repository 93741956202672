import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuildEvaluationComponent } from './modules/pages/build-evaluation/build-evaluation.component';
import { EvaluationTeamComponent } from './modules/pages/evaluation-team/evaluation-team.component';
import { ReviewMeetingsComponent } from './modules/pages/review-meetings/review-meetings.component';
import { SupplierStatusComponent } from './modules/pages/supplier-status/supplier-status.component';

const routes: Routes = [
  {
    path: 'build-evaluation',
    component: BuildEvaluationComponent,
  },
  {
    path: 'evaluation-team',
    component: EvaluationTeamComponent,
  },
  {
    path: 'review-meetings',
    component: ReviewMeetingsComponent,
  },
  {
    path: 'supplier-status',
    component: SupplierStatusComponent,
  },
  { path: '', redirectTo: 'build-evaluation', pathMatch: 'full' },
  { path: '**', redirectTo: 'build-evaluation', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
