import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription, takeWhile } from 'rxjs';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { ReviewMeetingService } from 'src/app/core/services/review-meeting/review-meeting.service';
import { StoreService } from 'src/app/core/services/store.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-review-meetings',
  templateUrl: './review-meetings.component.html',
  styleUrls: ['./review-meetings.component.scss'],
  providers: [DatePipe],
})
export class ReviewMeetingsComponent implements OnInit, OnDestroy {
  constructor(
    private reviwMeetingService: ReviewMeetingService,
    public path: AssetsPathChangeService,
    public store: StoreService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  meetingActionStatus = {
    buttonText: 'Start',
    isDisabled: true,
  };
  _subscribed = true;
  evaluationId: any = null;
  evaluationreviewmeetingId: any = null;
  evaluationreviewmeetingStatus: any = '';
  showReviewModal = false;
  showReviewGrid = false;
  actionType: any = null;

  showConfirmDialog = false;

  modalCancel() {
    this.showConfirmDialog = false;
  }

  icon: any = '';
  buttonText: any = '';
  headerText: any = '';
  paragraphText: any = '';
  paragraph2Text: any = '';
  buttonStatus: any = false;
  message: any = '';
  statusData: any = '';
  methodCall: any = '';
  infoMessage: any = { type: '', message: '', show: false };
  screenTitle: any = '';
  screenDescription: any = '';
  private statusSubscription: Subscription | undefined;
  ngOnInit(): void {
    this.statusSubscription = interval(30000).subscribe(() => {
      this.subscribeStore();
    });
    this.evaluationId = this.store.getEvaluationId();

    this.subscribeStore();
  }

  subscribeStore() {
    this.store.getEvaluationIdAsObservable
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(id => {
        if (id) {
          this.getStatus(id);
        }
      });
  }
  openModal(): void {
    this.showReviewModal = true;
  }
  openGrid(): void {
    this.showReviewGrid = true;
  }
  closeModal() {
    this.showReviewModal = false;
    this.actionType = null;
    this.subscribeStore();
  }
  onClickActions(action: string) {
    this.actionType = action;
    if (action != 'Cancel Meeting') this.openModal();
    else {
      this.showConfirmDialog = true;
    }
  }
  cancelMeeting() {
    this.reviwMeetingService
      .cancelMeeting(this.evaluationreviewmeetingId, this.evaluationId)
      .subscribe({
        next: (data: any) => {
          this.actionType = null;
          this.showConfirmDialog = false;
          this.subscribeStore();
        },
        error: e => {
          console.log(e);
        },
      });
  }
  imageUrl = '';
  getStatus(id: any) {
    this.reviwMeetingService.getStatus(id).subscribe({
      next: (data: any) => {
        this.statusData = data;
        this.evaluationreviewmeetingId = data?.reviewMeetingID ?? null;
        this.store.updateEvaluationReviewMeetingId(
          this.evaluationreviewmeetingId
        );
        this.evaluationreviewmeetingStatus = data?.meetingStatus ?? '';
        if (data.meetingType == 2) {
          if (data.meetingStatus == 'Not Exists') {
            this.imageUrl = '/assets/svg/review-icon.png';
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'No Scoring Method Selected';
            this.paragraphText =
              ' Schedule a review meeting to review and adjust scores or use the average scores to finalize results without a meeting.';
            this.paragraph2Text = '';
            this.buttonText = 'Review Meeting or Average Score';
            this.buttonStatus = false;
            this.methodCall = this.openModal;
          } else if (
            data.meetingStatus == 'Not Allowed To Generate ScoreCard'
          ) {
            this.imageUrl = '/assets/svg/average-score-icon.png';
            this.screenTitle = 'Proceeding with Average Score';
            this.screenDescription =
              'The decision has been made to finialize the evaluation results using the average score The Average Score Summary will be available for generations once all evaluations are completed.';
            this.infoMessage = {
              type: 'error',
              message:
                'The Average Score Summary cannot be generated until the evaluation is completed.',
              show: true,
            };
            this.headerText = 'Proceeding with Average Score';
            this.paragraphText = `No review meeting scheduled.`;
            this.paragraph2Text = '';
            this.buttonText = 'Generate Average Score Summary';
            this.buttonStatus = true;
            this.methodCall = '';
          } else if (data.meetingStatus == 'Allowed To Generate ScoreCard') {
            this.imageUrl = '/assets/svg/average-score-icon.png';
            this.screenTitle = 'Proceeding with Average Score';
            this.screenDescription =
              'The decision has been made to finialize the evaluation results using the average score The Average Score Summary will be available for generations once all evaluations are completed.';
            this.infoMessage = {
              type: 'info',
              message: `Average Score Summary will be automatically generated within 24 hours of the evaluation's completeion. If you wish  to proceed immediately, click 'Generate Average Score Summary' below.`,
              show: true,
            };
            this.headerText = 'Proceeding with Average Score';
            this.paragraphText = `No review meeting scheduled.`;
            this.paragraph2Text = '';
            this.buttonText = 'Generate Average Score Summary';
            this.buttonStatus = false;
            this.methodCall = this.openGrid;
          } else if (data.meetingStatus == 'Confirm Score') {
            this.imageUrl = '/assets/svg/average-score-icon.png';
            this.screenTitle = 'Proceeding with Average Score';
            this.screenDescription =
              'The decision has been made to finialize the evaluation results using the average score The Average Score Summary will be available for generations once all evaluations are completed.';
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Proceeding with Average Score';
            this.paragraphText = `No review meeting scheduled.`;
            this.paragraph2Text = `Average Score Summary was successfully generated on ${this.datePipe.transform(this.statusData.scoreCardGeneratedOn, 'fullDate') + ' ' + this.datePipe.transform(this.statusData.scoreCardGeneratedOn, 'shortTime')}`;
            //add button Download Average Score Summary
            this.buttonText = 'Confirm Scores and Continue to Supplier Status';
            this.buttonStatus = false;
            this.methodCall = this.openGrid;
          } else if (data.meetingStatus == 'Evaluation Finalized') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle =
              'Evaluation Review Confirmed and Ready for Supplier Status';
            this.screenDescription = `The evaluation reveiw has been completed. All scores have been confirmed, and the process is now ready to proceed to the Supplier Status phase. Further changes can only be made if the evaluaution review is reopened by the Procurement Lead.`;
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Evaluation Finalized';
            this.paragraphText = `Average Score Summary was successfully generated on ${this.datePipe.transform(this.statusData.meetingConcludedOn, 'fullDate') + ' ' + this.datePipe.transform(this.statusData.meetingConcludedOn, 'shortTime')}`;
            this.paragraph2Text = `Evaluation Review process was formally finalized on ${this.datePipe.transform(this.statusData.meetingConcludedOn, 'fullDate') + ' ' + this.datePipe.transform(this.statusData.meetingConcludedOn, 'shortTime')}`;
            this.buttonText = 'Reopen Evaluation Review';

            this.buttonStatus = false;
            this.methodCall = '';
          }
        } else {
          if (data.meetingStatus == 'Not Exists') {
            this.imageUrl = '/assets/svg/review-icon.png';
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'No Scoring Method Selected';
            this.paragraphText =
              ' Schedule a review meeting to review and adjust scores or use the average scores to finalize results without a meeting.';
            this.paragraph2Text = '';
            this.buttonText = 'Review Meeting or Average Score';
            this.buttonStatus = false;
            this.methodCall = this.openModal;
          }
          // else if (data.meetingStatus == 'Meeting Scheduled') {
          //   this.imageUrl = '/assets/svg/review-meeting-icon.png';
          //   this.screenTitle = 'Evaluation Review Meeting Ready to Begin';
          //   this.screenDescription =
          //     'The decision has been made to hold a review meeting. Below you will find the meeting details and available actions.';
          //   this.infoMessage = {
          //     type: 'error',
          //     message:
          //       'The scorecards cannot be generated until the evaluation is completed.',
          //     show: true,
          //   };
          //   this.headerText = 'Review Meeting Scheduled';
          //   this.paragraphText = `Meeting set for ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')} `;
          //   this.paragraph2Text = '';
          //   this.buttonText = 'Generate Scorecard';
          //   this.buttonStatus = true;
          //   this.methodCall = '';
          // }
          else if (data.meetingStatus == 'Not Allowed To Generate ScoreCard') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle = 'Evaluation Review Meeting Ready to Begin';
            this.screenDescription =
              'The decision has been made to hold a review meeting. Below you will find the meeting details and available actions.';
            this.infoMessage = {
              type: 'error',
              message:
                'The scorecards cannot be generated until the evaluation is completed.',
              show: true,
            };
            this.headerText = 'Review Meeting Scheduled';
            this.paragraphText = `Meeting set for ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')}`;
            this.paragraph2Text = '';
            this.buttonText = 'Generate Scorecard';
            this.buttonStatus = true;
            this.methodCall = '';
          } else if (data.meetingStatus == 'Allowed To Generate ScoreCard') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle = 'Evaluation Review Meeting Ready to Begin';
            this.screenDescription = `The review meeting is set to begin at ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate')} and end ${this.datePipe.transform(data.reviewMeetingEndDate, 'fullDate')}. You will need to open the Evaluation Review Meeting Scorecards to finalize preparations and efficiently start the Evaluation Review Meeting session.`;
            this.infoMessage = {
              type: 'info',
              message:
                "Scorecards will be automatically generated within 24 hours of the evaluation's completion. If you wish to proceed immediately, click 'Generate Scorecards' below.",
              show: true,
            };
            this.headerText = 'Review Meeting Scheduled';
            this.paragraphText = `Meeting set for ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')} to ${this.datePipe.transform(data.reviewMeetingEndDate, 'shortTime')}`;
            this.paragraph2Text = '';
            this.buttonText = 'Generate Scorecard';
            this.buttonStatus = false;
            this.methodCall = this.generateScoreCard;
          } else if (data.meetingStatus == 'Preview Score Card') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle = 'Evaluation Review Meeting Ready to Begin';
            this.screenDescription =
              'The decision has been made to hold a review meeting. Below you will find the meeting details and available actions.';
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Review Meeting Scheduled';
            this.paragraphText = `Meeting set for ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')} to ${this.datePipe.transform(data.reviewMeetingEndDate, 'shortTime')}`;
            this.paragraph2Text = `The scorecards was successfully generated on ${this.datePipe.transform(data.scoreCardGeneratedOn, 'fullDate') + ' ' + this.datePipe.transform(data.scoreCardGeneratedOn, 'shortTime')}.`;
            this.buttonText = 'Preview Meeting Scorecards';
            this.buttonStatus = false;
            this.methodCall = this.openGrid;
          } else if (data.meetingStatus == 'Meeting Start Soon') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle = 'Evaluation Review Meeting Ready to Begin';
            this.screenDescription = `The review meeting is set to begin at ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')}. You will need to open the Evaluation Review Meeting Scorecards to finalize preparations and efficiently start the Evaluation Review Meeting session.`;
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Meeting Ready to Begin';
            this.paragraphText = `Meeting set for ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')} - ${this.datePipe.transform(data.reviewMeetingEndDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingEndDate, 'shortTime')}`;
            this.paragraph2Text = 'Meeting Starting Soon';
            this.buttonText = 'Open Meeting';
            this.buttonStatus = false;
            this.methodCall = this.openGrid;
          } else if (data.meetingStatus == 'Meeting In Progress') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle = 'Evaluation Review Meeting In Progress';
            this.screenDescription = `The review meeting was started on ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')} and is currently in progress. You can join the meeting now.`;
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Review Meeting Started';
            this.paragraphText = `Meeting started on ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + ' ' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')}`;
            this.paragraph2Text = 'Meeting in progress';
            this.buttonText = 'Join Meeting';
            this.buttonStatus = false;
            this.methodCall = this.openGrid;
            this.meetingActionStatus.isDisabled = false;
            this.meetingActionStatus.buttonText = 'Start';
          } else if (data.meetingStatus == 'Confirm Score') {
            this.imageUrl = '/assets/svg/meeting-conclude-icon.png';
            this.screenTitle = 'Evaluation Review Meeting Concluded';
            this.screenDescription = `The review meeting was successfully concluded. Please review the meeting summary and ensure all details are correct before proceeding to the next step in the evaluation process.`;
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Meeting concluded';
            this.paragraphText = `Meeting concluded on ${this.datePipe.transform(data.reviewMeetingStartDate, 'fullDate') + 'at' + this.datePipe.transform(data.reviewMeetingStartDate, 'shortTime')}`;
            this.paragraph2Text = '';
            this.buttonText = 'Confirm Scores and Continue to Supplier Status';
            //add another button Downlaod Average Score Summary
            this.buttonStatus = false;
            this.methodCall = this.navigateTosuppliers;
          } else if (data.meetingStatus == 'Evaluation Finalized') {
            this.imageUrl = '/assets/svg/review-meeting-icon.png';
            this.screenTitle =
              'Evaluation Review Confirmed and Ready for Supplier Status';
            this.screenDescription = `The evaluation reveiw has been completed. All scores have been confirmed, and the process is now ready to proceed to the Supplier Status phase. Further changes can only be made if the evaluaution review is reopened by the Procurement Lead.`;
            this.infoMessage = {
              type: '',
              message: '',
              show: false,
            };
            this.headerText = 'Evaluation Finalized';
            this.paragraphText = `Evaluation Review Meeting concluded on ${this.datePipe.transform(this.statusData.meetingConcludedOn, 'fullDate') + ' ' + this.datePipe.transform(this.statusData.meetingConcludedOn, 'shortTime')}`;
            this.paragraph2Text = `Evaluation Review process was formally finalized on ${this.datePipe.transform(this.statusData.meetingConcludedOn, 'fullDate') + ' ' + this.datePipe.transform(this.statusData.meetingConcludedOn, 'shortTime')}`;
            this.buttonText = 'Reopen Evaluation Review';

            this.buttonStatus = false;
            this.methodCall = '';
          }
        }
      },
      error: e => {
        console.log(e);
      },
    });
  }
  showConfirmDialogForReopen: any = false;
  reopen() {
    this.showConfirmDialogForReopen = true;
  }
  cancelReopen() {
    this.showConfirmDialogForReopen = false;
  }
  navigateTosuppliers() {
    this.router.navigate(['/build-evaluation']);
  }
  closeGrid() {
    this.showReviewGrid = false;
  }
  meetingActions() {
    if (this.meetingActionStatus.buttonText === 'Start') {
      this.meetingActionStatus.buttonText = 'Conclude';
      return;
    }
    if (this.meetingActionStatus.buttonText === 'Conclude') {
      const data = {
        evaluationId: this.store.getEvaluationId(),
        reviewMeetingId: this.store.getEvaluationReviewMeetingId(),
      };

      this.reviwMeetingService
        .concludeMeeting(
          this.store.getEvaluationId(),
          this.store.getEvaluationReviewMeetingId(),
          data
        )
        .subscribe({
          next: (data: any) => {
            this.subscribeStore();
            this.closeGrid();
          },
        });
    }
  }

  generateScoreCard() {
    const data = {
      evaluationId: this.store.getEvaluationId(),
      meetingId: this.store.getEvaluationReviewMeetingId(),
    };

    this.reviwMeetingService.generateScorecard('PL', data).subscribe({
      next: (data: any) => {
        this.subscribeStore();
      },
    });
  }

  ngOnDestroy(): void {
    this._subscribed = false;
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }
}
