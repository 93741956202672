import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { BuildFlowService } from 'src/app/core/services/build-flow.service';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-add-edit-section',
  templateUrl: './add-edit-section.component.html',
  styleUrls: ['./add-edit-section.component.scss'],
})
export class AddEditSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stageTypeId = 0;
  @Input() formData: any;
  @Input() stageDisplayOrder!: number;
  @Output() hideAddSection = new EventEmitter();

  evaluationScoringMethodId: number = this.store.getEvaluationScoringMethodId(); // 1 for Points 2 for Weight

  sectionForm = this.fb.group({
    title: [null],
    score: [null],
  });
  stageForm: any;

  constructor(
    public fb: FormBuilder,
    private store: StoreService,
    private buildFlow: BuildFlowService,
    private focusMonitor: FocusMonitor,
    private el: ElementRef<HTMLElement>,
    public path: AssetsPathChangeService
  ) {}

  assignFormData() {
    this.sectionForm.patchValue({
      title: this.formData.title,
      score: this.formData.score,
    });
  }

  formValuesChanges() {
    this.sectionForm.valueChanges
      .pipe(
        tap(() => this.store.setStopAutoSaveBuildFlow(true)),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.formData.title = this.sectionForm.value.title;
        this.formData.score = this.sectionForm.value.score;
        this.store.setStopAutoSaveBuildFlow(false);
      });
  }
  monitorFocus() {
    this.focusMonitor
      .monitor(this.el?.nativeElement, true)
      .subscribe(origin => {
        if (origin) {
          this.store.setBuildFlowAccodionFocus({
            stage: this.stageDisplayOrder,
            section: this.formData.displayOrder,
          });
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stageTypeId']) {
      this.sectionForm.patchValue({
        score: null,
      });
    }
  }
  ngOnInit(): void {
    if (this.formData) {
      this.assignFormData();
    }
    this.formValuesChanges();
    this.monitorFocus();
  }
  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.el?.nativeElement);
  }
}
