import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-evaluation-guide',
  templateUrl: './evaluation-guide.component.html',
  styleUrls: ['./evaluation-guide.component.scss'],
})
export class EvaluationGuideComponent {
  @Input() selectedProject: any = null;

  showLoader = false;
  @Output() goBack: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() continue = new EventEmitter<number>();

  backToDashboard(): void {
    this.goBack.emit();
  }
}
