import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-build-evaluation-landing-page-errors',
  templateUrl: './build-evaluation-landing-page-errors.component.html',
  styleUrls: ['./build-evaluation-landing-page-errors.component.scss'],
})
export class BuildEvaluationLandingPageErrorsComponent {
  @Input() errorsWarnings: any;

  formatText(text: string) {
    if (text) {
      const result = text?.split(':');
      return result?.length > 1
        ? `<strong>${result[0]}:</strong> ${result[1]}`
        : result[0];
    }
    return text;
  }
}
