<div
  [hidden]="hideModal"
  class="modal d-block bg-dark bg-opacity-50"
  [class.d-none]="hideModal"
  id="manageEvalModal"
  tabindex="-1"
  aria-labelledby="manageEvalModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable w-650">
    <div class="modal-content">
      <div class="modal-header ps-5 pt-5 pb-2 border-0">
        <h2 class="text-start">Manage Evaluators</h2>
        <button
          type="button"
          class="btn-close custom-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"></button>
      </div>
      <div
        class="w-100 px-5 pb-4 mb-1 position-relative"
        *ngIf="evaluatorsTeamList.length">
        <input
          class="form-control h-50"
          type="text"
          name="search"
          (input)="onSearchInput()"
          [(ngModel)]="searchText"
          autocomplete="off"
          placeholder="Keyword Search" />
        <button type="button" (click)="onClearSearch()" class="clear-search">
          <app-icon
            *ngIf="!isTyping"
            class="input-icon"
            [type]="'search'"
            [width]="18"
            [height]="18">
          </app-icon>
          <app-icon
            *ngIf="isTyping"
            class="input-icon"
            [type]="'x'"
            [width]="20"
            [height]="20"></app-icon>
        </button>
      </div>
      <div class="modal-body p-0 text-center">
        <div class="py-3" *ngIf="showLoader; else assignStages">
          <app-loader [showText]="true"></app-loader>
        </div>
        <ng-template #assignStages>
          <div
            class="pt-4"
            [class]="accessType !== 'criteria' ? 'px-5' : 'px-4'">
            <div class="row">
              <div class="col-12" *ngIf="accessType !== 'criteria'">
                <ul
                  *ngIf="filteredEmployee.length > 0"
                  class="list-group select-all">
                  <li class="list-group-item d-flex align-items-center mb-2">
                    <div
                      class="d-flex align-items-center justify-content-start">
                      <div class="ps-2 pe-3">
                        <input
                          type="checkbox"
                          [(ngModel)]="selectAll"
                          name="list_name"
                          value="m1"
                          (change)="toggleSelectAll()" />
                      </div>
                      <div class="ps-1">Select All</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="col-12 team-memeber-list"
                *ngIf="filteredEmployee.length > 0">
                <ul class="list-group">
                  <li
                    class="list-group-item d-flex align-items-center mb-2"
                    *ngFor="
                      let item of filteredEmployee | orderBy: 'firstName'
                    ">
                    <div
                      class="d-flex align-items-center justify-content-start">
                      <div class="ps-2 pe-3">
                        <input
                          type="checkbox"
                          [(ngModel)]="item.isSelected"
                          name="list_name"
                          value="{{ item.id }}"
                          (change)="toggleSelected()" />
                      </div>
                      <div
                        class="initials ms-1"
                        [ngClass]="item.isSelected ? 'selected' : ''">
                        {{ item.firstName | slice: 0 : 1
                        }}{{ item.lastName | slice: 0 : 1 }}
                      </div>
                      <div class="px-3">
                        {{ item.firstName }} {{ item.lastName }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-12" *ngIf="filteredEmployee.length === 0">
                <p class="py-4 strong text-start">
                  No evaluators were found{{
                    evaluatorsTeamList.length ? ' with this name.' : '.'
                  }}
                  <span *ngIf="accessType !== 'criteria'">
                    To add an evaluator, click the '+Add New Team Member' link
                    below.</span
                  >
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="modal-footer border-0 justify-content-start px-3 py-4">
        <button
          type="button"
          [disabled]="filteredEmployee.length === 0 || saveLoader"
          class="btn btn-primary min-width"
          (click)="saveSelectedEvaluators()">
          Save
          <span
            *ngIf="saveLoader"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </button>
        <button
          type="button"
          class="btn btn-secondary min-width ms-2"
          (click)="closeModal()"
          data-bs-dismiss="modal">
          Cancel
        </button>
        <a
          *ngIf="accessType !== 'criteria'"
          class="ms-4"
          (click)="addMemberProPortal($event)"
          href="#"
          >+ Add New Team Member</a
        >
      </div>
    </div>
  </div>
</div>
<app-confirm-dialog
  *ngIf="showRemoveEvaluatorDialog"
  [message]="modalMessage"
  [dialogTitle]="'Delete Evaluator?'"
  cancelButtonText="Cancel"
  confirmButtonText="Confirm"
  iconType="alert-circle"
  (dialogConfirmed)="onConfirmRemoveEvaluators()"
  (dialogCancel)="onCancelRemoveEvaluators()"
  type="error">
</app-confirm-dialog>
