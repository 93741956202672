import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralMessage',
})
export class PluralMessagePipe implements PipeTransform {
  transform(count: number, message: string): string {
    if (!count) {
      return `No ${message}s`;
    } else if (count === 1) {
      return `1 ${message}`;
    } else {
      return `${count} ${message}s`;
    }
  }
}
