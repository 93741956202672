import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EvaluationTeamService } from 'src/app/core/services/evaluation-team/evaluation-team.service';
import { StoreService } from 'src/app/core/services/store.service';
import { EvaluationLibraryContentEnum } from 'src/app/shared/enums';
import { DatePipe } from '@angular/common';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';

@Component({
  selector: 'app-evaluator-list',
  templateUrl: './evaluator-list.component.html',
  styleUrls: ['./evaluator-list.component.scss'],
})
export class EvaluatorListComponent {
  constructor(
    private evaluationTeamService: EvaluationTeamService,
    private store: StoreService,
    private datePipe: DatePipe,
    public path: AssetsPathChangeService
  ) {}

  @Input() evaluatorsList: any[] = [];
  @Output() toggleAssignStagesModal = new EventEmitter<string>();
  @Output() removeAssignedStage = new EventEmitter<any>();
  @Output() refreshEvalData = new EventEmitter<boolean>();
  locale = {
    format: 'MMM DD/YY',
  };
  evaluationId = this.store.getEvaluationId();
  expandedEvaluators: any = {};

  trackByFn(index: number, evaluator: any) {
    return evaluator.teamMemberId;
  }
  trackById(index: number, stage: any) {
    return stage.id;
  }
  showAssignStagePoup(teamMemberId: any): void {
    this.toggleAssignStagesModal.emit(teamMemberId);
  }
  removeStage(teamMemberId: string, stage: any) {
    this.removeAssignedStage.emit({ teamMemberId: teamMemberId, stage: stage });
  }
  completedAndClosedStatus(evaluator: any) {
    return (
      evaluator.overAllPercentage === 100 &&
      evaluator.stages.every((stage: any) => stage.stageStatus === 'Closed')
    );
  }
  dateRangeClicked(stage: any) {
    stage.isDateModified = true;
  }
  updateDateRange(stage: any) {
    if (stage.isDateModified) {
      stage.startDateUtc = stage.dateRange.startDate;
      stage.endDateUtc = stage.dateRange.endDate;
      this.evaluationTeamService
        .updateStage({ evaluationStageRequest: stage })
        .subscribe({
          next: (data: any) => {
            this.refreshEvalData.emit();
          },
          error: (e: any) => {
            stage.isDateModified = false;
            stage.dateRange = stage.startDateUtc
              ? {
                  startDate: new Date(stage.startDateUtc),
                  endDate: new Date(stage.endDateUtc),
                }
              : null;
          },
        });
    }
  }

  getLibraryContent(groupTypeId: any) {
    this.showLoader = true;
    this.evaluationTeamService
      .getLibraryContent(this.evaluationId, groupTypeId)
      .subscribe({
        next: (data: any) => {
          this.libraryContent = data.data;
          this.showLoader = false;
        },
      });
  }
  showLoader = true;
  libraryContent: any = null;
  showNoCOIDialog = false;
  showConfirmDialog = false;
  evaluationState = EvaluationLibraryContentEnum;
  selectedProject: any = null;
  showEvaluatorCOIModal = false;
  showEvaluationContentLibraryModal = false;
  currentEvaluationState = EvaluationLibraryContentEnum.EvaluationGuide;
  showModal = false;
  modalType: 'coi' | null = null;
  //evaluatorId = 'a3d836de-6123-4400-a945-97e0c25bfa30';
  evaluatorData: any = null;
  showCOIModal = false;
  //teamMemberId = '46acc524-65c1-4432-bf51-0ca4185656e9';

  // Open Evaluation Guide for New Projects
  openGuideModal(groupTypeId: any): void {
    // this.selectedProject = selectedProject;
    this.showEvaluationContentLibraryModal = true;
    this.currentEvaluationState =
      groupTypeId == 1
        ? EvaluationLibraryContentEnum.EvaluationGuide
        : groupTypeId == 2
          ? EvaluationLibraryContentEnum.EvaluationNonDisclosureAgreement
          : groupTypeId == 3
            ? EvaluationLibraryContentEnum.EvaluationConflictOfInterest
            : EvaluationLibraryContentEnum.EvaluationScoreCard;
    this.getLibraryContent(groupTypeId);
  }
  closeGuideModal(): void {
    this.showEvaluationContentLibraryModal = false;
  }
  setEvaluationState(state: any) {
    if (state === 'close') {
      this.closeGuideModal();
    } else {
      this.currentEvaluationState = state;
    }
  }
  onConfirmDialogStatusChange(status: boolean) {
    this.showConfirmDialog = status;
    this.showEvaluationContentLibraryModal = false;
  }
  onNoCOIDialogStatusChange(status: boolean) {
    this.showNoCOIDialog = status;
    this.showEvaluationContentLibraryModal = false;
  }
  openCOIModal(teamMemberId: string) {
    const evaluatorDetails = this.evaluatorsList.find(
      evaluator => evaluator.teamMemberId === teamMemberId
    );
    this.evaluationTeamService
      .getCoiContent(this.evaluationId, teamMemberId)
      .subscribe({
        next: (data: any) => {
          this.evaluatorData = {
            firstName: `${evaluatorDetails?.firstName}`,
            lastName: `${evaluatorDetails?.lastName}`,
            acknowledgedDateTimeUtc: this.datePipe.transform(
              data?.data?.acknowledgedDateTimeUtc,
              'yyyy-MM-dd, hh:mm a'
            ),
            commentByEvaluatorOnCOI: data?.data?.commentByEvaluatorOnCOI,
            evaluationId: this.evaluationId,
            teamMemberId: teamMemberId,
          };
          this.showCOIModal = true;
        },
      });
  }

  closeCOIModal(): void {
    this.showCOIModal = false;
  }
  toggleAccordion(evaluator: any) {
    evaluator.expanded = !evaluator.expanded;
    if (
      Object.prototype.hasOwnProperty.call(
        this.expandedEvaluators,
        evaluator.teamMemberId
      )
    ) {
      this.expandedEvaluators[evaluator.teamMemberId] =
        !this.expandedEvaluators[evaluator.teamMemberId];
    } else {
      this.expandedEvaluators[evaluator.teamMemberId] = evaluator.expanded;
    }
  }
}
