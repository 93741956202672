import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-landing-page-error',
  templateUrl: './landing-page-error.component.html',
  styleUrls: ['./landing-page-error.component.scss'],
})
export class LandingPageErrorComponent {
  @Output() refresh = new EventEmitter<boolean>();

  refreshData() {
    this.refresh.emit(true);
  }
}
