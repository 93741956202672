<div *ngIf="selectedProject">
  <h2 class="project-title">
    {{ selectedProject.project.id }}
    {{ selectedProject.project.name }}
  </h2>
  <!-- <h2 class="evaluation-guide-title">Evaluation Guide</h2> -->
  <div class="text-center py-3" *ngIf="showLoader; else content">
    <app-loader [showText]="true"></app-loader>
  </div>
  <ng-template #content>
    <div [innerHTML]="selectedProject?.project.content"></div>
  </ng-template>
  <button
    type="button"
    *ngIf="selectedProject.project"
    class="btn btn-primary evaluation-guide-agree"
    [disabled]="true">
    I agree
  </button>

  <button class="arrow-left">
    <app-icon [type]="'arrow-left'" [width]="18" [height]="18"> </app-icon>
  </button>
  <a class="back-btn-link" (click)="backToDashboard()"
    >Back to My Evaluation Dashboard</a
  >
</div>
