<div>
  <div class="mt-3" *ngIf="errorsWarnings.errorsCount">
    <div class="danger-alert-continer" role="alert">
      <h4 class="d-flex align-items-end">
        <app-icon
          [class]="'error pe-2'"
          [type]="'alert-circle'"
          [width]="20"
          [height]="20"></app-icon>
        There were {{ errorsWarnings.errorsCount }} errors found
      </h4>
      <ul class="ms-4" *ngFor="let error of errorsWarnings.errors">
        <li [innerHTML]="formatText(error)"></li>
      </ul>
    </div>
  </div>
  <div class="mt-3" *ngIf="errorsWarnings.warningsCount">
    <div class="warning-alert-continer" role="alert">
      <h4 class="d-flex align-items-end">
        <app-icon
          [class]="'warning pe-2'"
          [type]="'alert-triangle'"
          [width]="22"
          [height]="22"></app-icon>
        There were {{ errorsWarnings.warningsCount }} warning found
      </h4>
      <ul class="ms-4" *ngFor="let warning of errorsWarnings.warnings">
        <li [innerHTML]="formatText(warning)"></li>
      </ul>
    </div>
  </div>
</div>
