import { Component, EventEmitter, Output } from '@angular/core';
import { AssetsPathChangeService } from '../../services/core/assets-path-change.service';

@Component({
  selector: 'app-landing-page-error',
  templateUrl: './landing-page-error.component.html',
  styleUrls: ['./landing-page-error.component.scss'],
})
export class LandingPageErrorComponent {
  @Output() refresh = new EventEmitter<boolean>();
  constructor(public path: AssetsPathChangeService) {}
  refreshData() {
    this.refresh.emit(true);
  }
}
