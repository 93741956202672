import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from '../services/store.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private store: StoreService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = request.url;
    if (url.includes('{nodeId}')) {
      url = url.replace('{nodeId}', this.store.getNodeId());
    }
    if (url.includes('{customerId}')) {
      url = url.replace('{customerId}', this.store.getCustomerId());
    }
    if (url.includes('{projectId}')) {
      url = url.replace('{projectId}', this.store.getProjectId());
    }
    const token = this.tokenExtractor.getToken() as string;
    request = request.clone({
      url: url,
      withCredentials: true,
      // setHeaders: { 'X-XSRF-TOKEN': token || '' },
      setHeaders: {
        'X-XSRF-TOKEN': token || '',
        externalaccessapikey: '248BB949-0F0F-4BCF-9031-78883464C97D', //'CFD9F286-B89A-4CE0-8935-1AC9B37D1557', //QA: 248BB949-0F0F-4BCF-9031-78883464C97D
      },
    });

    return next.handle(request);
  }
}
