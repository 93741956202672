<div class="container-fluid">
  <section>
    <div class="row">
      <div class="col">
        <app-status></app-status>
      </div>
    </div>
  </section>
  <div class="app-layout">
    <aside>
      <app-sidebar></app-sidebar>
    </aside>
    <main [class]="sidebarExpanded" id="main-content">
      <router-outlet></router-outlet>
    </main>
  </div>
  <app-page-loader></app-page-loader>
  <div aria-live="polite" toastContainer></div>
</div>
