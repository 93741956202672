<div class="modal">
  <div class="modal-overlay"></div>
  <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header justify-content-between">
        <div class="col d-flex align-items-center">
          <div class="logo">
            <img
              [src]="path.getAssetUrl('/assets/svg/logo.svg')"
              alt="Logo"
              class="logo-img" />
          </div>
          <h4 class="modal-title">meeting</h4>
        </div>
        <div
          class="col d-flex align-items-center justify-content-center text-center">
          <h3 class="project-title mb-0">
            RFP2018-19 Website Redesign and Development
          </h3>
        </div>
        <div class="col d-flex align-items-end justify-content-end text-end">
          <button
            type="button"
            class="btn btn-primary btn-sm header-button pb-3"
            (click)="leaveLaunchBuilder()"
            data-bs-dismiss="modal">
            <app-icon
              class="mt-2"
              [type]="'x'"
              [width]="20"
              [height]="20"
              [strokeWidth]="3"></app-icon
            >Leave
          </button>

          <button
            [disabled]="additionalInfo.isDisabled"
            [ngClass]="{
              'start-button': additionalInfo.buttonText === 'Start',
              'conclude-button': additionalInfo.buttonText !== 'Start',
            }"
            class="btn toggle-button header-button"
            (click)="toggleState()">
            <app-icon
              *ngIf="additionalInfo.buttonText !== 'Start'"
              class="mt-2"
              [type]="'check'"
              [width]="20"
              [height]="20"
              [strokeWidth]="2"></app-icon>
            <app-icon
              *ngIf="additionalInfo.buttonText === 'Start'"
              class="mt-2"
              [type]="'play'"
              [width]="20"
              [height]="20"
              [strokeWidth]="2"></app-icon>
            {{ additionalInfo.buttonText }}
          </button>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <!-- Footer-->
    </div>
  </div>
</div>
