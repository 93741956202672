<div
  class="criterias-container"
  cdkDropList
  [cdkDropListDisabled]="!sectionDisplayOrder"
  (cdkDropListDropped)="dropCriterias($event)">
  <div
    ngbAccordion
    #criteriaAccordion="ngbAccordion"
    class="custom-accordion criteria-items">
    <div
      class="criteria-item"
      ngbAccordionItem="criteria-item-{{ criteria.displayOrder }}"
      [collapsed]="
        criteria?.enableEdit || criteria?.enableExpanded ? false : true
      "
      [ngClass]="{
        'is-edit':
          criteria.displayOrder === buildFlowFocus?.criteria &&
          sectionDisplayOrder === buildFlowFocus?.section &&
          stageDisplayOrder === buildFlowFocus?.stage,
        'has-error':
          enableErrorsWarnings &&
          criteria.messages &&
          criteria.messages?.errors?.count,
        'has-warning':
          enableErrorsWarnings &&
          criteria.messages &&
          criteria.messages?.warnings.count,
      }"
      *ngFor="
        let criteria of criterias.slice() | orderBy: 'displayOrder';
        index as criteriaIndex;
        trackBy: trackByFn
      "
      [cdkDragPreviewContainer]="'parent'"
      [cdkDragDisabled]="criteria.enableEdit"
      cdkDragBoundary=".criteria-items"
      cdkDrag>
      <div
        class="row d-flex px-3 py-2"
        [ngClass]="{ 'align-items-center': !criteria.enableEdit }">
        <div class="col-12 col-sm-9 col-md-10" *ngIf="!criteria.enableEdit">
          <div class="row">
            <div class="col-12 col-sm-5">
              <div class="d-flex align-items-center">
                <div class="drag-icon criteria-drag-icon" cdkDragHandle>
                  <img
                    [src]="path.getAssetUrl('/assets/svg/drag-icon.svg')"
                    alt="icon of drag and drop" />
                </div>
                <h4 class="accordion-header ps-3" *ngIf="!sectionDisplayOrder">
                  {{ stageDisplayOrder }}.{{ criteria.displayOrder }}
                  {{ criteria.title }}
                </h4>
                <h4 class="accordion-header ps-3" *ngIf="sectionDisplayOrder">
                  {{ stageDisplayOrder }}.{{ sectionDisplayOrder }}.{{
                    criteria.displayOrder
                  }}
                  {{ criteria.title }}
                </h4>
              </div>
            </div>
            <div class="col-12 col-sm-2">
              <span *ngIf="criteria.proposalResponseMappings?.length">
                {{ criteria.proposalResponseMappings[0].name }}
                <span *ngIf="criteria.proposalResponseMappings.length > 1"
                  >({{ criteria.proposalResponseMappings.length - 1 }})</span
                >
              </span>
            </div>
            <div class="col-12 col-sm-3">
              <span *ngIf="criteria.score !== null && stageTypeId === 1">
                {{ criteria.score + ' ' + stageTypes[stageTypeId] | lowercase }}
              </span>
              <span *ngIf="criteria.score !== null && stageTypeId === 2">
                {{
                  criteria.score + '% ' + stageTypes[stageTypeId] | lowercase
                }}
              </span>
              <span *ngIf="stageTypeId === 3">
                {{ stageTypes[stageTypeId] }}
              </span>
              <span *ngIf="stageTypeId === 4 && criteria.pricingFormulaId">
                {{ stageTypes[stageTypeId] }} Fromula:
                {{ pricingFormula[criteria.pricingFormulaId] }}
              </span>
            </div>
            <div class="col-12 col-sm-2">
              {{ criteria.assignedEvaluatorCount | pluralMessage: 'Evaluator' }}
            </div>
          </div>
          <div *ngIf="criteria?.messages && enableErrorsWarnings">
            <div
              class="row"
              *ngFor="let error of criteria?.messages?.errors.values">
              <span class="col-12 validate-msg">
                <small>
                  <app-icon
                    [class]="'error pe-2'"
                    [type]="'alert-circle'"
                    [width]="20"
                    [height]="20"></app-icon>
                  {{ error }}
                </small>
              </span>
            </div>
            <div
              class="row"
              *ngFor="let warning of criteria?.messages?.warnings.values">
              <span class="col-12 validate-msg">
                <small>
                  <app-icon
                    [class]="'warning pe-2'"
                    [type]="'alert-triangle'"
                    [width]="22"
                    [height]="22"></app-icon>
                  {{ warning }}
                </small>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-9 col-md-10 mb-2" *ngIf="criteria.enableEdit">
          <app-add-edit-criteria
            [stageTypeId]="stageTypeId"
            [formData]="criteria"
            [stageDisplayOrder]="stageDisplayOrder"
            [sectionDisplayOrder]="sectionDisplayOrder"></app-add-edit-criteria>
        </div>
        <div class="col-12 col-sm-3 col-md-2">
          <div class="row">
            <div
              class="col-12 col-sm-12 d-flex align-items-center justify-content-end">
              <div class="criteria-actions hover-action-items">
                <button
                  class="btn btn-none p-0"
                  type="button"
                  (click)="copyCriteria(criteria.displayOrder)">
                  <app-icon
                    [type]="'copy'"
                    [width]="18"
                    [height]="18"></app-icon>
                </button>
                <button
                  class="btn btn-none py-1"
                  type="button"
                  (click)="deleteCriteria(criteria)">
                  <app-icon
                    [type]="'trash'"
                    [width]="18"
                    [height]="18"></app-icon>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  [disabled]="stageStatus === 'Closed' || criteria?.enableEdit"
                  (click)="showEditCriteria(criteria)">
                  Edit
                </button>
              </div>
              <div class="criteria-actions toggle-accordion ps-3">
                <button
                  class="border-0"
                  type="button"
                  ngbAccordionButton
                  (click)="hideEditCriteria(criteria)"></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody></div>
      </div>
    </div>
  </div>
</div>
